import { forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'
import { Icon } from '../Icon/Icon'
import { NotificationCounter } from '../NotificationCounter/NotificationCounter'
import './Select.light.scss'

export const Select = memo(forwardRef((props, ref) => {

    let {
        source = [],
        name,
        valueParamName = 'value',
        labelParamName = 'label',
        counterParamName,
        onChange,
        placeholder,
        autocomplete,
        selectedIndex,
        multiple = false,
        disabled = false,
        inline = false,
        inlineClickHandler,
        footerButton,
        headerButton,
        isInvalid,
        className,
        title,
        onItemClick,
        selectOrEnter,
    } = props

    // if (name === 'socAccount') console.log('Select socAccount', selectedIndex)
    /////////////////////////
    let placeholderClass = ''
    // let inputBlurTimer = false
    let wasIconClicked = false
    const inputRef = useRef(null)
    const listRef = useRef(null)


    /////////////////////////////////////////////
    const [isActive, setActive] = useState(false)
    const [selected, setSelected] = useState([])
    const [list, setList] = useState([])
    const [label, setLabel] = useState([])
    const [listUpClassName, setListUpClassName] = useState('')
    const [prevSource, setPrevSource] = useState(source)
    const [prevSelectedIndex, setPrevSelectedIndex] = useState(selectedIndex)
    const [hideListTimer, setHideListTimer] = useState(false)
    const [inputBlurTimer, setInputBlurTimer] = useState(false)
    const hideListTimerRef = useRef(hideListTimer)
    const inputBlurTimerRef = useRef(inputBlurTimer)
    const sourceRef = useRef(source)


    //////////////////////////////////////////////////////////////////////////////
    useEffect(() => { hideListTimerRef.current = hideListTimer }, [hideListTimer])
    useEffect(() => { inputBlurTimerRef.current = inputBlurTimer }, [inputBlurTimer])
    useEffect(() => { sourceRef.current = source }, [source])


    /////////////////
    useEffect(() => {
        if (Array.isArray(selectedIndex) && selectedIndex.length === 0) {
            setSelected([])
        }
    }, [selectedIndex])


    /////////////////
    useEffect(() => {

        setList(source)

        if (!('selectedIndex' in props)) return

        if (!Array.isArray(selectedIndex)) {
            selectedIndex = selectedIndex || selectedIndex === 0 ? [selectedIndex] : []
        }

        selectedIndex = selectedIndex.filter(item => source[item])

        if (
            !isEqual(prevSource, source)
            || !prevSource?.length
            || !isEqual(prevSelectedIndex, selectedIndex)
            // || prevSelectedIndex !== selectedIndex // проверить, что не создает проблем где-либо
            // нужно для исправления выбора editingItem в UserAdd, мешает proxy webSelector
            // при активном условии и меняющемся source - webSelector сбрасывает выбор сразу после выбора
            // попробовать обработать ситуацию, когда source изменился, но selectedIndex оставить как был
        ) {

            setSelected(selectedIndex.map(item => {

                return {
                    label:
                        (
                            item === undefined
                            || item === null
                            || item < 0
                            || !source.length
                        )
                            ? ''
                            : source[item][labelParamName],
                    value:
                        (
                            item === undefined
                            || item === null
                            || item < 0
                            || !source.length
                        )
                            ? ''
                            : source[item][valueParamName]
                }

            }))

            setPrevSource(source)
            setPrevSelectedIndex(selectedIndex)

        }

    }, [selectedIndex, source])


    /////////////////
    useEffect(() => {
        setLabel(selected.map(item => item.label))

    }, [selected])


    const listActiveClassName =
        (
            isActive
            && (list.length > 0 || footerButton || headerButton)
        )
            ? 'select-list-active'
            : ''


    const inlineClassName = inline ? 'select-inline' : ''


    /////////////////
    useEffect(() => {

        const inputElement = inputRef.current
        const listElement = listRef.current

        if (inputElement) {

            const inputRect = inputElement.getBoundingClientRect()
            const isOverflowing = inputRect.top + inputRect.height + 300 > window.innerHeight
            setListUpClassName(isOverflowing ? 'select-list-up' : '')

            if (listElement) {

                if (!listElement.style.marginTop) {
                    listElement.style.marginTop = isOverflowing ? `-${inputRect.height}px` : null
                }

            }

        } else if (listElement) {

            const listRect = listElement.getBoundingClientRect()
            const isOverflowing = listRect.top + listRect.height > window.innerHeight
            setListUpClassName(isOverflowing ? 'select-list-up' : '')

        }

    }, [isActive])


    const toggleClassName = `
        select-toggle
        ${isActive ? 'select-toggle-active' : ''}
        ${autocomplete ? 'select-toggle-autocomplete' : ''}
        ${disabled ? 'select-toggle-disabled' : ''}
    `


    if (!label.length && placeholder) {
        placeholderClass = 'select-toggle-placeholder'
    }


    ///////////////////////////////////////////////////////
    const defaultSelectedValueToState = useCallback(() => {

        if (
            selectedIndex !== undefined
            && selectedIndex !== null
            && selectedIndex >= 0
            && onChange
        ) {
            onChange({
                target: {
                    name: name,
                    value: selectedIndex.map(index => source[index][valueParamName])
                }
            })
        }

    }, [])


    //////////////////////////////////////////////////////
    useEffect(() => { defaultSelectedValueToState() }, [])


    /////////////////
    useEffect(() => {

        const mappedSelected = selected.map(item => item.value)
        const noEmptySelected = mappedSelected.filter(item => item)

        onChange && onChange({ // pass emulated event object
            target: {
                name: name,
                value: multiple ? noEmptySelected : noEmptySelected[0]
            }
        }, selected)

    }, [selected])


    ////////////////////////////////////////////
    const handleToggleBlur = useCallback(() => {

        if (autocomplete) {
            return
        }

        setHideListTimer(setTimeout(() => {
            setActive(false)
        }, 300))

    }, [autocomplete])


    /////////////////////////////////////////////
    const handleToggleClick = useCallback(() => {

        if (autocomplete || disabled) {
            return
        }

        setActive(!isActive)

    }, [autocomplete, disabled, isActive])


    ///////////////////////////////////////////
    const filterList = useCallback(showAll => {

        const inputValue = inputRef?.current?.value

        setList(() => {

            // show all items

            if (
                showAll
                || !inputValue
            ) return [...sourceRef.current]

            // show filtered items

            return sourceRef.current.filter(item =>
                item[labelParamName]
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
            )

        })

    }, [labelParamName])


    /////////////////////////////////////////////////
    const handleItemClick = useCallback(newState => {

        inputBlurTimerRef?.current && clearTimeout(inputBlurTimerRef.current)
        if (multiple) clearTimeout(hideListTimerRef.current)

        if (inline && inlineClickHandler) {

            inlineClickHandler(newState)

        } else if (multiple) {

            const isItemSelected = selected.some(item => item.value === newState.value)
            let newSelected = [...selected]

            if (isItemSelected) {

                newSelected = selected.filter(item => item.value != newState.value)
                setSelected(newSelected)

            } else {

                newSelected.push(newState)
                setSelected(newSelected)

            }

        } else {

            setSelected([newState])

        }

        onItemClick && onItemClick(newState)

    }, [
        inline, inlineClickHandler, multiple, selected, onItemClick
        // name, onChange
    ])


    ///////////////////////////////
    const handleIconClick = () => {

        wasIconClicked = true

        if (autocomplete) {
            filterList(true)
            if (!isActive) {
                inputRef.current.focus()
            }
        }

        setActive(!isActive)

    }


    /////////////////////////////////////
    const handleInputFocus = (event) => {

        event.target.select()

        if (wasIconClicked) {
            wasIconClicked = false
        } else {
            filterList(true)
        }

        setActive(true)

    }


    ///////////////////////////////////////////
    const handleInputBlur = useCallback(() => {

        setHideListTimer(setTimeout(() => {
            setActive(false)
        }, 300))

        setInputBlurTimer(setTimeout(() => {

            if (selected[0] && !selectOrEnter) {
                setLabel(selected.map(item => item.label))
            }

        }, 100))

    }, [selected, selectOrEnter, hideListTimer])


    ////////////////////////////////////////////////
    const handleInputChange = useCallback(event => {

        setLabel([event.target.value])
        filterList()
        setActive(true)

        if (selectOrEnter && onChange) {
            onChange({
                target: {
                    name: name,
                    value: event.target.value
                }
            })
        }

    }, [selectOrEnter, onChange, name])


    /////////////////////////////////////////////////////
    const renderMultipleControls = useCallback(value => {

        if (!multiple) {
            return ''
        }

        const isValueSelected = selected.some(item => item.value == value)

        return (
            isValueSelected || inline
                ? <Icon name="minus" />
                : <Icon name="plus-thin" />
        )

    }, [multiple, selected, inline])


    /////////////////////////////////////////////////////
    const renderListItem = useCallback((item, index) => {

        const isItemSelected = selected.some(selectedItem => selectedItem.value == item[valueParamName])
        const itemClassName = `select-list-item ${isItemSelected ? 'select-list-item-selected' : ''}`

        return <div
            className={ itemClassName }
            key={ index }
            onClick={ () => {
                handleItemClick({
                    value: item[valueParamName],
                    label: item[labelParamName]
                })
            } }
            tabIndex="0"
            onBlur={
                () => {
                    multiple && setHideListTimer(setTimeout(() => {
                        setActive(false)
                    }, 300))
                }
            }
        >

            { counterParamName && counterParamName in item
                ? <NotificationCounter value={ item[counterParamName] } color={ item.counterColor } />
                : ''
            }

            <div className="select-list-item-value">{ item[labelParamName] }</div>
            { renderMultipleControls(item[valueParamName]) }

        </div>

    }, [
        renderMultipleControls, labelParamName, valueParamName,
        counterParamName, handleItemClick, selected, hideListTimer
    ])


    ///////////
    return <div
        className={ `select ${inlineClassName} ${className || ''}` }
        data-is-invalid={ isInvalid }
    >

        {
            !inline
                ? <div
                    className={ toggleClassName }
                    onClick={ handleToggleClick }
                    onBlur={ handleToggleBlur }
                    tabIndex="0"
                    title={ title }
                >
                    {
                        autocomplete
                            ? <input
                                type="text"
                                className={ `select-toggle-value ${placeholderClass}` }
                                value={ label.join(', ') || '' }
                                onFocus={ handleInputFocus }
                                onBlur={ handleInputBlur }
                                ref={ inputRef }
                                onChange={ handleInputChange }
                                placeholder={ placeholder }
                                name={ name }
                                disabled={ disabled }
                                title={ title }
                                autoComplete="off"
                            />
                            : <div className={ `select-toggle-value ${placeholderClass}` }>{ selected[0] ? label.join(', ') : (placeholder || '') }</div>
                    }
                    <div className="select-toggle-icon" onClick={ handleIconClick }><Icon name="chevron-down" /></div>
                </div>
                : ''
        }

        <div className={ `select-list ${listActiveClassName} ${(headerButton || footerButton) ? 'select-list-overflow' : ''} ${listUpClassName}` } ref={ listRef }>

            {
                headerButton
                    ? <button className="select-list-button" onClick={ headerButton.onClick }>
                        <Icon name={ headerButton.icon } />
                        { headerButton.label }
                    </button>
                    : ''
            }

            {
                (headerButton || footerButton)
                    ? <div
                        style={ { height: list.length * 38 } }
                        className={ `select-list-wrapper ${listUpClassName}` }
                    >
                        { list?.map((item, index) => renderListItem(item, index)) }
                    </div>
                    : list?.map((item, index) => renderListItem(item, index))
            }

            {
                footerButton
                    ? <button className="select-list-button" onClick={ footerButton.onClick }>
                        <Icon name={ footerButton.icon } />
                        { footerButton.label }
                    </button>
                    : ''
            }

        </div>

    </div>

}))