import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { AppContext } from '../../context/AppContext'
import { useApiRequest } from '../../hooks/api.request.hook'
import FormConstructor from '../FormConstructor/FormConstructor'
import { useModal } from '../Modal2/Modal2'

export const useShowAgencyNew = (
    modalFn: any,
    formRef: any,
    submitForm: Function,
    handleSubmit: SubmitHandler<any>,
    setStartUpload: any
) => {


    ///////////////////////////////////////////
    const { setModal, modalState } = useModal()
    const app = useContext(AppContext)
    const [socAccounts, setSocAccounts] = useState<any>([{ label: 'Новый', value: 'new' }])
    const apiRequest = useApiRequest()
    const [shouldUpdateModal, setShouldUpdateModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})


    /////////////////
    useEffect(() => {

        if (!modalFn.agencyNew) return
        setDefaultValues(modalFn.agencyNew)
        setShouldUpdateModal(true)
        delete modalFn.agencyNew

    }, [modalFn.agencyNew])


    /////////////////////////////////////////////////////////////
    const updateSocAccounts = useCallback(async (event: any) => {

        const agencyId = event.target?.value?.toString() || event.value?.toString()
        if (!agencyId) return

        await apiRequest({
            get: `/api/agencies/accounts/soc?agencyId=${agencyId}`,
            200: (response: any) => {

                const newAccounts = response.socAccounts.map((account: any) => {
                    return {
                        label: account.id,
                        value: account.id
                    }
                })

                newAccounts.unshift({ label: 'Новый', value: 'new' })
                setSocAccounts(newAccounts)
                setShouldUpdateModal(true)

            }
        })

    }, [setSocAccounts])


    ///////////////////////////////////////
    const formSchema: any = useMemo(() => {

        return [{
            label: 'Агентство',
            placeholder: 'Начните вводить агентство',
            type: 'select',
            autocomplete: true,
            name: 'agency',
            options: app.agencies || [],
            validation: { required: true },
            labelParamName: 'name',
            valueParamName: '_id',
            onChange: updateSocAccounts
        }, [{
            label: 'Лимит',
            type: 'radio-button',
            name: 'limit',
            options: [
                { label: '250', value: '250' },
                { label: '1500', value: '1500' },
                { label: 'Анлим', value: 'unlim' },
                { label: 'Любой', value: 'any' }
            ],
            validation: { required: true }
        }, {
            label: 'Количество',
            type: 'quantity',
            name: 'quantity',
            min: 1,
            max: 100,
            value: 1,
            validation: { required: true }
        }], [{
            label: 'Таймзона',
            name: 'timezone',
            placeholder: 'Введите таймзону',
            validation: { required: true }
        }, {
            label: 'Валюта',
            name: 'currency',
            placeholder: 'Введите валюту',
            validation: { required: true }
        }], {
            label: 'Соц. аккаунт',
            placeholder: 'Начните вводить соц. аккаунт',
            type: 'select',
            autocomplete: true,
            name: 'socAccount',
            options: socAccounts,
            validation: { required: true },
        }, {
            label: 'Ваш комментарий',
            placeholder: 'Напишите Ваш комментарий',
            type: 'textarea',
            name: 'comment'
        }]
    }, [app.agencies, updateSocAccounts, socAccounts])


    ////////////////////////////////////
    const modalContent = useMemo(() => {

        return <FormConstructor
            ref={ formRef }
            formSchema={ formSchema }
            defaultValues={ defaultValues }
            onSubmit={ data => handleSubmit({
                ...data,
                formSections: 1,
                social: 'agency',
                category: 'agencyNew'
            }) }
        />

    }, [formSchema, handleSubmit, defaultValues])


    //////////////////////////////////////////////
    const showModal = useCallback(() => setModal({
        title: 'Новый тикет',
        className: 'ticket-modal ticket-modal-agency-new',
        content: modalContent,
        floating: true,
        isVisible: true,
        buttons: [{
            icon: 'chevron-left2',
            label: 'Назад',
            className: 'button-plain-xl',
            action: modalFn.showConsumablesAgency
        }, {
            label: 'Создать тикет',
            action: submitForm,
        }],
        breadcrumbs: [
            ['Расходники', modalFn.showConsumables],
            ['Agency', modalFn.showConsumablesAgency],
            ['Новый']
        ]
    }), [modalContent])


    /////////////////
    useEffect(() => {

        if (
            shouldUpdateModal
            && modalState.isVisible
            && modalState.className === 'ticket-modal ticket-modal-agency-new'
        ) {
            showModal()
            setShouldUpdateModal(false)
        }

    }, [socAccounts, showModal, shouldUpdateModal, modalState])


    /////////////////
    useEffect(() => {

        if (
            modalState.className === 'ticket-modal ticket-modal-agency-new'
            && modalState.isVisible === false
        ) {
            setDefaultValues({})
        }

    }, [modalState.isVisible])


    /////////////////
    useEffect(() => {

        if (Object.keys(defaultValues).length > 0) {
            showModal()
            setDefaultValues({})
        }

    }, [modalContent, defaultValues, showModal, setDefaultValues])


    ////////////////
    return showModal


}