import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { AppContext } from '../../context/AppContext'
import FormConstructor from '../FormConstructor/FormConstructor'
import { updateFormSchema } from '../FormConstructor/updateFormSchema'
import { useModal } from '../Modal2/Modal2'
import { Uploader } from '../Uploader/Uploader'
import Icon from '../Icon/Icon'
import { formatFileSize } from '../../js/file'
import { useDefaultValues } from './default.values.hook'

export const useShowDesignStatic = (
	modalFn: any,
	formRef: any,
	submitForm: Function,
	handleSubmit: SubmitHandler<any>,
	setStartUpload: any
) => {


	///////////////////////////////////////////
	const { setModal, modalState } = useModal()
	const app = useContext(AppContext)
	const CATEGORY_ALIAS = 'designStatic'
	const { defaultValues, setDefaultValues } = useDefaultValues(modalFn, CATEGORY_ALIAS)
	const [resultFiles, setResultFiles] = useState<any>([])
	const [shouldUpdateModal, setShouldUpdateModal] = useState(false)
	const [parentId, setParentId] = useState('')


	/////////////////
	useEffect(() => {

		updateFormSchema(setFormSchema, {
			'geo-0': { options: app.geos || [] }
		})

	}, [app.geos])


	/////////////////
	useEffect(() => {

		updateFormSchema(setFormSchema, {
			'language-0': { options: app.languages || [] }
		})

	}, [app.languages])


	/////////////////
	useEffect(() => {

		if (modalFn.adaptationTicket?.categoryAlias !== CATEGORY_ALIAS) return

		updateFormSchema(setFormSchema, {
			'adaptation-0': {
				hidden: false,
				content: <>
					<div><strong>ТЗ</strong></div>
					<div>{ modalFn.adaptationTicket.techReq }</div>
					<br />
					<div><strong>Файлы</strong></div>
					<div>{ modalFn.adaptationTicket.files.map((file: any, index: number) =>
						<Fragment key={ index }>
							{ file.origName }{ index < modalFn.adaptationTicket.files.length - 1 ? ', ' : '' }
						</Fragment>
					) }</div>
				</>
			}
		})

		setParentId(modalFn.adaptationTicket.parentId)
		setShouldUpdateModal(true)
		delete modalFn.adaptationTicket

	}, [modalFn.adaptationTicket])


	//////////////////////////////////////////////
	const [formSchema, setFormSchema] = useState([
		{
			type: 'custom',
			name: 'adaptation-0',
			hidden: true
		}, [
			{
				label: 'Гео',
				placeholder: 'Выберите гео',
				type: 'select',
				name: 'geo-0',
				autocomplete: true,
				options: [],
				validation: { required: true },
				labelParamName: 'name',
				valueParamName: '_id'
			}, {
				label: 'Язык',
				placeholder: 'Начните вводить язык',
				type: 'select',
				name: 'language-0',
				autocomplete: true,
				options: [],
				validation: { required: true },
				labelParamName: 'name',
				valueParamName: '_id'
			}
		], {
			label: 'ТЗ',
			placeholder: 'Напишите ТЗ',
			type: 'textarea',
			name: 'techReq-0',
			validation: { required: true }
		}, {
			label: 'Ваш комментарий',
			placeholder: 'Ваш комментарий',
			type: 'textarea',
			name: 'comment-0'
		}
	])


	/////////////////////////////////////
	const modalContent = useMemo(() => <>

		<FormConstructor
			ref={ formRef }
			defaultValues={ defaultValues }
			formSchema={ formSchema }
			onSubmit={ (data) =>
				handleSubmit({
					...data,
					formSections: 1,
					social: 'design',
					'type-0': 'designStatic',
					parentId: parentId
				})
			}
		/>

		<div className="ticket-modal-heading">Прикрепить файл</div>

		<div className="ticket-modal-files">
			{ resultFiles?.map((result: any) =>
				<div className="ticket-details-file" key={ result?.name }>
					<span>
						<div className="ticket-details-file-name">{ result?.name }</div>
						<div className="ticket-details-file-size">{ formatFileSize(result?.size) }</div>
					</span>
					<Icon onClick={ () => {
						setResultFiles((prev: any) => prev.filter((item: any) => item?.name !== result?.name))
						setShouldUpdateModal(true)
					} } name="close" />
				</div>
			) }
		</div>

		<Uploader
			url="/api/files"
			postData={ { type: 'ticket' } }
			setStartUpload={ setStartUpload }
			resultFiles={ resultFiles }
			onChange={ files => {
				setResultFiles(files)
				setShouldUpdateModal(true)
			} }
			multiple
			name="file-0"
		/>

	</>, [formSchema, handleSubmit, defaultValues, parentId, resultFiles, setShouldUpdateModal, setResultFiles])


	//////////////////////////////
	const showModal = useCallback(
		() =>
			setModal({
				title: 'Новый тикет',
				className: 'ticket-modal ticket-modal-design-static',
				content: modalContent,
				floating: true,
				isVisible: true,
				buttons: [
					{
						icon: 'chevron-left2',
						label: 'Назад',
						action: modalFn.showConsumablesDesign,
						className: 'button-plain-xl',
					},
					{
						label: 'Создать тикет',
						action: submitForm,
					},
				],
				breadcrumbs: [
					['Расходники', modalFn.showConsumables],
					['Creative', modalFn.showConsumablesDesign],
					['Статика'],
				],
			}),
		[modalContent]
	)


	/////////////////
	useEffect(() => {

		if (
			shouldUpdateModal
			&& modalState.isVisible
			&& modalState.className === 'ticket-modal ticket-modal-design-static'
		) {
			showModal()
			setShouldUpdateModal(false)
		}

	}, [shouldUpdateModal, showModal, modalState])


	/////////////////
	useEffect(() => {
		if (defaultValues) {
			showModal()
			setDefaultValues(null)
		}
	}, [modalContent])


	////////////////
	return showModal

}
