import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Form, formStateToJson, updateFormState, validateForm } from '../../components/Form/Form'
import { handleInputChange } from '../../components/Form/handleInputChange'
import { Icon } from '../../components/Icon/Icon'
import { Modal } from '../../components/Modal/Modal'
import { Radio } from '../../components/Radio/Radio'
import { AppContext } from '../../context/AppContext'
import { useApiRequest } from '../../hooks/api.request.hook'

export const CostsAddForm = memo(({
    isVisible,
    setVisible,
    websList,
    updateCostsList,
    requestId,
    categoryAlias,
    webName,
    editingItem,
    saveWoCostAction,
    request = {},
    callback
}) => {


    //////////////////////////////////
    const app = useContext(AppContext)
    let categoriesSource = useMemo(() => [...app.categories || []], [app.categories])
    const radioNamePostfix = `-${editingItem === null || editingItem ? 'edit' : 'add'}`
    const [modalTitle, setModalTitle] = useState('')
    const apiRequest = useApiRequest()


    /////////////////
    useEffect(() => {

        if (request._id && isVisible) {

            updateFormState(setFormState, {
                ...request,
                web: request.giver?._id,
                sum: '',
                comment: '',
                category: {
                    value: request.category._id,
                    source: categoriesSource.filter(category =>
                        category.isActive
                        || request?.category._id === category._id
                    )
                },
                qty: request.data?.qty || 1,
                currency: '$'
            })

        }

    }, [request._id, isVisible, request, categoriesSource])


    /////////////////
    useEffect(() => {

        if (editingItem) {

            updateFormState(setFormState, {
                ...editingItem,
                category: {
                    value: editingItem.category,
                    source: categoriesSource.filter(category =>
                        category.isActive
                        || editingItem?.category?._id === category._id
                    ),
                    selectedIndex: categoriesSource.findIndex(category =>
                        category._id === editingItem?.category?._id
                    )
                },
                team: {
                    value: editingItem.team,
                    source: app.teams.filter(team =>
                        team.isActive
                        || editingItem?.team?._id === team._id
                    ),
                    selectedIndex: app.teams.findIndex(team =>
                        team._id === editingItem?.team?._id
                    )
                },
                web: {
                    value: editingItem.web.webName,
                    selectedIndex: websList.findIndex(web =>
                        web._id === editingItem?.web?._id
                    )
                }
            })

            setModalTitle('Изменить расход')

        } else {

            setModalTitle('Добавить расход')

        }

    }, [editingItem, categoriesSource])


    /////////////////////////////////////////
    let selectedCategoryIndex = categoryAlias
        ? app.categories?.findIndex(category => category.alias === categoryAlias)
        : 0


    if (selectedCategoryIndex < 0) {
        categoriesSource.unshift({ name: 'Задача', alias: 'task' })
        selectedCategoryIndex = 0
    }


    //////////////////////
    const currencyList = [
        { label: '$', value: '$' },
        { label: '€', value: '€' }
    ]


    ////////////////////////////////////////////
    const [formState, setFormState] = useState([
        [{
            title: 'Категория',
            type: 'autocomplete',
            name: 'category',
            required: 'string',
            source: categoriesSource.filter(category => category.isActive || editingItem?.category._id === category._id),
            labelParamName: 'name',
            valueParamName: '_id',
            selectedIndex: selectedCategoryIndex,
            // disabled: Boolean(categoryAlias)
        }, {
            title: 'Веб',
            type: 'autocomplete',
            name: 'web',
            placeholder: 'Начните вводить имя',
            required: 'string',
            source: websList,
            labelParamName: 'webName',
            valueParamName: '_id',
            // selectedIndex: selectedWebIndex,
            // disabled: Boolean(webName)
        }], {
            title: 'Team',
            type: 'autocomplete',
            name: 'team',
            placeholder: 'Начните вводить название',
            source: app.teams,
            labelParamName: 'name',
            valueParamName: '_id',
            hidden: editingItem !== null && !editingItem
        }, [{
            title: 'Сумма',
            className: 'form-sum',
            items: [
                {
                    type: 'text',
                    name: 'sum',
                    placeholder: 0,
                    required: 'number',
                    value: request?.data?.sum || ''
                }, {
                    type: 'custom',
                    content: ''
                }, {
                    type: 'select',
                    name: 'currency',
                    source: currencyList,
                    selectedIndex: 0,
                    required: 'string'
                }
            ]
        }, {
            title: 'Количество',
            name: 'qty',
            type: 'quantity',
            value: request?.data?.qty || 1,
            min: 1,
            max: 1000
        }], {
            title: 'Ваш комментарий',
            type: 'textarea',
            name: 'comment',
            placeholder: 'Напишите свой комментарий',
            unnecessary: true
        }, {
            name: `report${radioNamePostfix}`,
            value: 'cost'
        }
    ])


    ////////////////////////////////////////////
    const submitForm = useCallback(async () => {

        const isValid = validateForm(formState, '.costs-add-form', setFormState)

        if (!isValid) {
            return
        }

        const extraParams = { requestId }

        if (editingItem) {
            extraParams.costId = editingItem?._id
        } else {
            const formParams = JSON.parse(formStateToJson(formState))
            extraParams.team = websList.find(web => web._id === formParams.web).team
        }

        await apiRequest({
            [editingItem ? 'put' : 'post']: '/api/costs',
            body: formStateToJson(formState, extraParams),
            200: async () => {
                updateCostsList && await updateCostsList()
                callback && await callback()
            }
        })

        setVisible(false)

    }, [apiRequest, callback, editingItem, formState, requestId, setVisible, updateCostsList, websList])


    ////////////////////////////////////
    const modalButtons = useMemo(() => [
        {
            title: 'Сохранить без расхода',
            className: 'button-outline-xl',
            hidden: !saveWoCostAction,
            action: (event) => {
                event.preventDefault()
                saveWoCostAction && saveWoCostAction()
                callback && callback()
            }
        }, {
            title: editingItem ? 'Сохранить' : 'Добавить расход',
            className: 'button-default-xl',
            action: (event) => {
                event.preventDefault()
                submitForm()
            }
        }
    ], [editingItem, saveWoCostAction, submitForm])


    ///////////////////////////////////////////
    const modalHeaderContent = useMemo(() => <>

        <Radio
            name={ `report${radioNamePostfix}` }
            value="cost"
            label="Кост"
            type='radio-button'
            checked={ formState.some(item => item.name === `report${radioNamePostfix}` && item.value === 'cost') }
            onChange={ (event) => {
                handleInputChange(event, formState, setFormState)
            } }
        />

        <Radio
            name={ `report${radioNamePostfix}` }
            value="anticost"
            type='radio-button'
            label="Антикост"
            checked={ formState.some(item => item.name === `report${radioNamePostfix}` && item.value === 'anticost') }
            onChange={ (event) => {
                handleInputChange(event, formState, setFormState)
            } }
        />

    </>, [formState, radioNamePostfix])


    ////////////////////////////////////////
    const modalContent = useMemo(() => <Form
        submitFn={ submitForm }
        isVisible={ isVisible }
        formState={ formState }
        setFormState={ setFormState }
    />, [formState, isVisible, submitForm])


    /////////////
    return <Modal
        isVisible={ isVisible }
        setVisible={ setVisible }
        className="costs-add-form"
        buttons={ modalButtons }
        title={ modalTitle }
        headerContent={ modalHeaderContent }
        content={ modalContent }
    />


})


//////////////////////////////////////////////////////////////
export const CostsAddBtn = memo(({ setFormVisible, app }) => {

    return <button
        className="button-default-xl"
        onClick={ () => { setFormVisible(true) } }
    >
        <Icon
            name="plus"
        />
        Добавить
    </button>

})