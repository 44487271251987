import React, {
	useCallback,
	useContext, useEffect, useMemo, useState
} from 'react'
import { SubmitHandler } from 'react-hook-form'
import { AppContext } from '../../context/AppContext'
import FormConstructor from '../FormConstructor/FormConstructor'
import { useModal } from '../Modal2/Modal2'
import { Uploader } from '../Uploader/Uploader'
import { useDefaultValues } from './default.values.hook'
import Icon from '../Icon/Icon'
import { formatFileSize } from '../../js/file'

export const useShowTech = (
	modalFn: any,
	formRef: any,
	submitForm: Function,
	handleSubmit: SubmitHandler<any>,
	setStartUpload: any
) => {


	///////////////////////////////////////////
	const { setModal, modalState } = useModal()
	const [resultFiles, setResultFiles] = useState<any>([])
	const [shouldUpdateModal, setShouldUpdateModal] = useState(false)
	const app = useContext(AppContext)
	const { defaultValues, setDefaultValues } = useDefaultValues(modalFn, 'tech')


	//////////////////////////////////
	const techTopics = useMemo(() => {
		if (!app.techTopics) return []

		const newTopics = app.techTopics
			.filter((item: any) => item.isActive)
			.map((item: any) => ({
				label: `${item.department.name} | ${item.name}`,
				value: [item.department, { _id: item._id, name: item.name }],
			}))

		newTopics.push({
			label: 'Other',
			value: [{ name: 'Other' }, { _id: null, name: null }],
		})
		return newTopics
	}, [app.techTopics])


	////////////////////////////
	const formSchema = useMemo(
		() => [
			{
				label: 'Тема обращения',
				placeholder: 'Выберите тему',
				type: 'select',
				name: 'topic',
				options: techTopics,
				validation: { required: true },
			},
			{
				label: 'Дополнительная информация',
				placeholder: 'Опишите проблему',
				type: 'textarea',
				name: 'comment',
				validation: { required: true },
			},
		],
		[techTopics]
	)


	////////////////////////////////////
	const modalContent = useMemo(() => {
		return (
			<>

				<FormConstructor
					ref={ formRef }
					formSchema={ formSchema }
					defaultValues={ defaultValues }
					sectionTitle="Техническую помощь"
					sectionCount={ 5 }
					onSubmit={ (data) =>
						handleSubmit({
							...data,
							social: 'tech',
							category: 'tech',
						})
					}
				/>

				<div className="ticket-modal-heading">Прикрепить файл</div>

				<div className="ticket-modal-files">
					{ resultFiles?.map((result: any) =>
						<div className="ticket-details-file" key={ result?.name }>
							<span>
								<div className="ticket-details-file-name">{ result?.name }</div>
								<div className="ticket-details-file-size">{ formatFileSize(result?.size) }</div>
							</span>
							<Icon onClick={ () => {
								setResultFiles((prev: any) => prev.filter((item: any) => item?.name !== result?.name))
								setShouldUpdateModal(true)
							} } name="close" />
						</div>
					) }
				</div>

				<Uploader
					url="/api/files"
					postData={ { type: 'ticket' } }
					setStartUpload={ setStartUpload }
					resultFiles={ resultFiles }
					onChange={ files => {
						setResultFiles(files)
						setShouldUpdateModal(true)
					} }
					multiple
					name="file-0"
				/>

			</>
		)
	}, [formSchema, handleSubmit, defaultValues, resultFiles, setResultFiles, setShouldUpdateModal, setStartUpload])


	//////////////////////////////
	const showModal = useCallback(
		() =>
			setModal({
				title: 'Техническая помощь',
				floating: true,
				className: 'ticket-modal ticket-modal-tech',
				content: modalContent,
				isVisible: true,
				buttons: [
					{
						icon: 'plus',
						label: 'Еще один тикет',
						action: () => formRef.current?.append(),
						className: 'button-plain-xl',
					},
					{
						label: 'Создать тикет',
						action: submitForm,
					},
				],
			}),
		[modalContent, submitForm]
	)


	/////////////////
	useEffect(() => {

		if (
			shouldUpdateModal
			&& modalState.isVisible
			&& modalState.className === 'ticket-modal ticket-modal-tech'
		) {
			showModal()
			setShouldUpdateModal(false)
		}

	}, [shouldUpdateModal, showModal, modalState])


	/////////////////
	useEffect(() => {
		if (defaultValues) {
			showModal()
			setDefaultValues(null)
		}
	}, [modalContent, defaultValues, showModal, setDefaultValues])


	////////////////
	return showModal


}
