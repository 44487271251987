import { createContext, useCallback, useEffect, useState } from 'react'
import './DelayedActionContext.scss'
import { Icon } from '../../components/Icon/Icon'
import { ProgressRing } from '../../components/ProgressRing/ProgressRing'
import { If } from '../../components/If'

export const DelayedActionContext = createContext()

export const DelayedActionProvider = ({ children }) => {


    //////////////////////////
    const DEFAULT_DELAY = 5000
    const [isVisible, setVisible] = useState(false)
    const [queue, setQueue] = useState([])


    /////////////////
    useEffect(() => {
        if (!queue.length) setVisible(false)
    }, [queue])


    /////////////////////////////////////////
    const set = useCallback(newQueueItem => {

        setVisible(true)

        setQueue(prevState => {

            const newState = [...prevState]

            const existingAction = newState.find(item =>
                item.title === newQueueItem.title
                && item.action === newQueueItem.action
            )

            if (existingAction) {

                existingAction.delay = newQueueItem.delay || DEFAULT_DELAY
                existingAction.leftSec = (newQueueItem.delay || DEFAULT_DELAY) / 1000

            } else {

                newState.unshift({
                    ...newQueueItem,
                    id: Math.random(),
                    delay: newQueueItem.delay || DEFAULT_DELAY,
                    leftSec: (newQueueItem.delay || DEFAULT_DELAY) / 1000
                })

            }

            return newState

        })

    }, [])


    /////////////////
    useEffect(() => {

        const intervalId = setInterval(() => {

            // if (!queue.length) return

            setQueue(prevState => {

                if (!prevState.length) return prevState

                const expiredItems = prevState.filter(item =>
                    item.leftSec <= 1 && item.action
                )

                if (expiredItems.length) {
                    expiredItems.forEach(item =>
                        item.action()
                    )
                }

                const newState = prevState
                    .filter(item => item.leftSec > 1)
                    .map(item => (
                        { ...item, leftSec: item.leftSec - 1 }
                    ))

                return newState

            })

        }, 1000)

        return () => clearInterval(intervalId)

    }, [])


    ////////////////////////////////////////
    const cancelAction = useCallback(id => {

        if (!id) {
            setQueue([])
            setVisible(false)
            return
        }

        setQueue(prevState =>
            prevState.filter(item =>
                item.id !== id
            )
        )

    }, [])


    ////////////////////////////////////////////////////////
    return <DelayedActionContext.Provider value={ { set } }>

        { children }

        <div className={ `
            delayed-action
            ${isVisible ? 'delayed-action-visible' : ''}
        `}>

            <div className="delayed-action-list">

                { queue.map((item, index) =>

                    <div key={ index } className="delayed-action-item button button-2" onClick={ () => cancelAction(item.id) }>
                        <div className="delayed-action-title">{ item.title }</div>
                        <div className="delayed-action-type">{ item.type } через</div>
                        <div className="delayed-action-time">
                            <ProgressRing max={ item.delay / 1000 } current={ item.leftSec } />
                        </div>
                        <div className="delayed-action-hover">
                            {/* <Icon name="square-cross" /> will uncomment later after review */ }
                            Отменить
                        </div>
                    </div>

                ) }

            </div>

            <If condition={ queue.length > 1 }>
                <button
                    className="delayed-action-cancel-btn button button-1"
                    onClick={ () => cancelAction() }
                >Отменить все</button>
            </If>

        </div>

    </DelayedActionContext.Provider>


}