import 'normalize.css'
import { memo, useCallback, useState } from 'react'
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet'
import { Route, Routes } from 'react-router-dom'
import { DropZone } from './components/DropZone/DropZone'
import { If } from './components/If'
import { Loader } from './components/Loader/Loader'
import { ModalProvider } from './components/Modal2/Modal2'
import { AppContextProvider } from './context/AppContext'
import { AuthContext } from './context/AuthContext'
import { DelayedActionProvider } from './context/DelayedActionContext/DelayedActionContext.jsx'
import { SocketProvider } from './context/SocketContext'
import { useAuth } from './hooks/auth.hook'
import { handleError } from './js/lib'
import { Provider } from 'react-redux'
import { store } from './store/store'
import { authRoutes, nonAuthRoutes } from './routes/routes'

const App = memo(() => {


    ////////////////////////////////////////////////////
    const [isLoaderVisible, setLoader] = useState(false)
    const auth = useAuth(setLoader)
    const router = createBrowserRouter(auth.isAuth ? authRoutes : nonAuthRoutes, {
        errorHandler: (error) => {
            console.error('Router error:', error);
            handleError({
                message: error.message,
                source: 'router.error',
                auth,
            });
        },
    })


    //////////////////////////////////////////////////////////////
    window.onerror = (message, url, line, column) => handleError({
        message,
        url,
        line,
        column,
        auth,
        source: 'front.window.onerror'
    })


    /////////////////////////////////////////////////////////////////
    const Fallback = useCallback(() => <div className="fallback-div">

        Something went wrong

        <button
            onClick={ () => window.location.reload() }
            className="button-default-xl"
        >Reload page</button>

    </div>, [])


    //////////////////////////////////////////////
    const onError = useCallback((error, info) => {
        console.log(error, info)
    }, [])


    /////
    try {

        return <ErrorBoundary FallbackComponent={ Fallback } onError={ onError }>

            <Provider store={ store }>
                <DelayedActionProvider>
                    <AuthContext.Provider value={ auth }>
                        <SocketProvider>
                            <AppContextProvider setLoader={ setLoader }>
                                <ModalProvider>

                                    <Helmet>
                                        <title>TDFarmCRM</title>
                                    </Helmet>

                                    <RouterProvider router={ router } />

                                    <DropZone />
                                    <Loader isVisible={ isLoaderVisible } />

                                </ModalProvider>
                            </AppContextProvider>
                        </SocketProvider>
                    </AuthContext.Provider>
                </DelayedActionProvider>
            </Provider>

        </ErrorBoundary>

    } catch (error) {

        handleError({
            message: error.message,
            source: 'front.try.catch',
            auth
        })

    }

})

export default App