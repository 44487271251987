import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { AppContext } from '../../context/AppContext'
import FormConstructor from '../FormConstructor/FormConstructor'
import { everyFormItem, updateFormSchema } from '../FormConstructor/updateFormSchema'
import { useModal } from '../Modal2/Modal2'
import { useDefaultValues } from './default.values.hook'

export const useShowFacebookProxy = (
    modalFn: any,
    formRef: any,
    submitForm: Function,
    handleSubmit: SubmitHandler<any>,
) => {


    ///////////////////////////////////////////
    const { setModal, modalState } = useModal()
    const app = useContext(AppContext)
    const [shouldUpdateModal, setShouldUpdateModal] = useState(false)
    const proxiesRef = useRef(app.proxies)
    const sellersRef = useRef(app.sellers)
    const geosRef = useRef(app.geos)
    const { defaultValues, setDefaultValues } = useDefaultValues(modalFn, 'proxy')


    useEffect(() => {

        // PROXY PROLONG FROM PROXY PAGE

        if (!modalFn.proxyProlong) return
        handleTypeChange({ target: { value: 'prolong' } })
        handleProxyChange({ target: { value: modalFn.proxyProlong.proxyId } })

    }, [modalFn.proxyProlong])


    /////////////////
    useEffect(() => {

        // FILL PROXY LIST

        if (!app.proxies) return
        proxiesRef.current = app.proxies

        updateFormSchema(setFormSchema, {
            'proxyId-0': {
                options: proxiesRef.current || []
            }
        })

        setShouldUpdateModal(true)

    }, [app.proxies])


    /////////////////
    useEffect(() => {

        // FILL SELLER LIST

        if (!app.sellers) return
        sellersRef.current = app.sellers

        updateFormSchema(setFormSchema, {
            'seller-0': {
                options: sellersRef.current?.filter((seller: any) => seller.isActive) || []
            }
        })

        setShouldUpdateModal(true)

    }, [app.sellers])


    /////////////////
    useEffect(() => {

        // FILL GEO LIST

        if (!app.geos) return
        geosRef.current = app.geos

        updateFormSchema(setFormSchema, {
            'geo-0': {
                options: app.geos?.filter((geo: any) => geo.isActive) || []
            }
        })

        setShouldUpdateModal(true)

    }, [app.geos])


    //////////////////////////////////////////////////////
    const handleTypeChange = useCallback((event: any) => {

        const selectedType = event.target.value

        const {
            newDurationParams,
            newDurationUnitParams,
            newMobileDurationParams
        } = getProxyDurationParams(selectedType)

        setFormSchema(prevState =>

            everyFormItem(prevState, item => {

                if (item.name?.startsWith('seller-')) {
                    const filteredSellers = sellersRef.current.filter((seller: any) =>
                        seller.category === selectedType
                    )
                    item.options = filteredSellers
                }

                if (
                    item.name?.startsWith('seller-')
                    || item.name?.startsWith('ipChangeLink-')
                    || item.name?.startsWith('protocol-')
                    || item.name?.startsWith('geo-')
                    || item.name?.startsWith('qty-')
                    || item.oneThirdSpacer
                ) {
                    item.hidden = selectedType === 'prolong'
                }

                if (item.name?.startsWith('proxyId-')) {
                    item.hidden = selectedType !== 'prolong'
                }

                if (item.name?.startsWith('duration-')) {
                    Object.assign(item, newDurationParams)
                }

                if (item.name?.startsWith('durationUnit-')) {
                    Object.assign(item, newDurationUnitParams)
                }

                if (item.name?.startsWith('mobileDuration-')) {
                    Object.assign(item, newMobileDurationParams)
                }

                if (item.name?.startsWith('mobileDurationUnit-')) {
                    Object.assign(item, newMobileDurationParams)
                }

                if (item.name?.startsWith('type-')) {

                    item.options = item.options.map((option: any) => {
                        if (option.value === selectedType) {
                            option.checked = true
                        } else {
                            option.checked = false
                        }
                        return option
                    })

                }

            })

        )

        setShouldUpdateModal(true)

    }, [])


    ///////////////////////////////////////////////////////
    const handleProxyChange = useCallback((event: any) => {

        if (!proxiesRef.current) return

        const selectedProxyId = event.target.value
        if (!selectedProxyId) return

        const selectedProxy = proxiesRef.current.find((proxy: any) =>
            proxy._id === selectedProxyId
        )

        if (!selectedProxy) return

        const selectedProxyIndex = proxiesRef.current.findIndex((proxy: any) =>
            proxy._id === selectedProxyId
        )

        const {
            newDurationParams,
            newDurationUnitParams,
            newMobileDurationParams
        } = getProxyDurationParams(selectedProxy.category)

        setDefaultValues((prevState: any) => ({
            ...prevState,
            [`proxyId-0`]: selectedProxy?._id,
            [`type-0`]: 'prolong'
        }))

        updateFormSchema(setFormSchema, {
            [`duration-0`]: newDurationParams,
            [`durationUnit-0`]: newDurationUnitParams,
            [`mobileDuration-0`]: newMobileDurationParams,
            ['proxyId-0']: { selectedIndex: selectedProxyIndex } // for prolong from proxy page
        })

        setShouldUpdateModal(true)

    }, [])


    ///////////////////////////////////////////////////////////////////////
    const [formSchema, setFormSchema] = useState<Array<any>>([
        [
            {
                label: 'Тип',
                name: 'type-0',
                type: 'radio-button',
                validation: { required: true },
                options: [
                    { label: 'Статика', value: 'static' },
                    { label: 'Мобильная', value: 'mobile', checked: true },
                    { label: 'Резидентская', value: 'resident' },
                    { label: 'Продление', value: 'prolong' }
                ],
                onChange: handleTypeChange
            }, {
                label: 'Ссылка на смену IP',
                name: 'ipChangeLink-0',
                type: 'radio-button',
                validation: { required: true },
                options: [
                    { label: 'Да', value: 'yes' },
                    { label: 'Нет', value: 'no' }
                ]
            }
        ], [
            {
                label: 'Протокол',
                name: 'protocol-0',
                type: 'radio-button',
                validation: { required: true },
                options: [
                    { label: 'Socks5', value: 'socks5' },
                    { label: 'HTTPS', value: 'https' }
                ]
            }, {
                label: 'Селлер',
                name: 'seller-0',
                type: 'select',
                options: [],
                placeholder: 'Выберите селлера',
                labelParamName: 'name',
                valueParamName: '_id'
            }
        ], {
            label: 'Прокси',
            placeholder: 'Выберите прокси',
            type: 'select',
            autocomplete: true,
            name: `proxyId-0`,
            options: app.proxies,
            valueParamName: '_id',
            hidden: true,
            validation: { required: true },
            onChange: handleProxyChange
        }, [
            {
                label: 'Гео',
                name: 'geo-0',
                type: 'select',
                options: [],
                autocomplete: true,
                placeholder: 'Выберите гео',
                labelParamName: 'name',
                valueParamName: '_id',
                validation: { required: true }
            }, {
                label: 'Длительность',
                name: 'duration-0',
                disabled: true,
                placeholder: 'Сначала выберите тип',
                validation: { required: true },
                type: 'text',
                options: [
                    { label: '100 мб', value: 100 },
                    { label: '300 мб', value: 300 },
                    { label: '500 мб', value: 500 },
                    { label: '1 гб', value: 1024 },
                    { label: '2 гб', value: 2048 },
                    { label: '5 гб', value: 5120 }
                ]
            }, {
                name: 'durationUnit-0',
                type: 'hidden',
                // hidden: true
            }, {
                label: 'Длительность', // for mobile type
                type: 'text',
                name: `mobileDuration-0`,
                placeholder: '0',
                validation: { required: true },
                hidden: true
            }, {
                type: 'radio-button',
                name: `mobileDurationUnit-0`,
                options: [
                    { label: 'Дней', value: 'days', checked: true },
                    { label: 'Мбайт', value: 'mbytes' }
                ],
                validation: { required: true },
                hidden: true
            }, {
                label: 'Необходимое количество',
                placeholder: 'Введите количество',
                type: 'quantity',
                name: 'qty-0',
                value: 1,
                min: 1,
                max: 1000,
                required: 'number'
            }
        ], {
            label: 'Ваш комментарий (необязательно)',
            placeholder: 'Ваш комментарий',
            type: 'textarea',
            name: 'comment-0',
        }
    ])


    ////////////////////////////////////
    const modalContent = useMemo(() => {

        return (
            <>
                <FormConstructor
                    ref={ formRef }
                    formSchema={ formSchema }
                    defaultValues={ defaultValues }
                    sectionCount={ null }
                    sectionTitle="Прокси"
                    onSubmit={ (data) =>
                        handleSubmit({
                            ...data,
                            formSections: 1,
                            social: 'facebook',
                            category: 'proxy',
                        })
                    }
                />
            </>
        )
    }, [formSchema, handleSubmit, defaultValues, modalFn])


    //////////////////////////////////////////////
    const showModal = useCallback(() => setModal({
        title: 'Новый тикет',
        className: 'ticket-modal ticket-modal-proxy',
        content: modalContent,
        floating: true,
        isVisible: true,
        buttons: [
            // {
            //     icon: 'plus',
            //     label: 'Еще один тикет',
            //     action: () => formRef.current?.append(),
            //     className: 'button-plain-xl',
            // },
            {
                label: 'Создать тикет',
                action: submitForm,
            },
        ],
        breadcrumbs: [
            ['Расходники', modalFn.showConsumables],
            ['Facebook', modalFn.showConsumablesFacebook],
            ['Прокси']
        ]
    }), [modalContent])


    /////////////////
    useEffect(() => {

        if (
            shouldUpdateModal
            && modalState.isVisible
            && modalState.className === 'ticket-modal ticket-modal-proxy'
        ) {
            showModal()
            setShouldUpdateModal(false)
        }

    }, [shouldUpdateModal, showModal, modalState])


    /////////////////
    useEffect(() => {

        if (defaultValues) {
            showModal()
            setDefaultValues(null)
        }

    }, [modalContent, defaultValues, showModal, setDefaultValues])


    ////////////////
    return showModal


}


////////////////////////////////////////////////
const getProxyDurationParams = (type: string): {
    newDurationParams: any,
    newDurationUnitParams: any,
    newMobileDurationParams: any
} => {

    if (type === 'static') {

        return {
            newDurationParams: {
                type: 'text',
                placeholder: '0',
                label: 'Длительность (дней)',
                disabled: false,
                hidden: false,
            },
            newDurationUnitParams: {
                value: 'days',
            },
            newMobileDurationParams: {
                hidden: true,
            }
        }

    } else if (type === 'prolong') {

        return {
            newDurationParams: {
                disabled: true,
                placeholder: 'Сначала выберите прокси',
                label: 'Длительность',
                type: 'select',
                hidden: false,
            },
            newDurationUnitParams: {
            },
            newMobileDurationParams: {
                hidden: true,
            }
        }

    } else if (type === 'resident') {

        return {
            newDurationParams: {
                type: 'select',
                placeholder: 'Выберите длительность',
                label: 'Длительность',
                disabled: false,
                hidden: false,
            },
            newDurationUnitParams: {
                value: 'mbytes',
            },
            newMobileDurationParams: {
                hidden: true,
            }
        }

    } else if (type === 'mobile') {

        return {
            newDurationParams: {
                hidden: true,
            },
            newDurationUnitParams: {
                hidden: true,
            },
            newMobileDurationParams: {
                hidden: false,
            }
        }

    }

    return {
        newDurationParams: {},
        newDurationUnitParams: {},
        newMobileDurationParams: {}
    }

}