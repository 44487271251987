import { memo, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon } from '../../components/Icon/Icon'
import { AppContext } from '../../context/AppContext'
import { AuthContext } from '../../context/AuthContext'
import { useApiRequest } from '../../hooks/api.request.hook'
import { formatDate } from '../../js/date'
import { cleanHTML, shortenText } from '../../js/text'
import './OverviewNews.light.scss'

export const OverviewNews = memo(() => {


    ////////////////////////////////////
    const [news, setNews] = useState([])
    const auth = useContext(AuthContext)
    const app = useContext(AppContext)
    const navigate = useNavigate()
    const apiRequest = useApiRequest()


    /////////////////////////////////////////
    const getNews = useCallback(async () => {

        await apiRequest({
            get: '/api/news?limit=5',
            200: response => setNews(response.news)
        })

    }, [apiRequest])


    /////////////////////////////////////////
    useEffect(() => { getNews() }, [getNews])

    ///////////////////////////////////////
    return <div className="overview-news">

        <div className="overview-header">
            <div className="overview-title" onClick={ () => navigate('/news/') }>Новости</div>
        </div>

        <div className="overview-news-list">
            {
                news.map((item, index) => {

                    const plainText = cleanHTML(item.text)
                    const shortenedText = shortenText(plainText, 100)

                    return (
                        <div className="overview-news-item" onClick={ () => navigate(`/news/${item._id}`) } key={ index }>

                            <div className="overview-news-title overview-news-title-unread">{ item.title }</div>
                            <div className="overview-news-text">{ shortenedText }</div>

                            <div className="overview-news-date">
                                <Icon name="clock" />
                                <label>{ formatDate(item.createdAt) }</label>
                            </div>

                        </div>
                    )

                })
            }
        </div>

    </div>


})