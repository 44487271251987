import { useMemo } from 'react'
import { useTicketModalConstructor } from './ticket.modal.constructor'

export const useShowStart = (modalFn: any) => {


    ///////////////////////////////////////////
    const ticketModalButtons = useMemo(() => [{
        name: 'new-ticket', // уникальное имя кнопки для идентификации нажатия
        icon: 'new-ticket',
        label: 'Новый тикет',
        action: 'showNewTicket' // вызывается при выбранной кнопке и нажатии "Далее"
    }, {
        name: 'templates',
        icon: 'template',
        label: 'Выбрать шаблон',
        action: 'showTemplates'
    }], [])


    //////////////////////////////////
    return useTicketModalConstructor({
        title: 'Формирование тикета',
        name: 'start', // unique form & modal class postfix
        size: 'large', // buttons height (medium (72px) | large (104px) | default: small (52px))
        ticketModalButtons,
        modalFn,
        showSearch: true
    })


}
