import { useContext, useEffect, useState } from 'react'
import { WindowTitle } from '../../components/WindowTitle/WindowTitle'
import { AppContext } from '../../context/AppContext'
import { AuthContext } from '../../context/AuthContext'
import { useApiRequest } from '../../hooks/api.request.hook'
import { getWebs } from '../../js/get'
import { matchGroups, setPageHeight } from '../../js/lib'
import { CostsAddBtn, CostsAddForm } from '../CostsPage/CostsAddForm'
import { OverviewCharts } from './OverviewCharts'
import { OverviewNews } from './OverviewNews'
import './OverviewPage.light.scss'
import { OverviewRequests } from './OverviewRequests'

export const OverviewPage = () => {


    ///////////////////////////////////////////////////////////
    useEffect(() => { setPageHeight('div.overview-grid') }, [])
    const [isCostsAddFormVisible, setCostsAddFormVisible] = useState(false)
    const [websList, setWebsList] = useState([])
    const auth = useContext(AuthContext)
    const app = useContext(AppContext)
    const apiRequest = useApiRequest()


    ////////////////////////////////////////////////////////
    useEffect(() => { getWebs(apiRequest, setWebsList) }, [])


    ///////////////////////////////////////
    return (<div className="overview-page">

        <WindowTitle>Обзор</WindowTitle>

        <div className="page-header">

            <div className="page-title">Обзор</div>

            {
                matchGroups(['Manager Farm']) && websList.length
                    ? <>
                        <CostsAddBtn setFormVisible={ setCostsAddFormVisible } />
                        <CostsAddForm isVisible={ isCostsAddFormVisible } setVisible={ setCostsAddFormVisible } websList={ websList } />
                    </>
                    : ''
            }

        </div>

        <div
            className="overview-grid"
            data-requests={ auth.data?.overview?.isShowRequests }
            data-costs={ auth.data?.overview?.isShowCosts }
            data-news={ auth.data?.overview?.isShowNews }
        // data-index={ matchGroups(['Buyer', 'Team Lead']) }
        >
            { auth.data?.overview?.isShowRequests ? <OverviewRequests /> : '' }
            {/* { matchGroups(['Buyer', 'Team Lead']) && <OverviewIndexes /> } */ }
            { auth.data?.overview?.isShowCosts ? <OverviewCharts /> : '' }
            { auth.data?.overview?.isShowNews ? <OverviewNews /> : '' }
        </div>

    </div>)


}