import { useCallback, useEffect, useState } from 'react'
import { cookie } from '../js/cookie'
import { redirect } from 'react-router-dom'

export const useAuth = (setLoader) => {


    ////////////////////////////////////
    const [data, setData] = useState({})
    const [isAuth, setIsAuth] = useState(false)
    let isRefreshInAction = false


    //////////////////////////////
    const initAuth = async () => {

        const storageData = JSON.parse(localStorage.getItem('authData'))

        if (storageData?.accessToken) {

            setData(storageData)
            setLoader(true)
            await verify(storageData)
            setLoader(false)

        }

    }


    ///////////////////////////////////
    useEffect(() => { initAuth() }, [])


    //////////////////////////////////////////
    const login = useCallback((loginData) => {

        delete loginData.status
        setData(loginData)
        localStorage.setItem('authData', JSON.stringify(loginData))
        setIsAuth(true)

    }, [])


    ///////////////////////////////////////////////////
    const verify = useCallback(async (storageData) => {

        if (isRefreshInAction) return

        if (!storageData) {
            storageData = JSON.parse(localStorage.getItem('authData'))
        }

        if (!storageData) {
            setIsAuth(false)
            return
        }

    }, [data])


    /////////////////////////////////////////////////////////
    const updateAccessToken = useCallback(async response => {

        if (!isAuth) return
        if (!response?.newAccessToken) return
        if (response.type === 'basic') response = await response.json()

        const storageData = JSON.parse(localStorage.getItem('authData'))
        const loginData = { ...storageData, accessToken: response.newAccessToken }
        login(loginData)

    }, [data, isAuth])


    //////////////////////////////////
    const logout = useCallback(() => {

        setData({})
        localStorage.removeItem('authData')
        localStorage.removeItem('theme')
        localStorage.clear()
        setIsAuth(false)
        cookie.delete(`refreshToken${window.location.port}`)
        redirect('/')

    }, [])


    ///////////////////////////////////////////////////////
    const updateAvatarDir = useCallback((newAvatarDir) => {

        const currentData = JSON.parse(localStorage.getItem('authData'))

        if (currentData) {

            const updatedData = { ...currentData, avatarDir: newAvatarDir }
            localStorage.setItem('authData', JSON.stringify(updatedData))
            setData(updatedData)

        }
    }, [])


    ////////
    return {
        login, updateAccessToken, updateAvatarDir,
        data, setData, isAuth, setIsAuth, logout
    }


}