import { useEffect, useState } from 'react'

export const useDefaultValues = (modalFn: any, categoryAlias: string) => {


    /////////////////////////////////////////////////////////////////////////
    const [defaultValues, setDefaultValues] = useState<any>(modalFn.template)


    /////////////////
    useEffect(() => {

        if (modalFn.template?.category.alias !== categoryAlias) return

        const data = modalFn.template.data
        const keysWithIndex: any = {}

        for (let key in data) {
            keysWithIndex[`${key}-0`] = data[key].toString()
        }

        setDefaultValues(keysWithIndex)

        // delete modalFn.template

    }, [modalFn.template])


    //////////////////////////////////////////
    return { defaultValues, setDefaultValues }


}