import { createAsyncThunk } from '@reduxjs/toolkit';
import { newsApi } from '../../api/appApi'

export const getNews = createAsyncThunk(
  'news/getNews',
  async (payload: { sortState: [string, boolean], page: number }, { rejectWithValue }) => {
    try {

      const { data } = await newsApi.getNews(payload)

      return data

    } catch (error: any) {

      return rejectWithValue(error)

    }
  }
)

export const deleteNews = createAsyncThunk(
  'news/deleteNews',
  async (payload: { ids: string[] }, { rejectWithValue }) => {
    try {

      const { data } = await newsApi.deleteNews(payload)

      return data

    } catch (error: any) {

      return rejectWithValue(error)

    }
  }
)