import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { Uploader } from '../Uploader/Uploader'
import { AppContext } from '../../context/AppContext'
import { useApiRequest } from '../../hooks/api.request.hook'
import { matchGroups } from '../../js/lib'
import FormConstructor from '../FormConstructor/FormConstructor'
import Icon from '../Icon/Icon'
import { formatFileSize } from '../../js/file'
import { useModal } from '../Modal2/Modal2'

export const useShowAgencyReport = (
    modalFn: any,
    formRef: any,
    submitForm: Function,
    handleSubmit: SubmitHandler<any>,
    setStartUpload: any
) => {


    ///////////////////////////////////////////
    const { setModal, modalState } = useModal()
    const apiRequest = useApiRequest()
    const app = useContext(AppContext)
    const [resultFiles, setResultFiles] = useState<any>([])
    const [socAccounts, setSocAccounts] = useState<any>([])
    const [adsAccounts, setAdsAccounts] = useState<any>([])
    const [socPlaceholder, setSocPlaceholder] = useState('Сначала выберите агентство')
    const [adsPlaceholder, setAdsPlaceholder] = useState('Сначала выберите соц. аккаунт')
    const [shouldUpdateModal, setShouldUpdateModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})


    /////////////////
    useEffect(() => {

        if (!modalFn.agencyReport) return
        setDefaultValues(modalFn.agencyReport)
        setShouldUpdateModal(true)
        delete modalFn.agencyReport

    }, [modalFn.agencyReport])


    /////////////////////////////////////////////////////////////
    const updateSocAccounts = useCallback(async (event: any) => {

        const agencyId = event.target.value?.toString()
        if (!agencyId) return

        await apiRequest({
            get: `/api/agencies/accounts/soc?agencyId=${agencyId}`,
            200: (response: any) => {

                if (!response.socAccounts.length) {
                    setSocAccounts([])
                    setShouldUpdateModal(true)
                    return
                }

                const newAccounts = response.socAccounts.map((account: any) => {
                    return {
                        label: account.id,
                        value: account.id
                    }
                })

                setSocPlaceholder('Начните вводить соц. аккаунт')
                setSocAccounts(newAccounts)
                setShouldUpdateModal(true)

            }
        })

    }, [setSocAccounts, setShouldUpdateModal])


    /////////////////////////////////////////////////////////////
    const updateAdsAccounts = useCallback(async (event: any) => {

        const socAccountId = event.target.value?.toString()
        if (!socAccountId) return

        await apiRequest({
            get: `/api/agencies/accounts/ads?socAccountId=${socAccountId}`,
            200: (response: any) => {

                if (!response.adsAccounts.length) {
                    setAdsAccounts([])
                    setShouldUpdateModal(true)
                    return
                }

                const newAccounts = response.adsAccounts.map((account: any) => {
                    return {
                        label: account.id,
                        value: account.id
                    }
                })

                setAdsPlaceholder('Начните вводить РК')
                setAdsAccounts(newAccounts)
                setShouldUpdateModal(true)

            }
        })

    }, [setAdsAccounts, setShouldUpdateModal])


    ///////////////////////////////////////
    const formSchema: any = useMemo(() => {

        return [
            [{
                label: 'Агентство',
                placeholder: 'Начните вводить агентство',
                type: 'select',
                name: 'agency',
                autocomplete: true,
                options: app.agencies?.filter((agency: any) =>
                    agency.isHaveMyAgencyAccount || matchGroups(['Admin']))
                    || [],
                validation: { required: true },
                labelParamName: 'name',
                valueParamName: '_id',
                onChange: updateSocAccounts
            }, {
                label: 'Соц. аккаунт',
                placeholder: socPlaceholder,
                type: 'select',
                autocomplete: true,
                name: 'socAccount',
                options: socAccounts,
                validation: { required: true },
                onChange: updateAdsAccounts
            }], [{
                label: 'Рекламный кабинет',
                type: 'select',
                name: 'adsAccount',
                autocomplete: true,
                options: adsAccounts,
                placeholder: adsPlaceholder,
                multiple: true
            }, {
                label: 'Причина репорта',
                type: 'select',
                name: 'reason',
                placeholder: 'Выберите причину репорта',
                options: [
                    { label: 'Бан РК', value: 'banAdsAcc' },
                    { label: 'Лимит 25', value: 'limit25' },
                    { label: 'Рикс Пеймент', value: 'rixPayment' },
                    { label: 'Полис', value: 'polis' },
                    { label: 'ЗРД', value: 'zrd' },
                    { label: 'Disable', value: 'disable' },
                    { label: 'Неоплата', value: 'unpaid' },
                    { label: 'Пропал из соца', value: 'goneFromSoc' },
                    { label: 'ФП/пиксель', value: 'fpPixel' },
                    { label: 'Свой вариант (в комментарии)', value: 'custom' }
                ],
                validation: { required: true }
            }], {
                label: 'Ваш комментарий',
                placeholder: 'Напишите Ваш комментарий',
                type: 'textarea',
                name: 'comment'
            }
        ]
    }, [app.agencies, updateSocAccounts, socAccounts, socPlaceholder, adsAccounts, adsPlaceholder, updateAdsAccounts])


    ////////////////////////////////////
    const modalContent = useMemo(() => {

        return <>

            <FormConstructor
                ref={ formRef }
                formSchema={ formSchema }
                defaultValues={ defaultValues }
                onSubmit={ data => handleSubmit({
                    ...data,
                    formSections: 1,
                    social: 'agency',
                    category: 'agencyReport'
                }) }
            />

            <div className="ticket-modal-heading">Прикрепить файл</div>

            <div className="ticket-modal-files">
                { resultFiles?.map((result: any) =>
                    <div className="ticket-details-file" key={ result?.name }>
                        <span>
                            <div className="ticket-details-file-name">{ result?.name }</div>
                            <div className="ticket-details-file-size">{ formatFileSize(result?.size) }</div>
                        </span>
                        <Icon onClick={ () => {
                            setResultFiles((prev: any) => prev.filter((item: any) => item?.name !== result?.name))
                            setShouldUpdateModal(true)
                        } } name="close" />
                    </div>
                ) }
            </div>

            <Uploader
                url="/api/files"
                setStartUpload={ setStartUpload }
                resultFiles={ resultFiles }
                onChange={ files => {
                    setResultFiles(files)
                    setShouldUpdateModal(true)
                } }
                multiple
                name="file-0"
            />

        </>

    }, [formSchema, handleSubmit, defaultValues, setResultFiles, setShouldUpdateModal, resultFiles, setStartUpload])


    //////////////////////////////////////////////
    const showModal = useCallback(() => setModal({
        title: 'Новый тикет',
        className: 'ticket-modal ticket-modal-agency-report',
        content: modalContent,
        floating: true,
        isVisible: true,
        buttons: [{
            icon: 'chevron-left2',
            label: 'Назад',
            className: 'button-plain-xl',
            action: modalFn.showConsumablesAgency
        }, {
            label: 'Создать тикет',
            action: submitForm,
        }],
        breadcrumbs: [
            ['Расходники', modalFn.showConsumables],
            ['Agency', modalFn.showConsumablesAgency],
            ['Репорт']
        ]
    }), [modalContent])


    /////////////////
    useEffect(() => {
        shouldUpdateModal && showModal()
        setShouldUpdateModal(false)
    }, [modalContent, shouldUpdateModal, showModal])


    /////////////////
    useEffect(() => {

        if (
            modalState.className === 'ticket-modal ticket-modal-agency-report'
            && modalState.isVisible === false
        ) {
            setDefaultValues({})
        }

    }, [modalState.isVisible])


    /////////////////
    useEffect(() => {

        if (Object.keys(defaultValues).length > 0) {
            showModal()
            setDefaultValues({})
        }

    }, [modalContent, defaultValues, showModal, setDefaultValues])


    ////////////////
    return showModal


}