import React, { useCallback, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Icon } from '../../../components/Icon/Icon'
import { Pagination } from '../../../components/Pagination/Pagination'
import { AuthContext } from '../../../context/AuthContext'
import { formatDate } from '../../../js/date'
import { hashFields } from '../../../js/lib'
import RequestStatus from '../RequestStatus'
import './RequestsPageList.light.scss'

export const RequestsPageList = ({
    list,
    listCount,
    updateList,
    activeRequestId,
    isArchive,
    showCostAddForm,
    isListVisible,
    setListVisible,
    limit,
    setLimit
}) => {


    //////////////////////////////////
    const auth = useContext(AuthContext)
    const navigate = useNavigate()
    let { urlRequestId } = useParams()


    ///////////////////////////////////////
    const goToRequest = useCallback(id => {

        const section = isArchive ? 'archive' : 'requests'
        navigate(`/${section}/${id}${hashFields()}`)
        setListVisible && setListVisible(false)

    }, [isArchive, navigate, setListVisible])


    ///////////////////////////////////////////////////////////////
    let paginationBasePath = isArchive ? '/archive/' : '/requests/'


    ///////////////////////////////////////////////////
    const renderGiverWebName = useCallback(request => {

        if (!request.giver?._id) {
            return <div className="requests-page-item-deleted">Удаленный<br />пользователь</div>
        } else if (request.giver.webName) {
            return request.giver.webName
        } else {
            return [request.giver.firstName, request.giver.lastName].join(' ')
        }

    }, [])


    ////////////////////////
    return <div className={
        `requests-page-list 
        ${isListVisible || !urlRequestId ? 'requests-list-visible' : ''} 
        ${isArchive ? 'requests-page-archive' : 'requests-page-active'}`
    }>

        <table>

            <tbody>

                { list.map((item, index) => {

                    const activeClass = (activeRequestId === item._id) ? 'requests-page-item-active' : ''
                    let title = ''
                    const isGiver = item.giver?._id === auth.data.userId
                    const isUnreadMessages = item.isUnreadMessages ? item.isUnreadMessages[isGiver ? 'forGiver' : 'forTaker'] : false
                    const isUnreadFiles = item.isUnreadFiles ? item.isUnreadFiles[isGiver ? 'forGiver' : 'forTaker'] : false

                    const date = formatDate(item.createdAt, {
                        time: true,
                        year: false
                    })

                    const dateUpdated = formatDate(item.updatedAt, {
                        time: true,
                        year: false
                    })

                    if (item.category?.alias === 'task') {
                        title = item.data.title
                    } else if (item.data?.type === 'prolong') {
                        title = `Продление прокси №${item.proxyTaskId}`
                    } else {
                        title = `${item.category?.name} №${item.id || item.proxyTaskId || ''}`
                    }

                    return (<tr
                        key={ index }
                        className={ activeClass }
                        onClick={ () => goToRequest(item._id) }
                    >

                        <td>
                            <div className="requests-page-item-left">
                                <div className="requests-page-item-date">
                                    { date }
                                    { !isArchive && <div className="requests-page-item-date-updated">
                                        <Icon name="updated" />
                                        { dateUpdated }
                                    </div> }
                                </div>
                                <div className="requests-page-item-title">{ title }</div>
                            </div>
                        </td>

                        <td>
                            <div className="requests-page-item-team">
                                <div className="requests-page-item-team">{ item.giver?.team?.name || '' }</div>
                                <div className="requests-page-item-web">{ renderGiverWebName(item) }</div>
                            </div>
                        </td>

                        <td className="requests-page-item-taker">
                            <div className="requests-page-item-team">
                                <div className="requests-page-item-team">Исполнитель</div>
                                <div className="requests-page-item-web">{ item.taker?.webName || '-' }</div>
                            </div>
                        </td>

                        <td>

                            <div className="requests-page-item-counters">

                                <div className={ `requests-page-item-counter ${isUnreadMessages ? 'requests-page-item-counter-unread' : ''}` }>
                                    { <span>{ item.messagesCount }</span> }
                                    <Icon name="envelope" />
                                </div>

                                <div className={ `requests-page-item-counter ${isUnreadFiles ? 'requests-page-item-counter-unread' : ''}` }>
                                    { <span>{ item.filesCount }</span> }
                                    <Icon name="paperclip" />
                                </div>

                            </div>

                        </td>

                        <td><RequestStatus
                            item={ item }
                            updateList={ updateList }
                            showCostAddForm={ showCostAddForm }
                        /></td>

                    </tr>)

                }) }

                { setLimit &&
                    <tr>
                        <td colSpan="10">
                            <Pagination
                                count={ listCount }
                                basePath={ paginationBasePath }
                                limit={ limit }
                                setLimit={ setLimit }
                            />
                        </td>
                    </tr>
                }

            </tbody>

        </table>

        <div className="requests-page-list-mobile">

            { list.map((item, index) => {

                const activeClass = (activeRequestId === item._id) ? 'requests-page-item-active' : ''
                let title = ''
                const isGiver = item.giver?._id === auth.data.userId
                const isUnreadMessages = item.isUnreadMessages ? item.isUnreadMessages[isGiver ? 'forGiver' : 'forTaker'] : false
                const isUnreadFiles = item.isUnreadFiles ? item.isUnreadFiles[isGiver ? 'forGiver' : 'forTaker'] : false

                const date = formatDate(item.createdAt, {
                    time: true,
                    year: false
                })

                const dateUpdated = formatDate(item.updatedAt, {
                    time: true,
                    year: false
                })

                if (item.category?.alias === 'task') {
                    title = item.data.title
                } else if (item.data?.type === 'prolong') {
                    title = `Продление прокси №${item.proxyTaskId}`
                } else {
                    title = `${item.category?.name} №${item.id || item.proxyTaskId || ''}`
                }

                return (<div
                    key={ index }
                    className={ `${activeClass} request-page-list-item` }
                    onClick={ () => goToRequest(item._id) }
                >

                    <div>
                        <div className="requests-page-item-left">
                            <div className="requests-page-item-date">{ date }</div>
                            <div className="requests-page-item-title">{ title }</div>
                            { !isArchive && <div className="requests-page-item-date-updated">
                                <Icon name="updated" />
                                { dateUpdated }
                            </div> }
                        </div>
                    </div>

                    <span>

                        <div>
                            <div className="requests-page-item-team">
                                <div className="requests-page-item-team">{ item.giver?.team?.name || '' }</div>
                                <div className="requests-page-item-web">{ renderGiverWebName(item) }</div>
                            </div>
                        </div>

                        <div className="requests-page-item-counters">

                            <div className="requests-page-item-counter">
                                { <span>{ item.messagesCount }</span> }
                                <Icon name="envelope" />
                            </div>

                            <div className="requests-page-item-counter">
                                { <span>{ item.filesCount }</span> }
                                <Icon name="paperclip" />
                            </div>

                        </div>

                    </span>

                    <span>

                        <div className="requests-page-item-taker">
                            <div className="requests-page-item-team">
                                <div className="requests-page-item-team">Исполнитель</div>
                                <div className="requests-page-item-web">{ item.taker?.webName || '-' }</div>
                            </div>
                        </div>

                        <div className="requests-page-item-status-wrapper">
                            <RequestStatus
                                item={ item }
                                updateList={ updateList }
                                showCostAddForm={ showCostAddForm }
                            />
                        </div>

                    </span>

                </div>)

            }) }

            { setLimit && <Pagination
                count={ listCount }
                basePath={ paginationBasePath }
                limit={ limit }
                setLimit={ setLimit }
            /> }

        </div>

    </div>

}