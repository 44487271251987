import { pasteHere } from '../../js/lib'
import { Icon } from '../Icon/Icon'
import { Modal } from '../Modal/Modal'
import { Uploader } from '../Uploader/Uploader'
import { handleInputChange } from './handleInputChange'
import { Checkbox } from '../Checkbox/Checkbox'
import { Select } from '../Select/Select'
import { Range } from '../Range/Range'
import { Quantity } from '../Quantity/Quantity'
import { Radio } from '../Radio/Radio'
import { Editor } from '../Editor/Editor'
import { Input } from '../Input/Input'
import { InputPassword } from '../InputPassword/InputPassword'
import { Textarea } from '../Textarea/Textarea'
import { TagInput } from '../TagInput/TagInput'

///////////////////////////////
export const renderFormItem = (
    item,
    itemIndex,
    formClassName,
    autoFocusField,
    handleKeyup,
    formState,
    setFormState,
    textInput,
    app,
    isConfirmDelImgVisible,
    setConfirmDelImgVisible
) => {

    let attributes = {
        value: item.value || '',
        'data-is-invalid': item.isInvalid
    }

    if (item.required) attributes['data-required'] = item.required
    if (item.autofocus && !autoFocusField.current) attributes.ref = autoFocusField
    if (item.placeholder) attributes.placeholder = item.placeholder
    if (item.readonly) attributes.readOnly = true
    if (item.copyable) attributes['data-copyable'] = true
    if (item.disabled) attributes.disabled = item.disabled
    if (item.maxLength) attributes.maxLength = item.maxLength
    if (item.ref) attributes.ref = item.ref
    if ('store' in item) attributes.store = item.store

    let pastableClass = ''
    let isPastable = false

    if (navigator.clipboard?.readText && item.pastable) {
        pastableClass = 'form-item-container-pastable'
        isPastable = true
    }

    if (item.type === 'text') {

        delete attributes.ref

        return <div key={ itemIndex } className="form-item">

            {
                item.title
                    ? <div className="form-title">
                        { item.title }
                        { item.unnecessary ? <span className="form-unnecessary"> (необязательно)</span> : '' }
                    </div>
                    : ''
            }

            <div className={ `form-item-container ${pastableClass}` }>

                <Input
                    type={ item.type }
                    name={ item.name }
                    formClassName={ formClassName }
                    onKeyUp={ handleKeyup }
                    onChange={ (event) => {
                        handleInputChange(event, formState, setFormState)
                        item.onChange && item.onChange(event)
                        delete item.isInvalid
                        toggleValidationError(item.sectionIndex, formState, setFormState)
                    } }
                    // ref={ textInput }
                    placeholder={ item.placeholder }
                    autoComplete="off"
                    { ...attributes }
                />

                { isPastable ? <div
                    className="form-paste-btn"
                    onClick={ (event) => pasteHere(event.currentTarget.previousElementSibling) }
                    title="Вставить"
                ><Icon name="paste" /></div> : '' }

            </div>

        </div>

    } else if (item.type === 'password') {

        delete attributes.ref

        return <div key={ itemIndex } className="form-item">

            {
                item.title
                    ? <div className="form-title">
                        { item.title }
                        { item.unnecessary ? <span className="form-unnecessary"> (необязательно)</span> : '' }
                    </div>
                    : ''
            }

            <div className={ `form-item-container ${pastableClass}` }>

                <InputPassword
                    type={ item.type }
                    name={ item.name }
                    formClassName={ formClassName }
                    onKeyUp={ handleKeyup }
                    onChange={ (event) => {
                        handleInputChange(event, formState, setFormState)
                        item.onChange && item.onChange(event)
                        delete item.isInvalid
                        toggleValidationError(item.sectionIndex, formState, setFormState)
                    } }
                    // ref={ textInput }
                    placeholder={ item.placeholder }
                    autoComplete="off"
                    { ...attributes }
                />

                { isPastable ? <div
                    className="form-paste-btn"
                    onClick={ (event) => pasteHere(event.currentTarget.previousElementSibling) }
                    title="Вставить"
                ><Icon name="paste" /></div> : '' }

            </div>

        </div>

    } else if (item.type === 'textarea') {

        delete attributes.ref

        return (
            <div key={ itemIndex } className="form-item">

                {
                    item.title
                        ? <div className="form-title">
                            { item.title }
                            { item.unnecessary ? <span className="form-unnecessary"> (необязательно)</span> : '' }
                        </div>
                        : ''
                }

                <Textarea
                    name={ item.name }
                    formClassName={ formClassName }
                    onChange={ (event) => {
                        handleInputChange(event, formState, setFormState)
                        item.onChange && item.onChange(event)
                        delete item.isInvalid
                        toggleValidationError(item.sectionIndex, formState, setFormState)
                    } }
                    { ...attributes }
                />

            </div>
        )

    } else if (item.type === 'tags') {

        delete attributes.ref

        return (
            <div key={ itemIndex } className="form-item">

                {
                    item.title
                        ? <div className="form-title">
                            { item.title }
                            { item.unnecessary ? <span className="form-unnecessary"> (необязательно)</span> : '' }
                        </div>
                        : ''
                }

                <TagInput
                    initialTags={ item?.value || [] }
                    onChange={ (tags, name) => {

                        if (!tags.length) {
                            return
                        }

                        setFormState(prevState => {
                            return prevState.map(item => {

                                if (item?.name === name) {
                                    return { ...item, value: tags }
                                }

                                return item
                            })
                        })
                    } }
                    name={ item.name }
                />

            </div>
        )

    } else if (item.type === 'uploader') {

        return (<div key={ itemIndex }>

            {
                item.title
                    ? <div className="form-title">
                        { item.title }
                        { item.unnecessary ? <span className="form-unnecessary"> (необязательно)</span> : '' }
                    </div>
                    : ''
            }

            {
                item.current

                    ? <div className="form-uploader-edit">

                        <div
                            className="form-uploader-img"
                            style={ { backgroundImage: `url(${item.current})` } }
                            onClick={ () => app.setViewer(item.current.replace('/s/', '/l/')) }
                        ></div>

                        <button
                            className="button button-6"
                            title="Удалить изображение"
                            onClick={ () => setConfirmDelImgVisible(true) }
                        ><Icon name="trash" /></button>

                        <Modal
                            title="Вы уверены?"
                            className="form-uploader-del-modal"
                            isVisible={ isConfirmDelImgVisible }
                            setVisible={ setConfirmDelImgVisible }
                            content="Удалить изображение?"
                            buttons={ [
                                {
                                    title: 'Удалить',
                                    action: () => {
                                        item.deleteAction && item.deleteAction()
                                        setConfirmDelImgVisible(false)
                                    }
                                }, {
                                    title: 'Отменить',
                                    className: 'button button-2',
                                    action: () => setConfirmDelImgVisible(false)
                                }
                            ] }
                        />

                    </div>

                    : <Uploader
                        url={ item.url }
                        label={ item.label }
                        icon={ item.icon }
                        postData={ item.postData }
                        setStartUpload={ item.setStartUpload }
                        onChange={ (event) => {
                            handleInputChange(event, formState, setFormState)
                            item.onChange && item.onChange(event)
                            delete item.isInvalid
                            toggleValidationError(item.sectionIndex, formState, setFormState)
                        } }
                        multiple={ item.multiple }
                        name={ item.name }
                        accept={ item.accept }
                        selectedFilesDefault={ item.selectedFilesDefault }
                        filesLimit={ item.filesLimit }
                        isInvalid={ item.isInvalid }
                        clearButton={ item.clearButton }
                    />

            }

        </div>)

    } else if (item.type === 'hidden') {

        return (
            <input
                type="hidden"
                name={ item.name }
                value={ item.value }
                key={ itemIndex }
            />
        )

    } else if (item.type === 'range') {

        const handleRangeChange = (event) => {
            const { name, id, value } = event.target

            setFormState(prevState =>
                prevState.map(item =>
                    item.name === name
                        ? {
                            ...item,
                            value: id === 'min'
                                ? [+value, item.value[1]]
                                : [item.value[0], +value]
                        }
                        : item
                )
            )
        }

        return <Range
            minValue={ item.value[0] }
            maxValue={ item.value[1] }
            name={ item.name }
            onMinChange={ handleRangeChange }
            onMaxChange={ handleRangeChange }
            key={ itemIndex }
        />

    } else if (item.type === 'checkbox' && item.label) {

        // single checkbox

        return <Checkbox
            key={ itemIndex }
            name={ item.name }
            label={ item.label }
            value={ item.value }
            onChange={ event => handleInputChange(event, formState, setFormState) }
            checked={ item.checked }
            useExclude={ item.useExclude }
            excluded={ item.excluded }
        />

    } else if (item.type === 'checkbox-list' && item.options) {

        const updatedOptions = item?.options?.map(option => {
            return {
                checked: option.checked || false,
                value: option?.value || option[item.valueParamName],
                label: option?.label || option[item.labelParamName],
                ...option
            }
        })

        return <div className="form-checkbox-list-options" key={ itemIndex }>

            { updatedOptions?.map((option, optionIndex) => {
                return <Checkbox
                    key={ optionIndex }
                    name={ item.name }
                    value={ option.value }
                    onChange={ event => {

                        setFormState(prevState => {
                            // Map over the previous state items
                            return prevState.map(item => {
                                // Check if the current item's name matches the event target name
                                if (item?.name === event.target.name) {
                                    // Map over the options of the current item
                                    const updatedOptions = item.options?.map(option => {
                                        // Check if the current option's _id matches the event target value
                                        if (option[item.valueParamName] === event.target.value) {
                                            // Return the updated option with the checked property updated
                                            return { ...option, checked: event.target.checked, excluded: event.target.excluded }
                                        }
                                        // Return the option as is if it doesn't match the event target value
                                        return option
                                    });

                                    // Return the updated item with the updated options
                                    return { ...item, options: updatedOptions }
                                }

                                // Return the item as is if it doesn't match the event target name
                                return item
                            })
                        })
                        delete item.isInvalid

                    } }
                    checked={ option.checked }
                    disabled={ option.disabled }
                    label={ option.label }
                    withExclude={ true }
                    excluded={ option.excluded }
                />
            }) }

        </div>

    } else if (item.type === 'checkbox' && item.options) {

        // button-like checkbox list

        return <div className="form-checkbox-buttons" key={ itemIndex }>

            {
                item.title
                    ? <div className="form-title">
                        { item.title }
                        { item.unnecessary ? <span className="form-unnecessary"> (необязательно)</span> : '' }
                    </div>
                    : ''
            }

            <div key={ itemIndex } className="form-checkbox-buttons-list">

                { item.options.map((option, optionIndex) => {

                    return <Checkbox
                        key={ optionIndex }
                        name={ item.name }
                        value={ option.value }
                        onChange={ event => {

                            handleInputChange(event, formState, setFormState)
                            delete item.isInvalid
                            toggleValidationError(item.sectionIndex, formState, setFormState)

                        } }
                        checked={ option.checked }
                        label={ option.label }
                        view="button"
                        isInvalid={ item.isInvalid }
                        useExclude={ option.useExclude }
                        excluded={ option.excluded }
                    />

                }) }

            </div>

        </div>

    } else if (item.type === 'custom') {

        return (
            <div key={ itemIndex } className="form-item">
                { item.title ? <div className="form-title">{ item.title }</div> : '' }
                { item.content }
            </div>
        )

    } else if (item.type === 'select') {

        return (
            <div key={ itemIndex } className="form-item" data-name={ item.name }>

                {
                    item.title
                        ? <div className="form-title">
                            { item.title }
                            { item.unnecessary ? <span className="form-unnecessary"> (необязательно)</span> : '' }
                        </div>
                        : ''
                }

                <Select
                    source={ item.source }
                    name={ item.name }
                    placeholder={ item.placeholder }
                    labelParamName={ item.labelParamName }
                    valueParamName={ item.valueParamName }
                    onChange={ (event, selected) => {
                        handleInputChange(event, formState, setFormState)
                        item.onChange && item.onChange(event, selected)
                        delete item.isInvalid
                        toggleValidationError(item.sectionIndex, formState, setFormState)
                    } }
                    selectedIndex={ item.selectedIndex }
                    disabled={ item.disabled }
                    footerButton={ item.listButton }
                    multiple={ item.multiple }
                    inline={ item.inline }
                    inlineClickHandler={ item.inlineClickHandler }
                    debug={ item.debug }
                    isInvalid={ item.isInvalid }
                />

            </div>
        )

    } else if (item.type === 'autocomplete') {

        return (
            <div key={ itemIndex } className="form-item">

                {
                    item.title
                        ? <div className="form-title">
                            { item.title }
                            { item.unnecessary ? <span className="form-unnecessary"> (необязательно)</span> : '' }
                        </div>
                        : ''
                }

                <Select
                    source={ item.source }
                    name={ item.name }
                    placeholder={ item.placeholder }
                    labelParamName={ item.labelParamName }
                    valueParamName={ item.valueParamName }
                    onChange={ (event, selected) => {
                        handleInputChange(event, formState, setFormState)
                        item.onChange && item.onChange(event, selected)
                        delete item.isInvalid
                        toggleValidationError(item.sectionIndex, formState, setFormState)
                    } }
                    autocomplete={ true }
                    multiple={ item.multiple }
                    selectedIndex={ item.selectedIndex }
                    disabled={ item.disabled }
                    footerButton={ item.listButton }
                    isInvalid={ item.isInvalid }
                    selectOrEnter={ item.required === 'selectOrEnter' }
                    onItemClick={ item.onItemClick }
                />

            </div>
        )

    } else if (item.type === 'quantity') {

        return (
            <div key={ itemIndex }>

                {
                    item.title
                        ? <div className="form-title">
                            { item.title }
                            { item.unnecessary ? <span className="form-unnecessary"> (необязательно)</span> : '' }
                        </div>
                        : ''
                }

                <Quantity
                    name={ item.name }
                    value={ item.value }
                    min={ item.min }
                    max={ item.max }
                    onChange={ (event) => {
                        handleInputChange(event, formState, setFormState)
                        delete item.isInvalid
                        toggleValidationError(item.sectionIndex, formState, setFormState)
                    } }
                    isInvalid={ item.isInvalid }
                />

            </div>
        )

    } else if (item.type === 'radio' || item.type === 'radio-button') {

        return <div key={ itemIndex } className="form-radio">

            {
                item.title
                    ? <div className="form-title">
                        { item.title }
                        { item.unnecessary ? <span className="form-unnecessary"> (необязательно)</span> : '' }
                    </div>
                    : ''
            }

            <div className="form-radio-list">
                {
                    item.options?.map((option, optionIndex) => {

                        const attributes = {
                            name: item.name,
                            label: option.label,
                            value: option.value,
                            onChange: (event) => {
                                handleInputChange(event, formState, setFormState)
                                option.onChange && option.onChange(event)
                                item.onChange && item.onChange(event)
                                delete item.isInvalid
                                toggleValidationError(item.sectionIndex, formState, setFormState)
                            },
                            required: optionIndex === 0 ? item.required : '',
                            checked: option.checked,
                            isInvalid: item.isInvalid,
                            disabled: option.disabled
                        }

                        return <Radio key={ optionIndex } type={ item.type } { ...attributes } />

                    })
                }
            </div>

        </div>

    } else if (item.type === 'validationError') {

        return <div key={ itemIndex } className="form-validation-error">
            <Icon name="warning" />
            Заполните все необходимые поля
        </div>

    } else if (item.type === 'formSectionToggle') {

        const lastShownProxySection = item
            .proxySectionsAndProtocols
            .findLastIndex(section =>
                section.some(protocol => !protocol)
            )

        return <div key={ itemIndex } className="form-section-toggle" data-is-invalid={ item.isInvalid }>

            <div className="form-section-toggle-title">{ item.title } №{ item.sectionIndex + 1 }</div>

            {
                (
                    (
                        item.formSectionsRef
                        && item.sectionIndex + 1 === item.formSectionsRef.current[item.alias]
                        && item.sectionIndex
                    ) || (
                        item.proxyShowHideButton
                        && lastShownProxySection === item.sectionIndex
                    )
                )
                    ? <Icon
                        name="trash"
                        className="form-section-del"
                        title="Удалить запрос"
                        onClick={ () => {
                            item.hideAction && item.hideAction(item.alias)
                            item.proxyDelSection && item.proxyDelSection(item.sectionIndex)
                        } }
                    />
                    : ''
            }

            <div className="form-section-toggle-line"></div>

            <Icon
                name="chevron-up-circle"
                className="form-section-toggle-icon"
                onClick={ () => toggleFormSection(item.alias, item.sectionIndex) }
            />

        </div>

    } else if (item.type === 'editor') {

        return (<div key={ itemIndex } className="form-editor">

            {
                item.title
                    ? <div className="form-title">
                        { item.title }
                        { item.unnecessary ? <span className="form-unnecessary"> (необязательно)</span> : '' }
                    </div>
                    : ''
            }

            <Editor
                name={ item.name }
                value={ item.value }
                onChange={ (event) => {
                    handleInputChange(event, formState, setFormState)
                } }
                placeholder={ item.placeholder }
            />

        </div>)

    }

}


//////////////////////////////////////////////////////////////////////////
const toggleValidationError = (sectionIndex, formState, setFormState) => {

    const isValidationErrorExists = formState.some(firstLevelItem => {

        const itemsArray = Array.isArray(firstLevelItem) ? firstLevelItem : [firstLevelItem]
        return itemsArray.find(secondLevelItem => secondLevelItem.isInvalid && secondLevelItem.type !== 'formSectionToggle')

    })

    const isSectionValidationErrorExists = formState.some(firstLevelItem => {

        const itemsArray = Array.isArray(firstLevelItem) ? firstLevelItem : [firstLevelItem]
        const itemsArrayCurSection = itemsArray.filter(item => item.sectionIndex === sectionIndex)
        return itemsArrayCurSection.find(secondLevelItem => secondLevelItem.isInvalid)

    })

    setFormState(formState.map(item => {

        if (item.type === 'validationError') item.hidden = !isValidationErrorExists

        if (
            item.type === 'formSectionToggle'
            && item.sectionIndex === sectionIndex
        ) {
            if (isSectionValidationErrorExists) {
                item.isInvalid = !isSectionValidationErrorExists
            }
        }

        return item

    }))

}


///////////////////////////////////////////////////////////
const toggleFormSection = (alias, clickedSectionIndex) => {

    const clickedSectionRows = document.querySelectorAll(`div.modal-visible div.form-row[data-section="${clickedSectionIndex}"]`)

    clickedSectionRows.forEach(row => {

        if (row.classList.contains('form-row-collapsed')) {
            row.classList.remove('form-row-absolute')
            row.classList.remove('form-row-collapsed')
        } else {
            row.classList.add('form-row-collapsed')
            setTimeout(() => { row.classList.add('form-row-absolute') }, 200)
        }

    })

    const otherSectionsRows = document.querySelectorAll(`div.modal-visible div.form-row[data-section]:not([data-section="${clickedSectionIndex}"])`)

    otherSectionsRows.forEach(row => {
        row.classList.add('form-row-collapsed')
        setTimeout(() => { row.classList.add('form-row-absolute') }, 200)
    })

}