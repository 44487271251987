import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Icon } from '../../components/Icon/Icon'
import { AuthContext } from '../../context/AuthContext'
import { downloadFile, getDirName, getFileBaseName, isImage, isVideo } from '../../js/file'
import { If } from '../If'
import { Modal } from '../Modal/Modal'
import './Viewer.scss'

/**
 * Viewer component displays an image or video in a modal window.
 * @param {Object} props - The component props.
 * @param {Object} props.state - The state object containing information about the file to be displayed.
 * @param {Function} props.setState - The function to update the state.
 * @returns {JSX.Element} - The Viewer component.
 */

export const Viewer = ({ state, setState }) => {


    ////////////////////////////////////////////
    const [maxHeight, setMaxHeight] = useState()
    const [maxWidth, setMaxWidth] = useState()
    const auth = useContext(AuthContext)
    const filePath = state?.filePath || state


    ///////////////////////////////////////////////
    const setMaxImgDimensions = useCallback(() => {

        const windowWidth = window.innerWidth
        const windowHeight = window.innerHeight

        setMaxWidth(windowWidth * 0.39)
        setMaxHeight(windowHeight * 0.8)

    }, [])


    ////////////////////////////////////
    const download = useCallback(() => {

        downloadFile(
            state.dir,
            state.id,
            state.storedName,
            state.origName,
            auth.data.accessToken,
            state.size
        )

    }, [state, auth.data.accessToken])


    /////////////////
    useEffect(() => {

        window.addEventListener('resize', setMaxImgDimensions)
        setMaxImgDimensions()

        return () => window.removeEventListener('resize', setMaxImgDimensions)

    }, [])


    ////////////////////////////////////
    const modalContent = useMemo(() => {

        const dirName = getDirName(filePath)
        const fileBaseName = getFileBaseName(filePath)
        const filePathEncoded = dirName + '/' + encodeURIComponent(fileBaseName)

        return <div className="viewer-content">

            {/* IMAGE */ }

            <If condition={ isImage(filePath) }>
                <img
                    src={ filePathEncoded || '' }
                    className="viewer-img"
                    style={ {
                        maxWidth: maxWidth + 'px',
                        maxHeight: maxHeight + 'px'
                    } }
                    alt=""
                />
            </If>

            {/* VIDEO */ }

            <If condition={ isVideo(filePath) }>
                <video
                    src={ filePathEncoded || '' }
                    className="viewer-video"
                    style={ {
                        maxWidth: maxWidth + 'px',
                        maxHeight: maxHeight + 'px'
                    } }
                    controls
                ></video>
            </If>

            {/* DOWNLOAD BUTTON */ }

            <If condition={ state.downloadButton && state?.dir }>
                <button
                    onClick={ download }
                    className="viewer-download-button button button-6"
                    title="Скачать"
                >
                    <Icon name="import-small" />
                </button>
            </If>

        </div>

    }, [state, maxWidth, maxHeight, download, filePath])


    /////////////
    return <Modal
        isVisible={ state !== false }
        content={ modalContent }
        setVisible={ () => setState(false) }
        className="viewer"
    />


}