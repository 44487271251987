import { Helmet } from 'react-helmet'

/**
 * Renders the window title component.
 * 
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The content to be displayed in the window title.
 * @returns {JSX.Element} The rendered window title component.
 */

export const WindowTitle = ({ children }) => {

    return <Helmet>
        <title>{
            children + ' ' + process.env.REACT_APP_WINDOW_TITLE
        }</title>
    </Helmet>

}