import React, { memo, useContext } from 'react'
import { AppContext } from '../../context/AppContext'
import { AuthContext } from '../../context/AuthContext'
import { formatDate } from '../../js/date'
import { matchGroups } from '../../js/lib'
import RequestControls from './RequestControls'


//////////////////////////////
interface RequestStatusProps {
    item: any
    updateList: () => void
    showCostAddForm: (request: any) => void
}


//////////////////////////////////////////////////////
const RequestStatus: React.FC<RequestStatusProps> = ({
    item, updateList, showCostAddForm
}) => {


    ////////////////////////////////////
    const auth = useContext(AuthContext)
    const app = useContext(AppContext)


    ////
    if (
        item.status === 'archived' || item.status === 'closed'
        || (
            matchGroups(['Buyer', 'Support Farm', 'Support Design'])
            && (
                item.giver?._id === auth.data.userId
                || item.taker?._id === auth.data.userId
            )
            && item.status === 'closed'
        )
        || (
            matchGroups(['Manager Farm'])
            && item.status === 'closed'
            && item.giver?._id === auth.data.userId
        )
    ) {
        return (
            <div className="requests-page-item-closedate">
                { formatDate(item.closeDate, {
                    time: true
                })
                }
            </div>
        )
    }


    ////
    if (
        matchGroups(['Buyer', 'Admin', 'Team Lead'])
        || item.giver?._id === auth.data.userId
        || (matchGroups(['Manager Farm']) && item.giver?.group.name === 'Manager Farm')
    ) {
        return (
            <div className="requests-page-item-status" data-status={ item.status }>{ app.requestStatuses[item.status] }</div>
        )
    }


    ////////////////////////
    return <RequestControls
        item={ item }
        updateList={ updateList }
        showCostAddForm={ showCostAddForm }
    />


}


///////////////////////////////////
export default memo(RequestStatus)