import React, { useCallback, useEffect, useMemo } from 'react'
import FormConstructor from '../FormConstructor/FormConstructor'
import { useModal } from '../Modal2/Modal2'
import { SubmitHandler } from 'react-hook-form'
import { useDefaultValues } from './default.values.hook'

export const useShowFacebookFanpage = (
    modalFn: any,
    formRef: any,
    submitForm: Function,
    handleSubmit: SubmitHandler<any>,
) => {


    ///////////////////////////////
    const { setModal } = useModal()
    const { defaultValues, setDefaultValues } = useDefaultValues(modalFn, 'fanpage')


    ///////////////////////////
    const formSchema = useMemo(
        () => [
            [
                {
                    label: 'ПЗРД',
                    type: 'radio-button',
                    name: 'pzrd',
                    options: [
                        { label: 'С ПЗРД', value: 'with', checked: true },
                        { label: 'Без ПЗРД', value: 'without' }
                    ],
                    validation: { required: true },
                },
                {
                    label: 'Количество подписчиков',
                    type: 'select',
                    name: `subscribersQty`,
                    options: [
                        { label: 'Неважно', value: 'any' },
                        { label: '1000', value: '1000' },
                        { label: '3000', value: '3000' },
                        { label: '5000', value: '5000' },
                    ],
                }
            ],
            [
                {
                    label: 'Ссылка на покупку',
                    type: 'text',
                    name: 'buyLink',
                    placeholder: 'Оставьте ссылку на покупку',
                    validation: { required: false },
                },
                {
                    label: 'Необходимое количество',
                    type: 'quantity',
                    name: `qty`,
                    value: 1,
                    min: 1,
                    max: 1000,
                    validation: { required: true },
                }
            ],
            {
                label: 'Ваш комментарий',
                type: 'textarea',
                name: 'comment',
                placeholder: 'Напишите свой комментарий',
                validation: { required: false },
            },
            {
                label: 'Тег',
                type: 'tags',
                name: 'tags',
                validation: { required: false },
            }
        ],
        []
    )


    ////////////////////////////////////
    const modalContent = useMemo(() => {
        return (
            <FormConstructor
                ref={ formRef }
                formSchema={ formSchema }
                defaultValues={ defaultValues }
                sectionTitle="Fan Page"
                sectionCount={ 5 }
                onSubmit={ (data) =>
                    handleSubmit({
                        ...data,
                        social: 'facebook',
                        category: 'fanpage',
                    })
                }
            />
        )
    }, [formSchema, handleSubmit, defaultValues])


    //////////////////////////////
    const showModal = useCallback(
        () =>
            setModal({
                title: 'Новый тикет',
                className: 'ticket-modal ticket-modal-fanpage',
                content: modalContent,
                isVisible: true,
                floating: true,
                buttons: [
                    {
                        icon: 'plus',
                        label: 'Еще один тикет',
                        action: () => formRef.current?.append(),
                        className: 'button-plain-xl',
                    },
                    {
                        label: 'Создать тикет',
                        action: submitForm,
                    },
                ],
                breadcrumbs: [
                    ['Расходники', modalFn.showConsumables],
                    ['Facebook', modalFn.showConsumablesFacebook],
                    ['Fanpage']
                ]
            }),
        [modalContent]
    )


    /////////////////
    useEffect(() => {

        if (defaultValues) {
            showModal()
            // setDefaultValues(null)
        }

    }, [modalContent, defaultValues, showModal, setDefaultValues])

    ////////////////
    return showModal


}