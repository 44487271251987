import { useContext, useEffect, useState } from 'react'
import { Form, formStateToJson, validateForm } from '../../components/Form/Form'
import { Icon } from '../../components/Icon/Icon'
import { If } from '../../components/If'
import { AppContext } from '../../context/AppContext'
import { AuthContext } from '../../context/AuthContext'
import { useApiRequest } from '../../hooks/api.request.hook'
import { tip } from '../../js/tip'
import './AuthPage.scss'

export const AuthPage = () => {


    ///////////////////////////////////////////
    const [isError, setError] = useState(false)
    const app = useContext(AppContext)
    const auth = useContext(AuthContext)
    const apiRequest = useApiRequest()


    /////////////////
    useEffect(() => {

        if (auth.data.accessToken) {

            (async () => {

                await apiRequest({
                    get: '/api/auth/verify',
                    200: () => auth.setIsAuth(true)
                })

            })()

        }

    }, [auth.data.accessToken, auth, apiRequest])


    ////////////////////////////////////////////
    const [formState, setFormState] = useState([
        {
            placeholder: 'Введите логин',
            type: 'text',
            name: 'user',
            required: 'string'
        }, {
            placeholder: 'Введите пароль',
            type: 'password',
            name: 'password',
            required: 'string'
        }, {
            type: 'checkbox',
            label: 'Оставаться в системе',
            name: 'stay'
        }
    ])


    ////////////////////////////////
    const submitForm = async () => {

        setError(false)
        const isValid = validateForm(formState, 'div.auth-page div.form', setFormState)

        if (isValid) {

            await apiRequest({
                post: '/api/auth/login',
                body: formStateToJson(formState),
                200: auth.login,
                409: () => setError(true),
                429: () => {
                    tip({
                        target: 'button.auth-page-submit',
                        content: 'Слишком много попыток входа, попробуйте позже'
                    })
                }
            })

        }

    }


    ////////////////////////////////////////////////////////
    return (<div className={ `auth-page theme-${app.theme}` }>

        <div className="auth-page-image">

            <img
                src={ `/images/${app.theme === 'dark' ? 'auth-desktop-dark' : 'auth-desktop'}.jpg` }
                alt="Auth Page"
            />

        </div>

        <div className="auth-page-auth">

            <div className="auth-page-auth-form">

                <h1 className="auth-page-title">Вход в CRM</h1>

                <If condition={ ['4000'].includes(window.location.port) }>
                    <div className="auth-page-testing-note">
                        <Icon name="warning" />
                        Тестовая версия CRM
                    </div>
                </If>

                <Form
                    isVisible={ true }
                    formState={ formState }
                    setFormState={ setFormState }
                    submitFn={ submitForm }
                    className="auth-page-form"
                />

                { isError
                    ? <div className="auth-page-error">
                        <Icon name="warning" />
                        Логин или пароль введены неверно
                    </div>
                    : ''
                }

                <button className="button-default-xl auth-page-submit" onClick={ submitForm }>Войти</button>


            </div>

        </div>

    </div>)


}
