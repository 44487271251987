
import ChartDataLabels from 'chartjs-plugin-datalabels'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { AppContext } from '../../context/AppContext'

import {
    CategoryScale, Chart as ChartJS, Filler, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip
} from 'chart.js'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
)

export const OverviewChartsPeriodic = ({ chartPeriod, list }) => {


    ////////////////////////////////////
    const [data, setData] = useState({
        labels: [],
        datasets: []
    })


    const app = useContext(AppContext)


    /////////////////
    useEffect(() => {

        if (list.length) {

            let newDataset = []
            let newLabels = []

            if (chartPeriod === 'day') {

                newDataset = list.map(item => item.sum?.toFixed(2) * item.qty)
                newLabels = list.map(item => moment(item.createdAt).format('hh:mm'))

            } else if (chartPeriod === 'week') {

                const weekDayNames = {
                    1: 'Вс',
                    2: 'Пн',
                    3: 'Вт',
                    4: 'Ср',
                    5: 'Чт',
                    6: 'Пт',
                    7: 'Сб'
                }

                for (let i = 1; i < 8; i++) {

                    const weekDate = moment().subtract(1, 'week').add(i, 'days')
                    const dayOfWeek = weekDate.day() + 1

                    let month = weekDate.month() + 1
                    month = (month < 10 ? '0' : '') + month

                    const isDayExists = list.find(item => item._id === dayOfWeek)
                    newDataset.push(isDayExists ? isDayExists.sum : 0)
                    newLabels.push(`${weekDayNames[dayOfWeek]}, ${weekDate.date()}.${month}`)

                }

            } else if (chartPeriod === 'month') {

                for (let i = 1; i < 31; i++) {

                    const monthDate = moment().subtract(1, 'month').add(i + 1, 'days')
                    const isDayExists = list.find(item => item._id === monthDate.date())

                    let month = monthDate.month() + 1
                    month = (month < 10 ? '0' : '') + month

                    newDataset.push(isDayExists ? isDayExists.sum : 0)
                    newLabels.push(`${monthDate.date()}.${month}`)

                }

            }

            const ctx = document.querySelector('div.overview-charts-periodic-container canvas').getContext('2d')
            const gradient = ctx.createLinearGradient(0, 0, 0, 400)
            gradient.addColorStop(0, app.theme.includes('dark') ? 'rgba(205, 0, 15, 0.29)' : 'rgba(236, 0, 17, 0.29)')
            gradient.addColorStop(1, app.theme.includes('dark') ? 'rgba(0, 0, 0, 0)' : 'rgba(255, 255, 255, 0)')

            setData({
                labels: newLabels,
                datasets: [
                    {
                        label: 'Сумма',
                        data: newDataset,
                        fill: true,
                        borderColor: '#EC0011',
                        backgroundColor: gradient,
                        lineTension: 0.5,
                        borderWidth: 3,
                        pointBackgroundColor: 'white',
                        pointBorderColor: '#EC0011',
                        pointBorderWidth: 3,
                        pointRadius: 6,
                        pointHitRadius: 50,
                        pointHoverRadius: 8,
                        pointHoverBorderWidth: 3,
                        pointHoverBackgroundColor: 'white',
                        datalabels: {
                            display: function (context) {
                                return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
                            },
                            color: app.theme.includes('dark') ? '#767676' : '#3C4245',
                            font: {
                                size: '16px'
                            },
                            padding: {
                                bottom: 30
                            },
                            align: 'top',
                            formatter: value => value + '$'
                        }
                    }
                ]
            })

        }


    }, [list, chartPeriod])


    /////////////////
    const plugins = [
        ChartDataLabels,
        Filler,
        {
            afterDraw: chart => {
                if (chart.tooltip?._active?.length) {
                    let x = chart.tooltip._active[0].element.x
                    let yAxis = chart.scales.y
                    let ctx = chart.ctx
                    ctx.save()
                    ctx.beginPath()
                    ctx.setLineDash([6, 6])
                    ctx.moveTo(x, chart.tooltip._active[0].element.y + 11)
                    ctx.lineTo(x, yAxis.bottom)
                    ctx.lineWidth = 1
                    ctx.strokeStyle = '#3C4245'
                    ctx.stroke()
                    ctx.restore()
                }
            }
        }
    ]


    /////////////////
    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#757B7E',
                    font: {
                        size: 14
                    }
                },
                border: {
                    display: false
                }
            },
            y: {
                display: false,
                grid: {
                    display: false
                }
            }
        },
        layout: {
            padding: {
                top: 40
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: (tooltipItem) => {

                        let costItem = list[tooltipItem.dataIndex]

                        if (costItem?.category) {

                            const categories = Array.isArray(costItem.category) ? costItem.category : [costItem.category]
                            const categoryCount = {}

                            categories.forEach(category => {

                                if (category.name in categoryCount) {
                                    categoryCount[category.name]++
                                } else {
                                    categoryCount[category.name] = 1
                                }

                            })

                            let result = ''

                            for (let categoryName in categoryCount) {
                                result += `${categoryName}` // (${categoryCount[categoryName]})
                            }

                            return result

                        } else if (costItem) {

                            return costItem._id

                        }

                    }
                },
                displayColors: false
            }
        },
        responsive: true,
        // maintainAspectRatio: true
    }


    //////////////////////////////////////////////////
    return (<div className="overview-charts-periodic">

        {

            list.length

                ? <div className="overview-charts-periodic-container">
                    <Line options={ options } data={ data } plugins={ plugins } />
                </div>

                : <div className="page-note">За выбранный период расходов нет</div>

        }

    </div>)

}