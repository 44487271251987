import React, { useCallback, useEffect, useMemo } from 'react'
import { SubmitHandler } from 'react-hook-form'
import FormConstructor from '../FormConstructor/FormConstructor'
import { useModal } from '../Modal2/Modal2'
import { useDefaultValues } from './default.values.hook'

export const useShowFacebookSamofarms = (
    modalFn: any,
    formRef: any,
    submitForm: Function,
    handleSubmit: SubmitHandler<any>,
) => {


    ///////////////////////////////
    const { setModal } = useModal()
    const { defaultValues, setDefaultValues } = useDefaultValues(modalFn, 'samofarms')

    const formSchema = useMemo(() => [
        {
            label: 'Резерв',
            type: 'radio-button',
            name: 'reserve',
            options: [
                { label: 'TD', value: 'td' },
                { label: 'Другое', value: 'other', checked: true }
            ],
            validation: { required: true },
        },
        {
            label: 'Ссылка на покупку (необязательно)',
            placeholder: 'Вставте ссылку на покупку',
            type: 'text',
            name: 'buyLink',
            pastable: true
        },
        {
            label: 'Ваш комментарий (необязательно)',
            placeholder: 'Ваш комментарий',
            type: 'textarea',
            name: 'comment',
        },
    ], [])

    ////////////////////////////////////
    const modalContent = useMemo(() => {
        return (
            <>
                <FormConstructor
                    ref={ formRef }
                    formSchema={ formSchema }
                    defaultValues={ defaultValues }
                    sectionTitle="Самофармы"
                    sectionCount={ 5 }
                    onSubmit={ (data) =>
                        handleSubmit({
                            ...data,
                            social: 'facebook',
                            category: 'samofarms',
                        })
                    }
                />
            </>
        )
    }, [formSchema, handleSubmit, formRef.current, defaultValues])

    /////////////////////////////////////////////
    const showModal = useCallback(() => setModal({
        title: 'Новый тикет',
        className: 'ticket-modal ticket-modal-samofarms',
        content: modalContent,
        floating: true,
        isVisible: true,
        buttons: [
            {
                icon: 'plus',
                label: 'Еще один тикет',
                action: () => formRef.current?.append(),
                className: 'button-plain-xl',
            },
            {
                label: 'Создать тикет',
                action: submitForm,
            },
        ],
        breadcrumbs: [
            ['Расходники', modalFn.showConsumables],
            ['Facebook', modalFn.showConsumablesFacebook],
            ['Самофармы']
        ]
    }), [modalContent])

    /////////////////
    useEffect(() => {

        if (defaultValues) {
            showModal()
            setDefaultValues(null)
        }

    }, [modalContent, defaultValues, showModal, setDefaultValues])

    ////////////////
    return showModal

}