import { everyFormItem } from './Form'

export const updateFormState = (setState, params, options) => {

    // options.skipUnexisting: bool // default: false. Пропустить несуществующие в params ключи. Иначе они обнуляются в state.
    // options.exactNameMatch: bool // default: true. Точное совпадение имени, ('name' === 'name'), иначе startsWith(`${name}-') ('name' === 'name-0')


    ////////////////////////
    const formItemParams = [
        'source',
        'hidden',
        'labelParamName',
        'valueParamName',
        'selectedIndex',
        'isInvalid',
        'placeholder',
        'disabled',
        'type',
        'required',
        'title',
        'value',
        'content',
        'excluded',
        'options',
        'checked'
    ]


    ////////////////////////////
    const updateItem = item => {

        if (!item.name) return

        const isNameInParams =
            options && (options.exactNameMatch || !('exactNameMatch' in options))
                ? (params && item.name in params) ? item.name : ''
                : Object.keys(params).find(key => item.name.startsWith(key))

        if (options?.skipUnexisting && !isNameInParams) return

        const newItem = params[isNameInParams] || ''

        if (
            item.type === 'text'
            || item.type === 'textarea'
            || item.type === 'editor'
            || item.type === 'uploader'
            || item.type === 'quantity'
            || item.type === 'custom'
            || item.type === 'validationError'
            || item.type === 'hidden'
            || 'items' in item
        ) {

            if (Array.isArray(newItem)) {

                item.value = newItem[0]

            } else if (typeof newItem === 'object' && !(newItem instanceof FileList)) {

                formItemParams.forEach(param => {
                    if (param in newItem) {
                        item[param] = newItem[param]
                    }
                })

            } else {

                item.value = newItem

            }

        } else if (
            (item.type === 'autocomplete' || item.type === 'select')
            && item.source
        ) {

            if (Array.isArray(newItem)) {

                item.selectedIndex = newItem.map(newValue =>
                    item.source.findIndex(sourceItem => sourceItem[item.valueParamName || 'value'] === newValue)
                )

            } else if (typeof newItem === 'object') {

                // new value is object

                formItemParams.forEach(param => {
                    if (param in newItem) item[param] = newItem[param]
                })

                // const newValue = ('value' in newItem) ? newItem.value : newItem

                if ('selectedIndex' in newItem) {
                    item.selectedIndex = newItem.selectedIndex
                } else if ('value' in newItem) {
                    item.selectedIndex = item.source.findIndex(sourceItem =>
                        sourceItem[item.valueParamName || 'value'] === newItem.value
                    )
                } else if (item.selectedIndex) {
                    // console.log(item.selectedIndex)
                }

                // if (newValue && !('selectedIndex' in newItem)) {
                //     item.selectedIndex = item.source?.findIndex(sourceItem =>
                //         sourceItem[item.valueParamName || 'value'] === newValue[item.valueParamName || 'value']
                //     )
                // if (item.name === 'category._id') console.log('newItem', newItem)
                // }

            } else {

                // new value is string/number

                item.selectedIndex = item.source.findIndex(sourceItem =>
                    sourceItem[item.valueParamName || 'value'] === newItem
                )

            }

        } else if (item.type === 'radio' || item.type === 'radio-button') {

            if (typeof newItem === 'object') {

                // new value is object

                formItemParams.forEach(param => {
                    if (param in newItem) item[param] = newItem[param]
                })

            }

            item.options.forEach(option => {
                option.checked = (option.value === params[isNameInParams])
            })

        } else if (item.type === 'range') {
            item.value = newItem.value
        } else if (item.type === 'checkbox' || item.type === 'checkbox-list') {

            if (typeof newItem === 'object') {

                // new value is object

                // process options

                if (item.options?.length) { // && newItem.options?.length

                    const valueName = item.valueParamName || 'value'

                    item.options.forEach(option => {

                        let newOption

                        if (newItem.options) {

                            newOption = newItem.options.find(newOption =>
                                newOption[valueName] === option[valueName]
                            )

                            if (!newOption) return

                            // if (option.checked === true) delete option.excluded
                            if ('checked' in option) newOption.checked = option.checked
                            if ('disabled' in newOption) option.disabled = newOption.disabled
                            // Object.assign(newOption, option)

                        } else if (newItem[option[valueName]]) {

                            newOption = newItem[option[valueName]]
                            if ('checked' in newOption) option.checked = newOption.checked
                            if ('excluded' in newOption) option.excluded = newOption.excluded

                        }

                    })

                }

                formItemParams.forEach(param => {
                    if (param in newItem) item[param] = newItem[param]
                })

                // возможно было нужно...
                // for (const optionValue in newItem) {

                //     const option = item.options.find(option => (option.value === optionValue) || (option._id === optionValue))
                //     if (item.name === 'team._id') console.log('item.options', item.options)
                //     if (!option) continue
                //     Object.assign(option, newItem[optionValue])
                //     if (option.checked === true) delete option.excluded

                // }

            } else {

                // new value is bool

                item.checked = !!newItem

            }

        }

    }


    /////////////////////
    setState(prevState =>
        everyFormItem(prevState, updateItem)
    )


}