/*

    <Form
        submitFn={submitFn} // Validate/submit form action. Fires on input:text [enter] keyup
        formState={formState} // Config/state: Array
        setFormState={setFormState} // setState: dispatcher Function,
        className="form-class-name"
    />

    FORM STATE
    ==========

    [
        fieldObject1,
        fieldObject2,
        fieldObjectNNN,
        [
            fieldObject1,
            fieldObject2,
            fieldObjectNNN
        ]
    ]

    FIELD OBJECT
    ============

    {
        title: 'optional field title',
        className: 'optional class name',
        placeholder: 'optional field placeholder',
        type: 'text|textarea|hidden|uploader|custom|checkbox|select|autocomplete|password|editor',
        content: 'content for "custom" type only',
        source: [ // "select|autocomplete" type only
            {
                label: 'option label',
                value: 'option value'
            }
        ],
        options: [ // "radio" type only
            {
                label: 'option label',
                value: 'option value'
            }
        ]
        labelParamName: 'string', // "select|autocomplete" type only, optional, default: 'label'
        valueParamName: 'string', // "select|autocomplete" type only, optional, default: 'value'
        url: '/api/url', // 'uploader' type only
        accept: 'image/*', // 'uploader' type only
        data: { param1: value1 }, // extra post params, 'uploader' type only
        name: 'field name', // not required with 'custom' type
        value: 'optional field value',
        required: 'string|password|number|email|radio|same|option|url|select', // optional
        autofocus: bool, // optional, default: false
        pastable: bool, // optional, default: false
        disabled: bool, // optional, default: false
        unnecessary: bool, // optional, default: false
        items: [
            fieldObject1,
            fieldObject2,
            fieldObjectNNN
        ],
        hidden: bool // optional, default: false,
        ref: useRef() // optional,
        collapsed: bool // optional, default: false
    }

*/

import { useRef, useEffect, useContext, useState, memo } from 'react'
import { fieldToClipboard, matchGroups } from '../../js/lib'
import { hideTips } from '../../js/tip'
import './Form.light.scss'
import { AppContext } from '../../context/AppContext'
import { renderFormItem } from './renderFormItem'

export const Form = memo(({
    submitFn,
    isVisible,
    onCreate,
    formState,
    setFormState,
    className = '',
    debug
}) => {


    ///////////////////////////////////
    const autoFocusField = useRef(null)
    const rndFormId = Math.random()
    const textInput = useRef(null)
    const app = useContext(AppContext)
    const [isConfirmDelImgVisible, setConfirmDelImgVisible] = useState(false)


    /////////////////////////////////
    const handleSubmit = (event) => {
        event.preventDefault()
    }


    ////////////////////////////////
    const handleKeyup = (event) => {

        if (event.key === 'Enter' && submitFn) {
            submitFn()
        }

    }


    /////////////////
    useEffect(() => {

        if (!formState.find(item => item.type === 'validationError')) {

            formState.push({
                type: 'validationError',
                name: 'validationError',
                hidden: true,
                defaultHidden: true
            })

        }

    }, [formState])


    /////////////////
    useEffect(() => {

        const form = document.querySelector(`[data-form-id="${rndFormId}"]`)
        const copyableFields = form.querySelectorAll('[data-copyable="true"]')

        copyableFields.forEach(field =>
            field.onclick = fieldToClipboard
        )

        if (isVisible && autoFocusField.current) {
            autoFocusField.current.focus()
        }

        if (!isVisible) {
            hideTips(`[data-form-id="${rndFormId}"]`)
        }

    }, [isVisible])


    /////////////////
    useEffect(() => {
        onCreate && onCreate()
        setDefaultValues(setFormState)
    }, [])


    ///////////
    return <div
        className={ `form ${className}` }
        onSubmit={ handleSubmit }
        data-form-id={ rndFormId }
    >

        { formState.map((row, rowIndex) => {

            let cells = []
            let rowClassName = `form-row ${row.className || ''} ${row.collapsed ? 'form-row-collapsed form-row-absolute' : ''}`

            if (Array.isArray(row)) {
                cells = row
            } else {
                cells = [row]
            }

            cells = cells.filter(item => !item.hidden)

            let rowArray = cells.map((cell, cellIndex) => {

                let cellItems = []
                let cellClassName = `form-cell ${cell.className || ''}`
                if (cell.collapsed) rowClassName += ' form-row-collapsed form-row-absolute'

                if (cell.items) {
                    cellItems = cell.items
                } else {
                    cellItems = [cell]
                }

                cellItems = cellItems.filter(item => item.type)
                cellItems = cellItems.filter(item => !item.hidden)

                if (!cellItems.length) {
                    return ''
                }

                const cellItemsGroupFiltered = cellItems.filter(item =>
                    !item.deniedGroups || !matchGroups(item.deniedGroups)
                )

                const cellArray = cellItemsGroupFiltered.map((item, itemIndex) => {

                    return renderFormItem(
                        item,
                        itemIndex,
                        className,
                        autoFocusField,
                        handleKeyup,
                        formState,
                        setFormState,
                        textInput,
                        app,
                        isConfirmDelImgVisible,
                        setConfirmDelImgVisible
                    )

                })

                return (
                    <div className={ cellClassName } key={ cellIndex }>
                        { cell.title && cell.items ? <div className="form-title">{ cell.title }</div> : '' }
                        <div className="form-items">{ cellArray }</div>
                    </div>
                )

            })

            rowArray = rowArray.filter(item => item)

            if (!rowArray.length) {
                return ''
            }

            return <div className={ rowClassName } key={ rowIndex } data-section={ cells[0].sectionIndex } data-section-toggle={ cells[0].isFormSectionToggle }>{ rowArray }</div>

        }) }

    </div>

})


/////////////////////////////////
export const isEmail = (str) => {
    return /^([a-z0-9_\.\-]+)@([a-z0-9_\.-]+)\.([a-z\.]{2,6})$/i.test(str)
}


//////////////////////////////////
export const isNumber = (num) => {
    return /^[0-9\.]+$/i.test(num)
}


//////////////////////////////////
export const isUrl = (string) => {

    return /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(string)

}


////////////////////////////////////
export const wrapLinks = string => {

    if (!string) return

    const urlRegex = /(https?:\/\/\S+)/g
    const parts = string.split(urlRegex)

    return parts.map((part, index) => {
        if (index % 2) {

            return (
                <a href={ part } target="_blank" className="clickable-link" key={ index }>
                    { part }
                </a>
            )

        } else {
            return part
        }
    })

}


///////////////////////////////////////////////////////
export const getFormItemByName = (formState, name) => {

    let result

    const defineResult = (item) => {
        if (item.name === name) result = item
    }

    everyFormItem(formState, item => {

        if (item.items) {
            item.items.forEach(defineResult)
        } else {
            defineResult(item)
        }

    })

    // formState.forEach(firstLevelItem => {

    //     if (result) return

    //     let items = Array.isArray(firstLevelItem) ? firstLevelItem : [firstLevelItem]

    //     items.forEach(item => {

    //         if (result) return

    //         if (item.items) {
    //             item.items.forEach(defineResult)
    //         } else {
    //             defineResult(item)
    //         }

    //     })

    // })

    return result

}


////////////////////////////////////////////
const setDefaultValues = (setFormState) => {

    setFormState(prevState =>

        everyFormItem(prevState, (item) => {

            if (!item.defaultValue) {

                if (['text', 'password', 'textarea', 'quantity'].includes(item.type) && 'value' in item) {

                    item.defaultValue = item.value

                } else if (['select', 'autocomplete'].includes(item.type)) {

                    if ('selectedIndex' in item) {
                        item.defaultIndex = item.selectedIndex
                        const sourceItem = item.source[item.selectedIndex]
                        if (sourceItem) item.defaultValue = sourceItem[item.valueParamName || 'value']
                    } else {
                        item.defaultIndex = []
                    }

                } else if (item.type === 'radio' && item.options?.some(option => option.checked)) {

                    item.defaultValue = item.options.find(option => option.checked).value

                }

            }

            if (!('defaultHidden' in item) && 'hidden' in item) {
                item.defaultHidden = item.hidden
            }

            if (!('defaultCollapsed' in item) && 'collapsed' in item) {
                item.defaultCollapsed = item.collapsed
            }

            if (!('defaultChecked' in item) && 'checked' in item) {
                item.defaultChecked = item.checked
            }

        })

    )

}


///////////////////////////////////////////////////////
export const everyFormItem = (formState, callback) => {

    ///
    const proceedCell = (cell) => {

        let cellItems = []

        if (cell.items) {
            cellItems = cell.items
            cellItems.push(cell)
        } else {
            cellItems = [cell]
        }

        cellItems.forEach(callback)

    }

    ///
    const proceedRow = row => {

        let cells = []

        if (Array.isArray(row)) {
            cells = row
        } else {
            cells = [row]
        }

        cells.forEach(proceedCell)
        return row

    }

    ///
    return formState.map(proceedRow)

}

export { validateForm } from './validateForm'
export { flattenFormState } from './flattenFormState'
export { handleInputChange } from './handleInputChange'
export { formStateToJson } from './formStateToJson'
export { updateFormState } from './updateFormState'
export { resetForm } from './resetForm'
