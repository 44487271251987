
/////////////////////////////////////////////////////////////
export const getOperators = async (apiRequest, setState) => {

    await apiRequest({
        get: '/api/operators',
        200: response => setState(response.operators),
    })

    // const response = await fetch('/api/operators', {
    //     method: 'GET',
    //     headers: { 'x-access-token': accessToken }
    // })

    // if (response.status === 200) {
    //     const responseJson = await response.json()
    //     setState(responseJson.operators)
    // }

}


////////////////////////////////////////////////////////
export const getWebs = async (apiRequest, setState) => {

    await apiRequest({
        get: '/api/webs',
        200: response => {

            // const newWebsList = response.data.map(row => {

            //     const date = row[0].content
            //     const dateSplitted = date.split('.')
            //     const oDate = new Date(dateSplitted[2], +dateSplitted[1] - 1, dateSplitted[0])

            //     return {
            //         date: oDate.getTime(),
            //         web: row[1].content.trim(),
            //         team: row[2].content.trim()
            //     }

            // })

            // let lastWebsByDateList = []

            // newWebsList.forEach(row => {

            //     const existingEntry = lastWebsByDateList.find(item => item.web === row.web)

            //     if (!existingEntry) {
            //         lastWebsByDateList.push(row)
            //     } else if (existingEntry.date < row.date) {
            //         lastWebsByDateList = lastWebsByDateList.map(item => item.web === row.web ? row : item)
            //     }

            // })

            // lastWebsByDateList.sort((a, b) => a.web > b.web)
            // lastWebsByDateList = lastWebsByDateList.filter(item => item.web && item.team)
            // setState(lastWebsByDateList)
            setState(response.webs)

        },
        otherStatus: () => setState([])
    })

    // const response = await fetch('/api/webs', {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'x-access-token': accessToken
    //     }
    // })

    // if (response.status === 200) {

    //     const responseJson = await response.json()

    //     const newWebsList = responseJson.data.map(row => {

    //         const date = row[0].content
    //         const dateSplitted = date.split('.')
    //         const oDate = new Date(dateSplitted[2], +dateSplitted[1] - 1, dateSplitted[0])

    //         return {
    //             date: oDate.getTime(),
    //             web: row[1].content.trim(),
    //             team: row[2].content.trim()
    //         }

    //     })

    //     let lastWebsByDateList = []

    //     newWebsList.forEach(row => {

    //         const existingEntry = lastWebsByDateList.find(item => item.web === row.web)

    //         if (!existingEntry) {
    //             lastWebsByDateList.push(row)
    //         } else if (existingEntry.date < row.date) {
    //             lastWebsByDateList = lastWebsByDateList.map(item => item.web === row.web ? row : item)
    //         }

    //     })

    //     lastWebsByDateList.sort((a, b) => a.web > b.web)
    //     lastWebsByDateList = lastWebsByDateList.filter(item => item.web && item.team)
    //     setState(lastWebsByDateList)

    // } else {
    //     setState([])
    // }

}


///////////////////////////////////////////////////////////
export const getSellers = async (apiRequest, setState) => {

    await apiRequest({
        get: '/api/sellers',
        200: response => setState(response.sellers)
    })

}


////////////////////////////////////////////////////////
export const getGeos = async (apiRequest, setState) => {

    await apiRequest({
        get: '/api/geos',
        200: response => setState(response.geos),
    })

}