import { memo, useCallback, useContext } from 'react'
import { AppContext } from '../../context/AppContext'
import { AuthContext } from '../../context/AuthContext'
import { useApiRequest } from '../../hooks/api.request.hook'
import { matchGroups } from '../../js/lib'
import { Icon } from '../Icon/Icon'
import { useModal } from '../Modal2/Modal2'
import './Maintenance.scss'

/**
 * Renders a button component for toggling maintenance mode.
 * @component
 */

/////////////////////////////////////////////
export const MaintenanceButton = memo(() => {


    //////////////////////////////////////
    const isAdmin = matchGroups(['Admin'])
    const { setModal } = useModal()
    const apiRequest = useApiRequest()
    const app = useContext(AppContext)


    //////////////////////////////////////////////////
    const submitModeSwitch = useCallback(async () => {

        setModal(false)

        await apiRequest({
            patch: `/api/maintenance?activate=${!app.maintenanceMode}`
        })

    }, [setModal, apiRequest, app.maintenanceMode])


    ////////////////////////////////////////////
    const showConfirmModal = useCallback(() => {

        setModal({
            title: `${app.maintenanceMode ? 'Выключить' : 'Включить'} режим обслуживания?`,
            content: app.maintenanceMode
                ? 'Все пользователи снова получат доступ к системе'
                : 'Все пользователи, кроме Админов, не смогут пользоваться системой',
            isVisible: true,
            buttons: [
                {
                    label: app.maintenanceMode ? 'Выключить' : 'Включить',
                    action: submitModeSwitch
                }, {
                    label: 'Отмена',
                    className: 'button button-2',
                    action: () => setModal(false)
                }
            ]
        })

    }, [setModal, submitModeSwitch, app.maintenanceMode])


    ////////////////////
    if (!isAdmin) return


    //////////////
    return <button
        className="maintenance-button topbar-item"
        title={ `${app.maintenanceMode ? 'Выключить' : 'Включить'} режим обслуживания` }
        onClick={ showConfirmModal }
    >
        <Icon name="maintenance" />
    </button>


})


///////////////////////////////////////////
export const MaintenanceNote = memo(() => {


    ////////////////////////////////////
    const auth = useContext(AuthContext)


    /////////////////////////////////////////
    return <div className="maintenance-note">

        <label>
            <Icon name="maintenance" />
            Ведутся технические работы.
            <br />
            Система временно недоступна
        </label>

        <button
            className="button button-1"
            onClick={ auth.logout }
        >Выйти</button>

    </div>


})