import React from 'react';
import Icon from '../Icon/Icon';
import './TicketModalCount.scss';

interface TicketProps {
    title: string;
    index: number;
    showIndex: boolean;
    onClick: () => void;
    onDelete: () => void;
}

const TicketModalCount: React.FC<TicketProps> = ({ title, index, showIndex, onClick, onDelete }) => {


    //////////////////////////////////////////
    return <div
        onClick={ () => showIndex && onClick() }
        className="ticket-modal-count"
    >
        { showIndex && <Icon name="chevron-down" /> }
        Тикет на { title }
        { showIndex && ` ${index}` }
        { showIndex && <Icon name="close" onClick={ onDelete } /> }
    </div>


}

export default TicketModalCount
