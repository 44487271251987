import { createContext, useContext, useEffect, useState } from 'react'
import io from 'socket.io-client'
import { AuthContext } from './AuthContext'
import { getBackendUrl } from '../js/lib'

export const SocketContext = createContext()

export const SocketProvider = ({ children }) => {


    ////////////////////////////////////
    const auth = useContext(AuthContext)
    const [socket, setSocket] = useState(null)


    /////////////////
    useEffect(() => {

        if (auth.data.accessToken) {

            const newSocket = io(getBackendUrl(), {
                query: { accessToken: auth.data.accessToken },
                reconnectionDelay: 1500,
                reconnectionDelayMax: 6000,
                withCredentials: true
            })

            setSocket(newSocket)
            return () => newSocket.close()

        }

    }, [auth.data.accessToken])


    ////////////////////////////////////////////////
    return <SocketContext.Provider value={ socket }>
        { children }
    </SocketContext.Provider>


}