export const flattenFormState = (state) => {

    let result = []

    state.forEach(row => {

        let cells = []

        if (Array.isArray(row)) {
            cells = row
        } else {
            cells = [row]
        }

        cells = cells.filter(item => !item.hidden)

        cells.forEach(cell => {

            let cellItems = []

            if (cell.items) {
                cellItems = cell.items
            } else {
                cellItems = [cell]
            }

            cellItems = cellItems.filter(item => !item.hidden)

            cellItems.forEach(item => {

                if (!item.name) {
                    return
                }

                if (item.type === 'radio') {

                    const isRadioItemExists = result.some(resultItem => resultItem.name === item.name)

                    if (isRadioItemExists) {

                        if (item.options) {

                            const checkedOption = item.options.find(option => option.checked)
                            if (checkedOption) {
                                result[item.name] = checkedOption.value
                            }

                        } else if (item.checked) {

                            result = result.map(resultItem => {

                                if (resultItem.name === item.name) {
                                    resultItem.value = item.value
                                }

                                return resultItem

                            })

                        }

                        return

                    }

                }

                let newElement = {
                    name: item.name,
                    stateRef: item
                }

                if (item.value) { newElement.value = item.value }
                if ('selectedIndex' in item) { newElement.selectedIndex = item.selectedIndex }
                if (item.required) { newElement.required = item.required }
                if (item.requiredSame) { newElement.requiredSame = item.requiredSame }
                if (item.checked) { newElement.checked = item.checked }
                if (item.options) { newElement.value = item.options.find(option => option.checked)?.value }

                if (
                    item.type === 'select'
                    || item.type === 'autocomplete'
                ) {
                    newElement.tipTarget = '.select-toggle'
                }

                result.push(newElement)

            })

        })

    })

    return result

}