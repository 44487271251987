import { useCallback, useEffect, useMemo } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useModal } from '../Modal2/Modal2'
import React from 'react'
import FormConstructor from '../FormConstructor/FormConstructor'
import { useDefaultValues } from './default.values.hook'

export const useShowFacebookConsumable = (
    modalFn: any,
    formRef: any,
    submitForm: Function,
    handleSubmit: SubmitHandler<any>,
) => {


    ///////////////////////////////
    const { setModal } = useModal()
    const { defaultValues, setDefaultValues } = useDefaultValues(modalFn, 'samofarms')

    const formSchema = useMemo(() => [
        [
            {
                label: 'Сумма',
                type: 'text',
                name: 'sum',
                placeholder: 0,
                validation: { required: true },
            },
            {
                label: 'Валюта',
                type: 'select',
                name: 'currency',
                placeholder: 0,
                options: [
                    { label: '$', value: '$' },
                    { label: '€', value: '€' },
                    { label: '₴', value: '₴' }
                ],
                validation: { required: true },
            },
            {
                label: 'Способ оплаты',
                type: 'select',
                name: 'paymentMethod',
                options: [
                    { label: 'Карта Украины (комиссия в кост)', value: 'uaCardComissionInCost' },
                    { label: 'BTC (комиссия в кост)', value: 'btcComissionInCost' },
                    { label: 'USDT TRC20 (минимальный платёж 10$)', value: 'usdtTrc20' },
                    { label: 'USDT ERC20 (комиссия в кост)', value: 'usdtErc20ComissionInCost' },
                    { label: 'LTC (комиссия в кост)', value: 'ltcComissionInCost' },
                    { label: 'ETH (комиссия в кост)', value: 'ethComissionInCost' },
                    { label: 'PayPal', value: 'paypal' },
                    { label: 'Другое (в комментарии)', value: 'other' }
                ],
                validation: { required: true },
            }
        ],
        {
            label: 'Реквизиты',
            placeholder: 'Оставьте реквизиты для оплаты',
            type: 'text',
            name: 'requisites',
            pastable: true,
            validation: { required: true },
        },
        {
            label: 'Ваш комментарий (необязательно)',
            placeholder: 'Ваш комментарий',
            type: 'textarea',
            name: 'comment',
        },
    ], [])

    ////////////////////////////////////
    const modalContent = useMemo(() => {
        return (
            <>
                <FormConstructor
                    ref={ formRef }
                    formSchema={ formSchema }
                    defaultValues={ defaultValues }
                    sectionCount={ 5 }
                    sectionTitle="Расходник"
                    onSubmit={ (data) =>
                        handleSubmit({
                            ...data,
                            social: 'facebook',
                            category: 'consumable'
                        })
                    }
                />
            </>
        )
    }, [formSchema, handleSubmit, formRef.current, defaultValues])

    //////////////////////////////////////////////
    const showModal = useCallback(() => setModal({
        title: 'Новый тикет',
        className: 'ticket-modal ticket-modal-сonsumable',
        content: modalContent,
        isVisible: true,
        floating: true,
        buttons: [
            {
                icon: 'plus',
                label: 'Еще один тикет',
                action: () => formRef.current?.append(),
                className: 'button-plain-xl',
            },
            {
                label: 'Создать тикет',
                action: submitForm,
            },
        ],
        breadcrumbs: [
            ['Расходники', modalFn.showConsumables],
            ['Facebook', modalFn.showConsumablesFacebook],
            ['Расходник']
        ]
    }), [modalContent])

    /////////////////
    useEffect(() => {

        if (defaultValues) {
            showModal()
            setDefaultValues(null)
        }

    }, [modalContent, defaultValues, showModal, setDefaultValues])

    ////////////////
    return showModal

}