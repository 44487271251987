import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import { isElementVisible } from './lib';


////////////////////////////////
export const tip = (params) => {

    // params.target
    // params.content
    // params.placement
    // params.timeout
    // params.trigger
    // params.theme

    const timeout = params.timeout || 5000;
    const trigger = params.trigger || 'manual';
    const placement = params.placement || 'top-end';
    const theme = params.theme || '';

    if (!params.target) return;
    // if (!isElementVisible(params.target)) return;

    if (typeof params.target == 'string') {
        params.target = document.querySelector(params.target);
    }

    params.target._tippy && params.target._tippy.hide()

    const instance = tippy(
        params.target,
        {
            content: params.content,
            trigger: trigger,
            placement: placement,
            hideOnClick: false,
            showOnCreate: true,
            allowHTML: true,
            theme
        }
    );

    const hideTip = () => {
        if (typeof instance.hide == 'function') {
            instance.hide();
        } else {
            instance[0].hide();
        }
    }

    params.target.addEventListener('input', hideTip);
    setTimeout(hideTip, timeout);

    return false;

}


////////////////////////////////////////
export const hideTips = (container) => {

    if (typeof container == 'string') {
        container = document.querySelector(container)
    } else if (!container) {
        container = document
    }

    if (!container) return

    const tips = container.querySelectorAll('[aria-describedby^="tippy"]')
    container._tippy && container._tippy.hide()

    if (tips) {
        tips.forEach((item) => {
            item._tippy.hide()
        })
    }

}