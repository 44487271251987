import { forwardRef, memo, useEffect, useMemo, useState } from 'react'
import { useDebounce } from '../../hooks/debounce.hook'

/**
 * A memoized textarea component.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.value - The initial value of the textarea.
 * @param {string} props.name - The name of the textarea.
 * @param {string} props.formClassName - The class name of the form.
 * @param {boolean} props.isInvalid - Indicates if the textarea is invalid.
 * @param {function} props.onChange - The event handler for the onChange event.
 * @param {function} props.onInput - The event handler for the onInput event.
 * @param {function} props.onKeyDown - The event handler for the onKeyDown event.
 * @param {function} props.onPaste - The event handler for the onPaste event.
 * @returns {JSX.Element} The textarea component.
 */

export const Textarea = memo(forwardRef((props, ref) => {


    ///////////////////////////////////////////////
    const [value, setValue] = useState(props.value)
    const localStorageKey = `input-value-${props.name}-${props.formClassName}`
    const { isInvalid, ...restProps } = props || {}


    /////////////////
    useEffect(() => {
        setValue(props.value)
    }, [props.value])


    //////////////////////////////////////////////
    const debouncedSave = useDebounce((value) => {

        localStorage.setItem(localStorageKey, value)

    }, 500)


    /////////////////
    useEffect(() => {

        const savedValue = localStorage.getItem(localStorageKey)
        if (savedValue) setValue(savedValue)

    }, [localStorageKey])


    /////////////////
    useEffect(() => {

        if (value?.trim()?.length > 0 && props?.formClassName) {
            debouncedSave(value)
        } else {
            localStorage.removeItem(localStorageKey)
        }

    }, [value, localStorageKey])


    ///////////////////////////////////
    const attributes = useMemo(() => ({
        ...restProps,
        onChange: event => {
            if (props.onChange) {
                props.onChange(event)
            }
            setValue(event.target.value)
        },
        onInput: props.onInput,
        onKeyDown: props.onKeyDown,
        onPaste: props.onPaste,
        value,
        ...('isInvalid' in props && { 'data-is-invalid': props.isInvalid })
    }), [props, value])


    delete attributes.formClassName


    //////////////////////////////////////////////////////////
    return <textarea ref={ ref } { ...attributes }></textarea>


}))