/**
 * Editor Component
 * 
 * This component renders a TinyMCE editor with custom configuration. It supports dynamic theme changes and handles content updates.
 * 
 * @component
 * @example
 * ```jsx
 * <Editor
 *   name="editorName"
 *   value={editorValue}
 *   onChange={handleEditorChange}
 *   placeholder="Enter text here..."
 * />
 * ```
 * 
 * @param {Object} props - Component properties
 * @param {string} props.name - The name attribute for the editor
 * @param {string} props.value - The initial content of the editor
 * @param {Function} props.onChange - The function to call when the content of the editor changes
 * @param {string} props.placeholder - The placeholder text for the editor
 * 
 * @returns {JSX.Element} The rendered TinyMCE editor component
 */


import tinymce from 'tinymce/tinymce'
import 'tinymce/models/dom/model'
import 'tinymce/themes/silver'
import 'tinymce/icons/default'
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/link'
import 'tinymce/plugins/image'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/wordcount'
import 'tinymce/plugins/code'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/media'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/table'
import 'tinymce/plugins/help'
import { useContext, useEffect, useRef } from 'react'
import { AppContext } from '../../context/AppContext'


export const Editor = ({ name, value, onChange, placeholder }) => {


    ////////////////////////////////
    const textareaRef = useRef(null)
    const editorRef = useRef(null)
    const app = useContext(AppContext)


    /////////////////
    useEffect(() => {

        (async () => {

            editorRef.current = await tinymce.init({
                target: textareaRef.current,
                promotion: false,
                language_url: '/plugins/tinymce/langs/ru.js',
                language: 'ru',
                resize: false,
                height: 300,
                menubar: false,
                statusbar: false,
                skin_url: app.theme.includes("dark") ? '/plugins/tinymce/skins/ui/td-farm-crm-dark' : '/plugins/tinymce/skins/ui/oxide',
                // skin: app.theme === 'dark' ? 'td-farm-crm-dark' : 'oxide',
                content_css: app.theme.includes("dark") ? '/plugins/tinymce/skins/content/td-farm-crm-dark/content.min.css' : '/plugins/tinymce/skins/content/default/content.min.css',
                setup: function (editor) {

                    editor.on('keyup', () => {

                        onChange && onChange({
                            target: {
                                name: name,
                                value: editor.getContent()
                            }
                        })

                    })

                }
            })

        })()

        return () => {

            if (
                editorRef?.current
                && editorRef?.current[0]
                && editorRef.current[0]?.destroy()
            ) {
                try {
                    editorRef.current[0]?.destroy()
                } catch (e) { }
            }

        }

    }, [app.theme, name])


    /////////////////
    useEffect(() => {

        if (
            value
            && editorRef.current
            && editorRef.current[0]
            && editorRef.current[0].getContent() !== value
        ) {
            editorRef.current[0].setContent(value)
        }

    }, [value])


    /////////////////////////////////////////////////////////////////////
    return (<textarea ref={ textareaRef } placeholder={ placeholder } />)

}