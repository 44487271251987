import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useApiRequest } from '../../hooks/api.request.hook'
import { msToHr } from '../../js/date'
import { defineCase } from '../../js/text'
import { Dropdown } from '../Dropdown/Dropdown'
import { Icon } from '../Icon/Icon'
import { NotificationCounter } from '../NotificationCounter/NotificationCounter'
import { Timer } from '../Timer/Timer'
import './TopCurrentTasks.light.scss'

/**
 * Renders the top current tasks component.
 * @returns {JSX.Element} The top current tasks component.
 */

export const TopCurrentTasks = () => {


    ///////////////////////////////////////////////////////////////
    const [isDropdownVisible, setDropdownVisible] = useState(false)
    const [tasks, setTasks] = useState([])
    const buttonRef = useRef(null)
    const navigate = useNavigate()
    const apiRequest = useApiRequest()


    ////////////////////////////////////////////
    const handleButtonBlur = useCallback(() => {

        setTimeout(() => {
            setDropdownVisible(false)
        }, 200)

    }, [])


    /////////////////////////////////////////////
    const updateTasks = useCallback(async () => {

        await apiRequest({
            get: '/api/requests?status=active',
            200: response => setTasks(response.list)
        })

    }, [apiRequest])


    /////////////////
    useEffect(() => {

        const interval = setInterval(updateTasks, 5000)
        return () => clearInterval(interval)

    }, [updateTasks]) // auth.data.accessToken ???


    //////////////////////////////////////////
    const getTaskTitle = useCallback(task => {

        if (task.category.alias === 'task') {
            return task.data.title
        } else {
            return `${task.category.name} №${task.id || ''}`
        }

    }, [])


    // SINGLE TASK
    ////////////////////////
    if (tasks.length <= 1) {

        return (<div
            className="top-current-tasks-toggle"
            onClick={ () => navigate('/requests/' + tasks[0]._id) }
            onBlur={ handleButtonBlur }
            ref={ buttonRef }
            tabIndex="1"
        >
            <span className="top-current-tasks-toggle-title">{ tasks.length ? getTaskTitle(tasks[0]) : '' }</span>
            <Icon name="pause" className="top-current-tasks-toggle-icon" />
            <span className="top-current-tasks-toggle-time">{ tasks.length ? calcTaskTime(tasks[0]) : '' }</span>
        </div>)

    }


    // MULTIPLE TASKS
    /////////
    return <>

        <div
            className="top-current-tasks-toggle"
            onClick={ () => setDropdownVisible(!isDropdownVisible) }
            onBlur={ handleButtonBlur }
            ref={ buttonRef }
            tabIndex="1"
        >
            В работе { tasks.length } { defineCase(tasks.length, 'задача', 'задачи', 'задач') }
        </div>

        <Dropdown
            content={
                tasks.length
                    ? tasks.map((task, taskIndex) => {
                        return <div key={ taskIndex } className="top-current-task" onClick={ () => navigate('/requests/' + task._id) }>

                            <div className="top-current-task-category">Ежедневная задача</div>

                            <div className="top-current-task-counter">
                                <Icon name="envelope" />
                                <NotificationCounter value={ task.messagesCount } color={ task.isUnreadMessages ? 'red' : 'grey' } />
                            </div>

                            <div className="top-current-task-timer">
                                <Timer
                                    start={ task.takeDate }
                                    end={ task.pauseDate || '' }
                                    pausedSeconds={ task.pausedSeconds }
                                    isPaused={ task.status === 'paused' }
                                    options={ {
                                        seconds: false
                                    } }
                                />
                            </div>

                            <div className="top-current-task-title">{ getTaskTitle(task) }</div>

                        </div>
                    })
                    : 'Активных задач нет'
            }
            isVisible={ isDropdownVisible }
            className="top-current-tasks-dropdown"
            button={ buttonRef.current }
        />

    </>


}


////////////////////////////////
const calcTaskTime = (task) => {

    let endDate

    if (task.pauseDate) {
        endDate = new Date(task.pauseDate)
    } else {
        endDate = new Date()
    }

    endDate.setSeconds(endDate.getSeconds() - task.pausedSeconds)

    const startDate = new Date(task.takeDate)
    const diffMs = endDate - startDate
    return msToHr(diffMs, {
        seconds: false
    })

}