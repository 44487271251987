import React, { createContext, Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { Icon } from '../../components/Icon/Icon'
import { If } from '../../components/If'
import './Modal2.light.scss'

const SWITCH_MODALS_DELAY = 100 // should be equal to css transition duration

////////////////////////////////////
const ModalContext = createContext()

/**
 * ModalProvider component provides a context for managing modals in the application.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {JSX.Element} The rendered ModalProvider component.
 */

/////////////////////////////////////////////
export function ModalProvider({ children }) {


    //////////////////////////////////////////////////
    const [modalState, setModalState] = useState(null)
    const [hideTimerId, setHideTimerId] = useState(null)


    /////////////////
    useEffect(() => {

        if (modalState?.isVisible) {

            document.body.classList.add('is-modal2-visible')

        } else {

            window.scrollTo(0, 0)
            document.body.classList.remove('is-modal2-visible')

            setModalState(prevState => ({
                ...prevState,
                position: ''
            }))

        }

    }, [modalState?.isVisible, setModalState])


    ///////////////////////////////////
    const buttonsHTML = useMemo(() => {

        if (modalState && !('buttons' in modalState)) return

        return modalState?.buttons.map((item, index) => {

            if (item.type === 'custom') {
                return <Fragment key={ index }>{ item.content }</Fragment>
            }

            const btnClass = item.className || 'button-default-xl'

            return <button
                className={ btnClass }
                onClick={ (event) => {
                    event.preventDefault()
                    item.action && item.action(event)
                } }
                key={ index }
            >
                { item.icon && <Icon name={ item.icon } /> }
                <label>{ item.label }</label>
            </button>

        })

    }, [modalState])


    ////////////////////////////////////////
    const setModal = useCallback(params => {

        // hide when setModal(false)

        if (!params) {

            return setModalState(prevState => ({
                ...prevState,
                isVisible: false
            }))

        }

        // set default values

        if (!('closeButton' in params)) {
            params.closeButton = true
        }

        // hide by timer when "timeout" passed

        if (params?.timeout && params?.isVisible) {

            setHideTimerId(setTimeout(() => {

                setModalState(prevState => ({
                    ...prevState,
                    isVisible: false
                }))

            }, params.timeout))

        }

        // stop hide timer when no "timeout" passed

        if ('timeout' in params && !params.timeout) {
            stopHideTimer()
        }

        // return if updating another non-visible modal 

        if (
            !('isVisible' in params)
            && params.className !== modalState?.className
        ) return

        setModalState(prevState => {

            // switch to another modal with animation

            const isSwitchingModals = (
                params.isVisible
                && prevState?.isVisible
                && params.className !== prevState?.className
            )

            if (isSwitchingModals) {

                setTimeout(() => {

                    setModalState(() => ({
                        ...params,
                        isVisible: true
                    }))

                }, SWITCH_MODALS_DELAY)

                return {
                    ...prevState,
                    isVisible: false
                }

            }

            // update/show/hide modal

            if ('isVisible' in params) {

                return {
                    ...params,
                    isVisible: params.isVisible
                }

            }

            // just update modal content with no visibility change

            return {
                ...prevState,
                ...params
            }

        })

    }, [setModalState, modalState?.isVisible, modalState?.className])


    /////////////////////////////////////
    const hideModal = useCallback(() => {

        setModal(null)

    }, [setModal])


    /////////////////////////////////////////
    const stopHideTimer = useCallback(() => {

        if (hideTimerId) {
            clearTimeout(hideTimerId)
            setHideTimerId(null)
        }

    }, [hideTimerId, setHideTimerId])


    //////////////////////////////////////////
    const startHideTimer = useCallback(() => {

        stopHideTimer()

        if (modalState?.timeout) {
            setHideTimerId(setTimeout(() => {

                setModalState(prevState => ({
                    ...prevState,
                    isVisible: false
                }))

            }, modalState.timeout))
        }

    }, [modalState?.timeout, setModalState])


    /////////////////////////////////////////////////////////////////
    return <ModalContext.Provider value={ { setModal, modalState } }>

        { children }

        { createPortal(
            <div className={ `
                modal2
                ${modalState?.className || ''}
                ${modalState?.isVisible ? 'modal2-visible' : ''}
                ${modalState?.position === 'top' ? 'modal2-top' : ''}
                ${modalState?.floating ? 'modal2-floating' : ''}
            `}>

                <div
                    className="modal2-dialog"
                    onMouseEnter={ stopHideTimer }
                    onMouseLeave={ startHideTimer }
                >

                    <If condition={
                        modalState?.title
                        || modalState?.headerContent
                        || modalState?.closeButton
                        || modalState?.breadcrumbs?.length
                    }>

                        <div className="modal2-header">

                            { modalState?.title && <div className="modal2-title">{ modalState?.title }</div> }
                            { modalState?.headerContent && <div className="modal2-header-content">{ modalState?.headerContent }</div> }

                            { modalState?.breadcrumbs?.length > 0 && <div className="modal2-breadcrumbs">{
                                modalState?.breadcrumbs.map(([label, action], index) =>

                                    <div
                                        key={ index }
                                        className={ `modal2-breadcrumbs-item ${action ? 'modal2-breadcrumbs-link' : ''}` }
                                        onClick={ () => action && action() }
                                    >/ <label>{ label }</label></div>

                                )
                            }</div> }

                            { modalState?.closeButton && <div className="modal2-close" onClick={ hideModal }><Icon name="close" /></div> }

                        </div>

                    </If>

                    { modalState?.content && <div className="modal2-content">{ modalState?.content }</div> }
                    { modalState?.contentHTML && <div className="modal2-content" dangerouslySetInnerHTML={ { __html: modalState?.contentHTML } }></div> }
                    { buttonsHTML && <div className="modal2-footer">{ buttonsHTML }</div> }

                </div>

                <div className="modal2-shade" onClick={ hideModal }></div>

            </div>,
            document.body
        ) }

    </ModalContext.Provider>

}

////////////////////////////
export function useModal() {
    return useContext(ModalContext)
}