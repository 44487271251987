import { NavLink } from 'react-router-dom'
import { UserAvatar } from '../UserAvatar/UserAvatar'
import './UserWidget.scss'

/**
 * Renders the UserWidget component.
 * 
 * @returns {JSX.Element} The rendered UserWidget component.
 */

export const UserWidget = () => {

    return <div className="user-widget">
        <NavLink to="/user" className="user-widget-toggle">
            <UserAvatar />
        </NavLink>
    </div>

}