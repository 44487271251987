import React, { memo, useCallback, useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { matchGroups } from '../../js/lib'
import { Select } from '../Select/Select'
import './TicketModalSearch.scss'


//////////////////////
const searchSource = [
    { label: 'Расходники', value: 'showConsumables' },
    { label: 'Техническая помощь', value: 'showTech' },
    { label: 'Facebook', value: 'showConsumablesFacebook' },
    { label: 'Facebook / Fanpage', value: 'showFacebookFanpage' },
    { label: 'Facebook / Автореги', value: 'showFacebookAutoregs' },
    { label: 'Facebook / БМ', value: 'showFacebookBm' },
    { label: 'Facebook / Карты', value: 'showFacebookCards' },
    { label: 'Facebook / Логи', value: 'showFacebookLogs' },
    { label: 'Facebook / Прокси', value: 'showFacebookProxy' },
    { label: 'Facebook / Самофармы', value: 'showFacebookSamofarms' },
    { label: 'Facebook / Расходник', value: 'showFacebookConsumable' },
    { label: 'Facebook / Другое', value: 'showFacebookOther' },
    { label: 'Google', value: 'showConsumablesGoogle' },
    { label: 'Google / Покупные фармы', value: 'showGooglePurchasedFarm' },
    { label: 'Google / TD фарм', value: 'showGoogleTdFarm' },
    { label: 'Google / Другое', value: 'showGoogleOther' },
    { label: 'Google / Прокси', value: 'showGoogleProxy' },
    { label: 'Google / Расходник', value: 'showGoogleConsumable' },
    { label: 'Agency', value: 'showConsumablesAgency' },
    { label: 'Agency / Замена', value: 'showAgencyReplace' },
    { label: 'Agency / Репорт', value: 'showAgencyReport' },
    { label: 'Agency / Новый', value: 'showAgencyNew' },
    { label: 'Creative', value: 'showConsumablesDesign' },
    { label: 'Creative / UGC', value: 'showDesignUgc' },
    { label: 'Creative / Статика', value: 'showDesignStatic' },
    { label: 'Creative / Видео', value: 'showDesignVideo' }
]


//////////////////////////////////
interface TicketModalSearchProps {
    modalFn: any[]
}


////////////////////////////////////////////////////////////////////////////////
export const TicketModalSearch = memo(({ modalFn }: TicketModalSearchProps) => {


    ////////////////////////////////////
    const auth = useContext(AuthContext)
    const isTeamAllowedTech = ['Sem', 'Midas Team', 'Performance'].includes(auth.data.team?.name)
    const isTeamAllowedCreative = ['Sem', 'Dating devils', 'VenceDigital'].includes(auth.data.team?.name)
    const isAdmin = matchGroups(['Admin'])


    ///////////////////////////////////////////////////////////////////////
    const searchSourceFilteredByTeam = searchSource.filter((item: any) => {

        const isCreativeSection = item.value.includes('Creative') || item.value.includes('Design')
        const isTechSection = item.value.includes('Tech')

        return (
            (isCreativeSection && isTeamAllowedCreative)
            // || (isTechSection && isTeamAllowedTech)
            || isAdmin
            || (!isCreativeSection)  // && !isTechSection
        )

    })


    //////////////////////////////////////////////////
    const handleChange = useCallback((event: any) => {

        const { value } = event.target
        modalFn[value] && modalFn[value]()

    }, [])


    ////////////////////////////////////////////
    return <div className="ticket-modal-search">
        <Select
            placeholder="Начните вводить название темы тикета"
            source={ searchSourceFilteredByTeam }
            name="ticketModalSearch"
            autocomplete={ true }
            onChange={ handleChange }
        />
    </div>


})

