import React, { memo, useCallback, useContext } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { matchGroups } from '../../js/lib'
import { Icon } from '../Icon/Icon'
import { MenuItem } from './MenuItem'
import './SideBar.light.scss'

/**
 * Sidebar component.
 *
 * @component
 * @param {boolean} isSidebarVisible - Indicates whether the sidebar is visible or not.
 * @param {function} setSidebarVisible - Function to set the visibility of the sidebar.
 * @returns {JSX.Element} Sidebar component.
 */

export const SideBar = memo(({ isSidebarVisible, setSidebarVisible }) => {


    //////////////////////////////////////////////////////
    const requests = { name: 'Активные', icon: 'active2' }
    const location = useLocation()
    const auth = useContext(AuthContext)


    ////////////////////////////////////
    if (matchGroups(['Manager Farm'])) {
        requests.name = 'Доступные'
        requests.icon = 'available'
    }


    //////////////////////////////////////////
    const hideSidebar = useCallback(event => {

        event && event.stopPropagation()
        setSidebarVisible(false)

    }, [setSidebarVisible])


    ///////////////////
    const menuItems = [
        { name: 'Обзор', route: '/', icon: 'overview2' },
        { name: requests.name, route: '/requests', icon: requests.icon },
        { name: 'Архив', route: '/archive', icon: 'archive2' },
        {
            name: 'Прокси', route: '/proxy', icon: 'proxy2', allowedGroups: ['Support Proxy', 'Buyer', 'Support Farm', 'Admin', 'Team Lead'], submenu: [
                { name: 'Прайс-лист', route: '/proxy/price', allowedGroups: ['Admin', 'Support Proxy'] }
            ]
        },
        { name: 'Расходы', route: '/costs', icon: 'costs2', deniedGroups: ['Support Farm', 'Support Design', 'Support Technical', 'Manager Technical'] },
        {
            name: 'Новости', route: '/news', icon: 'news2', submenu: [
                { name: 'Рубрики', route: '/news/categories', allowedGroups: ['Admin'] },
                { name: 'Подрубрики', route: '/news/subcategories', allowedGroups: ['Admin'] },
            ]
        },
        {
            name: 'Агентские аккаунты', route: '/agency-accounts', icon: 'agency-accounts2', deniedGroups: ['Support Design', 'Support Proxy'], submenu: [
                { name: 'История запросов', route: '/agency-accounts/history' },
                { name: 'Отзывы', route: '/agency-accounts/reviews', allowedGroups: ['Admin'] },
            ]
        },
        {
            name: 'Магазин', route: '/shop', icon: 'wallet2', allowedGroups: ['Admin', 'Buyer'], submenu: [
                { name: 'Журнал операций с аккаунтами', route: '/shop/accounts-log', allowedGroups: ['Admin', 'Manager Farm'] },
                { name: 'Бухгалтерия', route: '/shop/accounting', allowedGroups: ['Admin', 'Manager Farm'] },
                { name: 'Покупки', route: '/shop/purchases', allowedGroups: ['Buyer'] }
            ]
        },
        { name: 'Креативы', route: '/designs', icon: 'design2', allowedGroups: ['Buyer', 'Support Design'] },
        {
            name: 'Управление', icon: 'gear2', allowedGroups: ['Admin', 'Support Proxy'], submenu: [
                { name: 'Учетные записи', route: '/settings/users', allowedGroups: ['Admin'] },
                { name: 'Команды', route: '/settings/teams', allowedGroups: ['Admin'] },
                { name: 'Категории', route: '/settings/categories', allowedGroups: ['Admin'] },
                { name: 'Глобальные категории', route: '/settings/globalcategories', allowedGroups: ['Admin'] },
                { name: 'Операторы', route: '/settings/operators', allowedGroups: ['Admin', 'Support Proxy'] },
                { name: 'Селлеры', route: '/settings/sellers', allowedGroups: ['Admin', 'Support Proxy'] },
                { name: 'Типы аккаунтов', route: '/settings/account-types', allowedGroups: ['Admin', 'Manager Farm'] },
                { name: 'Темы тех. запросов', route: '/settings/tech-topics', allowedGroups: ['Admin', 'Manager Technical'] },
                { name: 'Гео', route: '/settings/geos', allowedGroups: ['Admin', 'Support Proxy'] },
                { name: 'Агентства', route: '/settings/agencies', allowedGroups: ['Admin'] }
            ]
        }

        // { name: 'Статистика', route: '/stat/', icon: 'stat' }
    ].filter(isHaveAccess)
    // .filter(hostSpecific)

    ///////////////////////////////////////////////////////////////////////////////////
    const showSidebar = useCallback(() => setSidebarVisible(true), [setSidebarVisible])


    /////////
    return <>

        <aside
            className={ `sidebar ${isSidebarVisible ? 'sidebar-visible' : ''}` }
            onClick={ showSidebar }
        >

            <nav className="sidebar-nav">

                { menuItems.map((item, index) => {

                    if (item.submenu) {
                        item.submenu = item.submenu.filter(isHaveAccess)
                    }

                    let to = item.route

                    if (!to && item.submenu) {
                        to = item.submenu[0].route
                    }

                    const attributes = {
                        className: ({ isActive }) => (
                            (
                                item.submenu
                                && item.submenu.some(subItem => subItem.route === location.pathname)
                            )
                            || (
                                item.route
                                && (
                                    isActive
                                    || (
                                        location.pathname === item.route
                                    )
                                )
                            )
                        )
                            ? 'sidebar-nav-item sidebar-nav-item-active'
                            : 'sidebar-nav-item',
                        to: to,
                        end: item.route === '/' ? true : '',
                        title: item.name
                    }

                    return <MenuItem
                        key={ index }
                        item={ item }
                        attributes={ attributes }
                        location={ location }
                        hideSidebar={ hideSidebar }
                        isSidebarVisible={ isSidebarVisible }
                    />

                }) }

                <div className="sidebar-divider" />
            </nav>

            <nav className="sidebar-nav">

                <NavLink
                    data-tooltip-id={ !isSidebarVisible && "nav-tooltip" }
                    data-tooltip-content="Регламент"
                    className={
                        ({ isActive }) => isActive ? 'sidebar-nav-item sidebar-nav-item-active' : 'sidebar-nav-item'
                    }
                    to="/reglament/"
                    title="Регламент"
                >
                    <Icon name="info2" />
                    <label>Регламент</label>
                </NavLink>

                <NavLink
                    data-tooltip-id={ !isSidebarVisible && "nav-tooltip" }
                    data-tooltip-content="Выйти"
                    className="sidebar-nav-item sidebar-nav-item-logout"
                    to="/"
                    onClick={ auth.logout }
                    title="Выйти"
                >
                    <Icon name="logout2" />
                    <label>Выйти</label>
                </NavLink>

            </nav>

            {/* <div className="sidebar-version">2.46 beta</div> */ }

        </aside>

        <div
            className={ `sidebar-shade ${isSidebarVisible ? 'sidebar-visible' : ''}` }
            onClick={ hideSidebar }
        ></div>

    </>


})


////////////////////////////////
const isHaveAccess = (page) => {

    return (
        (
            (!page.allowedGroups || matchGroups(page.allowedGroups))
            && (!page.deniedGroups || !matchGroups(page.deniedGroups))
        ) || matchGroups(['Admin'])
    )

}


////////////////////////////////
const hostSpecific = (page) => {

    // show designs only on 3000, 4000 ports
    if (page.route === '/designs' && !['3000', '4000'].includes(window.location.port)) return false
    return true

}