import { PayloadAction, createSlice, createAsyncThunk, Action } from '@reduxjs/toolkit'
import { getNews } from '../thunks/newsThunks'

interface State {
  news: any
  categories: any
  groups: any
  subcategories: any
  teams: any
  webs: any
  newsCount: number
  sortState: [string, boolean]
  loading: boolean
  error: any
}

const initialState: State = {
  news: [],
  categories: [],
  groups: [],
  subcategories: [],
  teams: [],
  webs: [],
  newsCount: 0,
  sortState: ['createdAt', false],
  loading: false,
  error: null
}

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setSortState: (state, action) => {
      state.sortState = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNews.fulfilled, (state, action) => {
        state.loading = false
        state.news = action.payload.news
        state.categories = action.payload.categories
        state.groups = action.payload.groups
        state.subcategories = action.payload.subcategories
        state.teams = action.payload.teams
        state.webs = action.payload.webs
        state.newsCount = action.payload.newsCount
      })

    //Дефолтный кейс для всех пендинг и реджектет
    builder.addMatcher(
      (action: Action) => {
        return action.type.endsWith('/pending') || action.type.endsWith('/rejected')
      },
      (state, action: PayloadAction<any>) => {
        state.loading = action.type.endsWith('/pending')
        state.error = action.type.endsWith('/rejected') ? action.payload : null
      }
    )

  }
})

export const { setSortState } = newsSlice.actions

export default newsSlice.reducer
