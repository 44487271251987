/**
 * Input Component
 * 
 * This component renders an input field with debounced local storage saving. It supports various input types and handles value changes.
 * 
 * @component
 * @example
 * ```jsx
 * <Input
 *   name="inputName"
 *   value={inputValue}
 *   onChange={handleInputChange}
 *   formClassName="formClass"
 *   isInvalid={isInvalid}
 *   placeholder="Enter text here..."
 * />
 * ```
 * 
 * @param {Object} props - Component properties
 * @param {string} props.name - The name attribute for the input element
 * @param {string} [props.value] - The initial value of the input field
 * @param {Function} props.onChange - The function to call when the input value changes
 * @param {string} props.formClassName - The class name of the form containing the input
 * @param {boolean} [props.isInvalid] - Flag indicating if the input is in an invalid state
 * @param {string} [props.placeholder] - The placeholder text for the input field
 * 
 * @returns {JSX.Element} The rendered input component
 */


import { memo, useEffect, useMemo, useState, forwardRef } from 'react'
import { useDebounce } from '../../hooks/debounce.hook'


//////////////////////////////////////////////////////
export const Input = memo(forwardRef((props, ref) => {


    /////////////////////////////////////////////////////
    const [value, setValue] = useState(props.value || '')
    const localStorageKey = `input-value-${props.name}-${props.formClassName}`
    const { isInvalid, ...restProps } = props || {}


    /////////////////
    useEffect(() => {
        setValue(props.value || '')
    }, [props.value])


    ////////////////////////////////////////////
    const debouncedSave = useDebounce(value => {
        if (props.name?.includes('password')) return
        localStorage.setItem(localStorageKey, value)
    }, 500)


    /////////////////
    useEffect(() => {

        const savedValue = localStorage.getItem(localStorageKey)

        if (savedValue) {
            setValue(savedValue)
            if (props.onChange && props.name) {
                props.onChange({
                    target: {
                        name: props.name,
                        value: savedValue
                    }
                })
            }
        }

    }, [localStorageKey])


    /////////////////
    useEffect(() => {
        if (
            value?.trim
            && value?.trim()?.length
            && props?.formClassName
            && (!('store' in props) || props.store)
        ) {
            debouncedSave(value)
        } else {
            localStorage.removeItem(localStorageKey)
        }
    }, [value, localStorageKey])


    ///////////////////////////////////
    const attributes = useMemo(() => ({
        ...restProps,
        onChange: event => {
            if (props.onChange) {
                props.onChange(event)
            }
            setValue(event.target.value)
        },
        value,
        ...('isInvalid' in props && { 'data-is-invalid': props.isInvalid }),
        autoComplete: "off"
    }), [props, value])


    ///////////////////////////////
    delete attributes.formClassName
    delete attributes.store


    //////////////////////////////////////////////
    return <input { ...attributes } ref={ ref } />


}))