import { useMemo } from 'react'
import { useTicketModalConstructor } from './ticket.modal.constructor'

export const useShowConsumablesDesign = (modalFn: any) => {


    ///////////////////////////////////////////
    const ticketModalButtons = useMemo(() => [{
        name: 'ugc',
        label: 'UGC',
        action: 'showDesignUgc'
    }, {
        name: 'static',
        label: 'Статика',
        action: 'showDesignStatic'
    }, {
        name: 'video',
        label: 'Видео',
        action: 'showDesignVideo'
    }], [])


    //////////////////////////////////
    return useTicketModalConstructor({
        title: 'Новый тикет',
        name: 'consumables-design',
        ticketModalButtons,
        breadcrumbs: [
            ['Расходники', modalFn.showConsumables],
            ['Creative']
        ],
        modalFn
    })


}
