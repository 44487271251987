import { useContext } from 'react'
import { AppContext } from '../../context/AppContext'
import { Icon } from '../Icon/Icon'

//////////////////
const themeMap = {
    light: 'dark',
    dark: 'light',
    undefined: 'light',
    'undefined': 'light'
}

/**
 * Renders a button that toggles the theme between light and dark.
 * @returns {JSX.Element} The rendered button component.
 */

export const TopThemeSwitcher = () => {


    //////////////////////////////////
    const app = useContext(AppContext)
    const iconName = app.theme === 'light' ? 'moon' : 'sun'


    ///////////////////////////
    const toggleTheme = () => {

        const newTheme = themeMap[app.theme]
        app.setTheme(newTheme)
        localStorage.setItem('theme', newTheme)

    }


    //////////////////////////////////////////////////////////////////////////////
    return (<button className={ "topbar-item-switcher" } onClick={ toggleTheme }>
        <Icon name={ iconName } />
    </button>)


}