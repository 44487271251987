import { useMemo } from 'react'
import { useTicketModalConstructor } from './ticket.modal.constructor'

export const useShowConsumablesGoogle = (modalFn: any) => {


    ///////////////////////////////////////////
    const ticketModalButtons = useMemo(() => [{
        name: 'purchased-farms',
        label: 'Покупные фармы',
        action: 'showGooglePurchasedFarm'
    }, {
        name: 'td-farm',
        label: 'ТД фарм',
        action: 'showGoogleTdFarm'
    }, {
        name: 'other',
        label: 'Другое',
        action: 'showGoogleOther'
    }, {
        name: 'proxy',
        label: 'Прокси',
        action: 'showGoogleProxy'
    }, {
        name: 'consumable',
        label: 'Расходник',
        action: 'showGoogleConsumable'
    },], [])


    //////////////////////////////////
    return useTicketModalConstructor({
        title: 'Новый тикет',
        name: 'consumables-google',
        ticketModalButtons,
        breadcrumbs: [
            ['Расходники', modalFn.showConsumables],
            ['Google']
        ],
        modalFn
    })


}
