import './Loader.scss'

/**
 * Renders a loader component.
 * @param {Object} props - The component props.
 * @param {boolean} props.isVisible - Determines whether the loader is visible or not.
 * @returns {JSX.Element} The loader component.
 */

export const Loader = ({ isVisible }) => {

    return <div className={ 'loader' + (isVisible ? ' loader-visible' : '') }>
        <div className="loader-outer">
            <div className="loader-inner"></div>
        </div>
        <div className="loader-text"></div>
    </div>

}