import { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { MatchGroups } from '../../components/MatchGroups'
import { Radio } from '../../components/Radio/Radio'
import { AppContext } from '../../context/AppContext'
import { AuthContext } from '../../context/AuthContext'
import { useApiRequest } from '../../hooks/api.request.hook'
import { matchGroups } from '../../js/lib'
import './OverviewCharts.light.scss'
import { OverviewChartsPeriodic } from './OverviewChartsPeriodic'
import { OverviewChartsPeriodicStat } from './OverviewChartsPeriodicStat'
import { OverviewChartsPorts } from './OverviewChartsPorts'
import { OverviewChartsToday } from './OverviewChartsToday'
import { OverviewChartsTypical } from './OverviewChartsTypical'

export const OverviewCharts = () => {


    //////////////////////////////////////////////
    const [chartData, setChartData] = useState({})
    const [chartPeriod, setChartPeriod] = useState('day')
    const auth = useContext(AuthContext)
    const app = useContext(AppContext)
    const apiRequest = useApiRequest()

    let title = ''
    let titleLink = ''

    if (matchGroups(['Support Farm'])) { title = 'Статистика' }
    else if (matchGroups(['Support Proxy'])) { title = 'Выполненные' }
    else { title = 'Расходы'; titleLink = '/costs' }


    /////////////////////////////////////
    const updateChartData = async () => {

        const apiUrl = matchGroups(['Support Farm', 'Manager Farm', 'Support Proxy'])
            ? `/api/stat?period=${chartPeriod}`
            : `/api/costs?mode=charts&period=${chartPeriod}`

        await apiRequest({
            get: apiUrl,
            '200': response => setChartData(response),
            auth
        })

    }


    /////////////////////////////////////////////////////
    useEffect(() => { updateChartData() }, [chartPeriod])


    /////////////////////////////////////////
    return (<div className="overview-charts">

        <div className="overview-header">

            <NavLink className="overview-title" to={ titleLink }>
                { title }
            </NavLink>

            <div className="overview-header-filters">
                <Radio
                    name="chartPeriod"
                    value="day"
                    label="День"
                    checked={ chartPeriod === 'day' }
                    onChange={ () => setChartPeriod('day') }
                />
                <Radio
                    name="chartPeriod"
                    value="week"
                    label="Неделя"
                    checked={ chartPeriod === 'week' }
                    onChange={ () => setChartPeriod('week') }
                />
                <Radio
                    name="chartPeriod"
                    value="month"
                    label="Месяц"
                    checked={ chartPeriod === 'month' }
                    onChange={ () => setChartPeriod('month') }
                />
            </div>

        </div>

        {
            chartData.periodic && !matchGroups(['Support Farm'])
                ? <>

                    <MatchGroups allowed={ ['Manager Farm', 'Support Proxy'] }>
                        <OverviewChartsPeriodicStat chartPeriod={ chartPeriod } list={ chartData.periodic } />
                    </MatchGroups>

                    <MatchGroups denied={ ['Support Farm', 'Manager Farm', 'Support Proxy'] }>
                        <OverviewChartsPeriodic chartPeriod={ chartPeriod } list={ chartData.periodic } />
                    </MatchGroups>

                </>
                : ''
        }

        { chartData.typical ? <OverviewChartsTypical list={ chartData.typical } /> : '' }
        { chartData.todayMsSpent ? <OverviewChartsToday data={ chartData } /> : '' }
        { chartData.ports ? <OverviewChartsPorts data={ chartData.ports } /> : '' }

    </div>)


}