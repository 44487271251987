import { memo } from 'react'

/**
 * A component that renders a progress ring.
 * @component
 * @param {number} size - The size of the progress ring in pixels.
 * @param {number} current - The current value of the progress.
 * @param {number} max - The maximum value of the progress.
 * @param {number} strokeWidth - The width of the progress ring's stroke.
 * @returns {JSX.Element} The rendered progress ring.
 */

export const ProgressRing = memo(({ size = 25, current, max, strokeWidth = 3 }) => {

    const radius = (size - strokeWidth) / 2
    const circumference = 2 * Math.PI * radius
    const offset = -((max - current) / max) * circumference

    return <svg width={ size } height={ size }>

        <g transform={ `rotate(-90 ${size / 2} ${size / 2})` }>

            <circle
                stroke="red"
                fill="transparent"
                strokeWidth={ strokeWidth }
                strokeDasharray={ circumference + ' ' + circumference }
                style={ { strokeDashoffset: offset, transition: 'stroke-dashoffset 1s linear' } }
                r={ radius }
                cx={ size / 2 }
                cy={ size / 2 }
            />

            <text
                x="50%"
                y="50%"
                textAnchor="middle"
                stroke="black"
                strokeWidth="0.5px"
                dy=".35em"
                transform={ `rotate(90 ${size / 2} ${size / 2})` }
            >
                { current }
            </text>

        </g>

    </svg>

})