import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ContextMenu } from '../components/ContextMenu/ContextMenu'
import { InfoPopup } from '../components/InfoPopup/InfoPopup'
import { Viewer } from '../components/Viewer/Viewer'
import { updateStateArray } from '../js/lib'
import { AuthContext } from './AuthContext'
import { SocketContext } from './SocketContext'


/////////////////////////////////////////
export const AppContext = createContext()


////////////////////////////////////////////////////////////////
export const AppContextProvider = ({ setLoader, children }) => {


    ////////////////////////////////////////
    const socket = useContext(SocketContext)
    const auth = useContext(AuthContext)
    const currentTheme = localStorage.getItem('theme') || process.env.REACT_APP_DEFAULT_THEME
    const [theme, setTheme] = useState(currentTheme)
    const [infoPopupState, setInfoPopup] = useState(null)
    const [categories, setCategories] = useState(null)
    const [operators, setOperators] = useState(null)
    const [sellers, setSellers] = useState(null)
    const [geos, setGeos] = useState(null)
    const [requestTemplates, setRequestTemplates] = useState(null)
    const [agencies, setAgencies] = useState(null)
    const [languages, setLanguages] = useState(null)
    const [accountTypes, setAccountTypes] = useState(null)
    const [techTopics, setTechTopics] = useState(null)
    const [techDepartments, setTechDepartments] = useState(null)
    const [proxies, setProxies] = useState(null)
    const [contextMenu, setContextMenu] = useState(null)
    const [selectedSocial, setSelectedSocial] = useState('facebook')
    const [visibleCat, setVisibleCat] = useState(null)
    const [isUploadingActive, setUploadingActive] = useState(false)
    const [viewerState, setViewer] = useState(false)
    const [maintenanceMode, setMaintenanceMode] = useState(false)
    const [users, setUsers] = useState([])
    const [teams, setTeams] = useState([])
    const [modalFn, setModalFn] = useState(null)


    /////////////////
    useEffect(() => {

        if (socket && auth?.isAuth) {
            socket.emit('get common lists')
        }

    }, [socket?.connected, auth?.isAuth])


    /////////////////////////////////////////////////////////////////
    useEffect(() => { document.body.dataset.theme = theme }, [theme])


    /////////////////////////////////////////////////
    const updateCommonLists = useCallback(update => {

        auth.updateAccessToken(update)
        if (!update || !auth?.isAuth) return

        const listNames = [
            'users',
            'teams',
            'categories',
            'operators',
            'sellers',
            'geos',
            'requestTemplates',
            'agencies',
            'languages',
            'accountTypes',
            'techTopics',
            'techDepartments',
            'proxies'
        ]

        listNames.forEach(listName => {

            if (!update[listName]) return

            const listNameCapitalized = listName.charAt(0).toUpperCase() + listName.slice(1)
            const setState = eval(`set${listNameCapitalized}`)

            updateStateArray({
                setState,
                updatedArray: update[listName],
                resetList: update.resetList
            })

            const isActiveFlagExists = update[listName].some(item =>
                'isActive' in item
            )

            if (isActiveFlagExists) {
                setState(prevState => prevState.filter(item => item.isActive))
            }

        })

    }, [auth?.isAuth])


    /////////////////////////////////////////////////
    const updateCurrentUser = useCallback(update => {

        auth.setData(prevState => {

            const newData = { ...prevState, ...update }
            localStorage.setItem('authData', JSON.stringify(newData))
            return newData

        })

    }, [auth])


    /////////////////
    useEffect(() => {

        if (socket) {

            socket.off('common lists update', updateCommonLists)
            socket.on('common lists update', updateCommonLists)
            socket.off('maintenance mode', setMaintenanceMode)
            socket.on('maintenance mode', setMaintenanceMode)
            socket.off('current user update', updateCurrentUser)
            socket.on('current user update', updateCurrentUser)

            return () => {
                socket.off('common lists update', updateCommonLists)
                socket.off('maintenance mode', setMaintenanceMode)
                socket.off('current user update', updateCurrentUser)
            }

        }

    }, [socket, updateCommonLists, updateCurrentUser])


    //////////////////////////////////////////////////
    const [formSections, setFormSections] = useState({
        autoregs: 1,
        proxy: 1,
        consumable: 1,
        cards: 1,
        logs: 1,
        samofarms: 1,
        bm: 1,
        fanpage: 1,
        other: 1,
        agencyNew: 1,
        agencyReplace: 1,
        agencyReport: 1,
        designUgc: 1,
        // designStatic: 1, // there is only one design form with internal category selector now
        // designVideo: 1
        googleTdFarm: 1,
        googlePurchasedFarm: 1,
        tech: 1
    })


    /////////////////////////////////////
    const contextValue = useMemo(() => ({
        requestStatuses: {
            new: 'Не начато',
            inWork: 'Выполняется',
            partial: 'Частично выполнен',
            approving: 'На утверждении',
            paused: 'Пауза',
            closed: 'Закрыт',
            archived: 'Завершен'
        },
        limitArr: [
            { value: 10, label: '10' },
            { value: 15, label: '15' },
            { value: 30, label: '30' },
            { value: 50, label: '50' },
            { value: 100, label: '100' },
            { value: 500, label: '500' }
        ],
        setLoader,
        setInfoPopup,
        categories,
        operators,
        sellers,
        geos,
        requestTemplates,
        agencies,
        languages,
        accountTypes,
        techTopics,
        techDepartments,
        setContextMenu,
        selectedSocial,
        setSelectedSocial,
        visibleCat,
        setVisibleCat,
        isUploadingActive,
        setUploadingActive,
        formSections,
        setFormSections,
        theme,
        setTheme,
        viewerState,
        setViewer,
        proxies,
        maintenanceMode,
        users,
        setUsers,
        teams,
        setTeams,
        modalFn,
        setModalFn
    }), [
        // setLoader,
        // setInfoPopup,
        categories,
        operators,
        sellers,
        geos,
        requestTemplates,
        agencies,
        languages,
        accountTypes,
        techTopics,
        techDepartments,
        // setContextMenu,
        selectedSocial,
        // setSelectedSocial,
        visibleCat,
        // setVisibleCat,
        isUploadingActive,
        // setUploadingActive,
        formSections,
        // setFormSections,
        theme,
        viewerState,
        proxies,
        maintenanceMode,
        users,
        teams,
        modalFn
    ])


    ///////////////////////////////////////////////////
    return <AppContext.Provider value={ contextValue }>

        { children }

        { infoPopupState ? <InfoPopup state={ infoPopupState } setState={ setInfoPopup } /> : '' }
        <ContextMenu state={ contextMenu } setState={ setContextMenu } />
        <Viewer state={ viewerState } setState={ setViewer } />

    </AppContext.Provider>


}
