import { ArcElement, Chart as ChartJS, Tooltip } from 'chart.js'
import { useContext } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { AppContext } from '../../context/AppContext'

ChartJS.register(ArcElement, Tooltip)

export const OverviewChartsTypical = ({ list }) => {


    //////////////////////////////////
    const app = useContext(AppContext)


    ////////////////
    const colors = [
        '#EC0011',
        'rgba(255, 0, 18, 0.6)',
        'rgba(255, 0, 18, 0.4)',
        'rgba(255, 3, 21, 0.25)',
        'rgba(255, 2, 20, 0.15)'
    ]


    //////////////
    const data = {
        labels: list.map(item =>
            app.categories?.find(catItem => catItem.alias === item._id)?.name
        ),
        datasets: [
            {
                label: '# of Votes',
                data: list.map(item => item.sum),
                backgroundColor: colors,
                borderWidth: 5,
                borderRadius: 10,
                borderColor: app.theme.includes('dark') ? '#262626' : '#fff',
                hoverBorderColor: app.theme.includes('dark') ? '#262626' : '#fff',
                hoverBorderWidth: 3,
                borderAlign: 'outer',
                cutout: 75
            },
        ],
    }


    /////////////////
    const options = {
        plugins: {
            legend: {
                display: false
            }
        }
    }


    const totalSum = list.reduce((total, item) => item.sum + total, 0)


    /////////////////////////////////////////////////
    return (<div className="overview-charts-typical">

        <div className="overview-charts-typical-donut">

            <div className="overview-charts-typical-donut-container">

                <Doughnut
                    options={ options }
                    data={ data }
                />

                { totalSum ? <div className="overview-charts-typical-total">{ totalSum.toFixed(2) }$</div> : '' }

            </div>


        </div>
        <div className="overview-charts-typical-details">
            { list.map((item, index) => {

                // const category = app.categories.find(catItem => catItem.alias === item._id)?.name

                return (
                    <div key={ index } className="overview-charts-typical-details-item">
                        <div className="overview-charts-typical-details-dot" style={ { backgroundColor: colors[index] } }></div>
                        <div className="overview-charts-typical-details-category">{ item._id }</div>
                        <div className="overview-charts-typical-details-sum">{ item.sum?.toFixed(2) }$</div>
                    </div>
                )

            }) }
        </div>

    </div>)

}