/**
 * Dropdown Component
 * 
 * This component renders a dropdown menu that can be toggled by a button. The dropdown content is passed as a prop.
 * 
 * @component
 * @example
 * ```jsx
 * <Dropdown
 *   content={<div>Dropdown Content</div>}
 *   isVisible={true}
 *   className="optional-class-name"
 *   button={toggleButtonDomNode} // or "selector"
 * />
 * ```
 * 
 * @param {Object} props - Component properties
 * @param {JSX.Element} props.content - The content to be displayed inside the dropdown
 * @param {boolean} props.isVisible - Flag indicating if the dropdown is visible
 * @param {string} [props.className] - Optional additional class names for the dropdown
 * @param {HTMLElement|string} props.button - The button DOM node or selector that toggles the dropdown
 * 
 * @returns {JSX.Element} The rendered dropdown component
 */


import { useEffect, useRef } from 'react'
import './Dropdown.light.scss'


////////////////////////////////////////////////////////////////////////
export const Dropdown = ({ content, isVisible, className, button }) => {


    ////////////////////////////////
    const dropdownRef = useRef(null)


    /////////////////
    useEffect(() => {

        if (button) {
            placeDropdown(dropdownRef.current, button)
        }

    }, [isVisible, button])


    ///////////
    return <div
        className={ `dropdown ${isVisible ? 'dropdown-visible' : ''} ${className}` }
        ref={ dropdownRef }
    >
        <div className="dropdown-arrow"></div>
        <div className="dropdown-content">{ content }</div>
    </div>


}


////////////////////////////////////////////////////
export const placeDropdown = (dropdown, target) => {

    // dropdown: DOMNode | selector
    // target: DOMNode | selector

    if (typeof dropdown === 'string') {
        dropdown = document.querySelector(dropdown)
    }

    if (typeof target === 'string') {
        target = document.querySelector(target)
    }

    const targetRect = target.getBoundingClientRect()
    const targetParentRect = target.offsetParent.getBoundingClientRect()
    const dropdownRect = dropdown.getBoundingClientRect()

    let newDropdownLeft = targetRect.right - targetParentRect.left - dropdownRect.width + 6
    const arrow = dropdown.querySelector('div.dropdown-arrow')

    if (newDropdownLeft < 0) {

        const arrowRect = arrow.getBoundingClientRect()
        const arrowLeft = targetRect.left + (targetRect.width / 2) - (arrowRect.width / 2) + 4
        arrow.style.left = arrowLeft + 'px'
        arrow.style.right = 'auto'
        newDropdownLeft = 0

    } else {

        arrow.removeAttribute('style')

    }

    dropdown.style.top = targetRect.top + targetRect.height + 'px'
    dropdown.style.left = newDropdownLeft + 'px'

}