import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
// import { BrowserRouter } from 'react-router-dom'
// import * as Sentry from '@sentry/react'

// Sentry.init({
//     dsn: "https://f99032235d2d40d2ac45288839ea52a1@o4505305847365632.ingest.sentry.io/4505305852674048"
// })

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    // <React.StrictMode>
    // <BrowserRouter>
    <App />
    // </BrowserRouter>
    // </React.StrictMode>
)

