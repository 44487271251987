//////////////////////////////////////////////////////////////////////
export const handleInputChange = (event, formState, setFormState) => {

    if (!setFormState) {
        return
    }

    let isEntryFound = false

    const newFormState = formState.map(row => {

        let cells = []

        if (Array.isArray(row)) {
            cells = row
        } else {
            cells = [row]
        }

        cells.forEach(cell => {

            let cellItems = []

            if (cell.items) {
                cellItems = cell.items
            } else {
                cellItems = [cell]
            }

            cellItems.forEach(item => {

                if (item.name !== event.target.name) return

                if (event.target.type === 'checkbox' && item.label) {

                    // single checkbox

                    item.checked = event.target.checked

                    item.value =
                        item.checked
                            ? item.useExclude
                                ? item.checked
                                : event.target.value
                            : ''

                } else if (event.target.type === 'checkbox' && item.options) {

                    // button-like checkbox list

                    const clickedOption = item.options.find(option =>
                        option.value === event.target.value
                    )

                    if (clickedOption) {
                        clickedOption.checked = event.target.checked
                    }

                    if (!event.target.checked) delete clickedOption.excluded

                } else if (event.target.type === 'radio' && event.target.checked) {

                    if (item.options) {
                        item.options.forEach(option => option.checked = option.value === event.target.value)
                    } else {
                        item.value = event.target.value
                    }

                } else if (item.type === 'uploader') {

                    item.value = event.target.files

                } else if (item.type) {

                    item.value = event.target.value

                }

                isEntryFound = true

            })

        })

        return row

    })

    if (!isEntryFound) {
        formState.push({
            name: event.target.name,
            value: event.target.value
        })
    }

    setFormState(newFormState)

}