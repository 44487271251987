import { everyFormItem } from './Form'

export const resetForm = (setFormState, formSelector) => {

    setFormState(prevState =>

        everyFormItem(prevState, item => {

            if (['text', 'password', 'textarea', 'quantity'].includes(item.type)) {

                if ('defaultValue' in item) {
                    item.value = item.defaultValue
                } else {
                    delete item.value
                }

                for (let i = 0; i < localStorage.length; i++) {
                    const key = localStorage.key(i)
                    if (key.includes(item.name)) {
                        localStorage.removeItem(key)
                        i--
                    }
                }

            } else if (['select', 'autocomplete'].includes(item.type)) {

                if ('defaultIndex' in item) {
                    item.selectedIndex = item.defaultIndex
                } else if ('defaultValue' in item) {
                    item.value = item.defaultValue
                } else {
                    delete item.selectedIndex
                    delete item.value
                }

            } else if (item.type === 'radio') {

                item.options.forEach(option => {

                    if (option.value === item.defaultValue) {
                        option.checked = true
                    } else {
                        delete option.checked
                    }

                })

            } else if (item.type === 'uploader' && item.name) {

                const input = document.querySelector(`input[name="${item.name}"]`)
                const label = document.querySelector(`label[data-name="${item.name}"]`)

                if (input) {
                    input.value = ''
                    input.files = null
                }

                if (label) {
                    label.innerText = label.dataset.default
                }

                item.selectedFilesDefault = []

            } else if ('defaultValue' in item) {

                item.value = item.defaultValue

            } else if (item.type === 'validationError') {

                item.hidden = true

            }

            if ('defaultHidden' in item) {
                item.hidden = item.defaultHidden
            } else {
                delete item.hidden
            }

            if ('defaultCollapsed' in item) {
                item.collapsed = item.defaultCollapsed
            } else {
                delete item.collapsed
            }

            if ('defaultChecked' in item) {
                item.checked = item.defaultChecked
            } else {
                delete item.checked
            }

            delete item.isInvalid
            return item

        })

    )

    if (formSelector) {

        const formRows = document.querySelectorAll(`${formSelector} div.form-row[data-section]`)

        formRows.forEach(row => {
            row.classList.toggle('form-row-collapsed', +row.dataset.section)
            row.classList.toggle('form-row-absolute', +row.dataset.section)
        })

    }

}