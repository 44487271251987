import { memo, useCallback, useContext, useMemo, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import { getHash, hashFields } from '../../js/lib'
import { Icon } from '../Icon/Icon'
import { Select } from '../Select/Select'
import './Pagination.light.scss'

/**
 * Pagination component for navigating through a list of items.
 *
 * @component
 * @param {number} count - The total count of items.
 * @param {number} limit - The number of items to display per page.
 * @param {function} setLimit - A function to set the limit of items per page.
 * @param {string} basePath - The base path for the pagination links.
 * @returns {JSX.Element} The rendered Pagination component.
 */

//////////////////////////////////
export const Pagination = memo(({
    count = 0,
    limit = process.env.REACT_APP_DEFAULT_PAGE_LIMIT,
    setLimit,
    basePath
}) => {


    ////////////////////////////////////////////
    const [goToPage, setGoToPage] = useState('')
    const totalPages = Math.ceil(count / limit)
    const navigate = useNavigate()
    const app = useContext(AppContext)
    const curPage = +getHash('page') || 1


    ////////////////////////////////////
    const currentLimit = useMemo(() => {

        if (!limit) return

        return app.limitArr.findIndex(item => item.value === +limit)

    }, [app.limitArr, limit])


    /////////////////////////
    const maxVisiblePages = 3
    let pagesStart = Math.max(1, curPage - Math.floor(maxVisiblePages / 2))
    let pagesEnd = Math.min(totalPages, pagesStart + maxVisiblePages - 1)


    ///////////////////////////////////////
    const handleClick = useCallback((pageNum) => {
        if (+curPage !== pageNum) app.setLoader(true)
    }, [curPage, app])


    /////////////////////////////////////////////
    const renderPageNumbers = useCallback(() => {

        const pageNumbers = []

        for (let pageNum = pagesStart; pageNum <= pagesEnd; pageNum++) {

            pageNumbers.push(
                <NavLink
                    key={ pageNum }
                    to={ (basePath || '') + hashFields({ page: pageNum > 1 ? pageNum : '' }) }
                    className={ pageNum === curPage ? 'pagination-item pagination-item-active' : 'pagination-item' }
                    onClick={ () => handleClick(pageNum) }
                >
                    { pageNum }
                </NavLink>
            )

        }

        return pageNumbers

    }, [pagesStart, pagesEnd, curPage, app, basePath, handleClick])


    //////////////////////////////////////////////////////
    const renderEllipsisAndLastPages = useCallback(() => {

        const ellipsisAndLastPages = []

        if (pagesEnd < totalPages - 2) {

            ellipsisAndLastPages.push(
                <div key="ellipsis-end" className="pagination-ellipsis">...</div>
            )

            for (let pageNum = totalPages - 1; pageNum <= totalPages; pageNum++) {
                ellipsisAndLastPages.push(
                    <NavLink
                        key={ pageNum }
                        to={ (basePath || '') + hashFields({ page: pageNum }) }
                        className="pagination-item pagination-item-last"
                        onClick={ () => handleClick(pageNum) }
                    >
                        { pageNum }
                    </NavLink>
                )
            }

        } else if (pagesEnd < totalPages) {

            ellipsisAndLastPages.push(
                <NavLink
                    key={ totalPages }
                    to={ (basePath || '') + hashFields({ page: totalPages }) }
                    className="pagination-item pagination-item-last"
                    onClick={ () => handleClick(totalPages) }
                >
                    { totalPages }
                </NavLink>
            )

        }

        return ellipsisAndLastPages

    }, [pagesEnd, totalPages, basePath, handleClick])


    ///////////////////////////////////////////
    const handlePrevClick = useCallback(() => {
        const prevPage = curPage > 1 ? curPage - 1 : 1
        navigate((basePath || '') + hashFields({ page: prevPage }))
        app.setLoader(true)
    }, [curPage, navigate, app, basePath])


    ///////////////////////////////////////////
    const handleNextClick = useCallback(() => {
        const nextPage = curPage < totalPages ? curPage + 1 : totalPages
        navigate((basePath || '') + hashFields({ page: nextPage }))
        app.setLoader(true)
    }, [curPage, totalPages, navigate, app, basePath])


    ///////////////////////////////////////////////
    const handleGoToPageChange = useCallback(e => {

        const value = e.target.value

        if (/^\d*$/.test(value)) {
            setGoToPage(value)
        }

    }, [setGoToPage])


    ////////////////////////////////////////////////
    const handleGoToPageSubmit = useCallback(() => {

        const pageNum = Math.min(Math.max(1, parseInt(goToPage, 10)), totalPages)
        navigate((basePath || '') + hashFields({ page: pageNum > 1 ? pageNum : '' }))
        app.setLoader(true)
        setGoToPage('')

    }, [hashFields, navigate, app, setGoToPage, totalPages, goToPage, basePath])


    /////////////////////////////////////////
    const handleKeyPress = useCallback(e => {

        if (e.key === 'Enter') {
            handleGoToPageSubmit()
        }

    }, [handleGoToPageSubmit])


    ////////////////////////////////////////////
    const handleChangeLimit = useCallback(e => {

        if (!e.target.value) return
        const newLimit = +e.target.value
        setLimit(newLimit)

    }, [setLimit])


    ///////////////////////////////////////////
    return <div className="pagination-wrapper">

        <div className="pagination-limit">
            <Select
                source={ app.limitArr }
                selectedIndex={ currentLimit }
                onChange={ handleChangeLimit }
            />
            <span>Строк на странице</span>
        </div>

        <div className="pagination">
            <button disabled={ curPage === 1 } onClick={ handlePrevClick } className="pagination-button">
                <Icon name="arrow-long-left" />
                <p>Previous</p>
            </button>

            <div className="pagination-pages">
                { pagesStart > 1 && (
                    <NavLink
                        key={ 1 }
                        to={ (basePath || '') + hashFields({ page: '' }) }
                        className="pagination-item pagination-item-first"
                        onClick={ () => handleClick(1) }
                    >
                        1
                    </NavLink>
                ) }
                { pagesStart > 1 && (
                    <div key="ellipsis-start" className="pagination-ellipsis">...</div>
                ) }
                { renderPageNumbers() }
                { renderEllipsisAndLastPages() }
            </div>

            <button disabled={ curPage === totalPages } onClick={ handleNextClick } className="pagination-button">
                <p>Next</p>
                <Icon name="arrow-long-right" />
            </button>
        </div>

        <div className="pagination-navigate">
            <span>Перейти на страницу</span>
            <div>
                <input
                    value={ goToPage }
                    onChange={ handleGoToPageChange }
                    onKeyDown={ handleKeyPress }
                    inputMode="numeric"
                />
                <Icon name="chevron-right" onClick={ handleGoToPageSubmit } />
            </div>
        </div>

    </div>


})
