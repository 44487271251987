import React, { memo, MouseEventHandler, useState } from 'react'
import Icon from '../Icon/Icon'
import './TicketModalButton.light.scss'


/////////////////////////////////////////
export interface TicketModalButtonProps {
    onClick: MouseEventHandler<HTMLButtonElement>
    icon?: string
    label: string
    size?: 'medium' | 'large'
    selected?: boolean
    name?: string
    disabled?: boolean
}


///////////////////////////////////////
export const TicketModalButton = memo((
    { onClick,
        icon,
        label,
        size,
        selected,
        name,
        disabled = false
    }: TicketModalButtonProps
) => {


    /////////////////////////////////////////////////
    const [isSelected, setSelected] = useState(false)


    //////////////
    return <button
        onClick={ event => {
            setSelected(true)
            onClick && onClick(event)
        } }
        className={ `
            ticket-modal-button
            ${size ? `ticket-modal-button-${size}` : ''}
            ${selected ? 'ticket-modal-button-selected' : ''}
        ` }
        data-name={ name }
        disabled={ disabled }
    >
        { icon && <Icon name={ icon } /> }
        { label }
    </button>


})