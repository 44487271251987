import { forwardRef, memo } from 'react'
import './Radio.light.scss'

/**
 * Radio component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.name - The name of the radio input.
 * @param {string} [props.value] - The value of the radio input.
 * @param {string} props.label - The label for the radio input.
 * @param {string} props.type - The type of the radio input.
 * @param {string} [props.tipTarget] - The target for tooltip.
 * @param {boolean} [props.required=false] - Whether the radio input is required.
 * @param {boolean} [props.checked=false] - Whether the radio input is checked.
 * @param {function} [props.onChange] - The function to be called when the radio input value changes.
 * @param {boolean} [props.isInvalid] - Whether the radio input is invalid.
 * @param {boolean} [props.disabled] - Whether the radio input is disabled.
 * @returns {JSX.Element} The rendered Radio component.
 */

export const Radio = memo(forwardRef(({
    name,
    value,
    label,
    type,
    tipTarget,
    required,
    checked = false,
    onChange,
    isInvalid,
    disabled
}, ref) => {

    const attributes = {
        type: 'radio',
        name,
        checked,
        disabled
    }

    if (value) {
        attributes.value = value
    }

    if (required) {
        attributes['data-required'] = 'radio'
    }

    if (tipTarget) {
        attributes['data-tip-target'] = tipTarget
    }

    if (onChange) {
        attributes.onChange = onChange
    }

    return <label className={ type === "radio-button" ? "radio-button" : "radio" } data-is-invalid={ isInvalid }>
        <input { ...attributes } />
        <div>{ label }</div>
    </label>

}))