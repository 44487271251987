export const cookie = {
    get(name) {
        const cookies = document.cookie.split(';').map(cookie => cookie.trim());
        for (const cookie of cookies) {
            const [cookieName, cookieValue] = cookie.split('=');
            if (cookieName === name) {
                return cookieValue;
            }
        }
        return null;
    },
    set(name, value, options = {}) {
        let cookie = `${name}=${value}`;
        if (options.expires) {
            const expires = new Date(options.expires);
            cookie += `; expires=${expires.toUTCString()}`;
        }
        if (options.path) {
            cookie += `; path=${options.path}`;
        }
        if (options.domain) {
            cookie += `; domain=${options.domain}`;
        }
        // if (options.secure) {
        cookie += `; secure`;
        // }
        cookie += `; SameSite=None`;
        document.cookie = cookie;
    },
    delete(name) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
};