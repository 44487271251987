import React, { useCallback, useContext, useMemo } from 'react'
import { AppContext } from '../../context/AppContext'
import { AuthContext } from '../../context/AuthContext'
import { matchGroups } from '../../js/lib'
import { capitalize } from '../../js/text'
import Icon from '../Icon/Icon'
import { useModal } from '../Modal2/Modal2'


export const useShowTemplates = (modalFn: any) => {


    ///////////////////////////////
    const { setModal } = useModal()
    const app = useContext(AppContext)
    const auth = useContext(AuthContext)
    const isTeamAllowedTech = ['Sem', 'Midas Team', 'Performance'].includes(auth.data.team?.name)
    const isTeamAllowedCreative = ['Sem', 'Dating devils', 'VenceDigital'].includes(auth.data.team?.name)
    const isAdmin = matchGroups(['Admin'])


    ////////////////////////////////////
    const modalContent = useMemo(() => {

        let result

        if (app.requestTemplates?.length) {

            result = app.requestTemplates?.filter((template: any) => {

                // filtered by team

                const isCreativeSection = template.category.alias.startsWith('design')
                const isTechSection = template.category.alias === 'tech'

                return (
                    (isCreativeSection && isTeamAllowedCreative)
                    // || (isTechSection && isTeamAllowedTech)
                    || isAdmin
                    || (!isCreativeSection) // && !isTechSection
                )

            }).map((template: any, templateIndex: number) =>
                <button
                    className="request-templates-item button button-2-small"
                    key={ templateIndex }
                    onClick={ () => {

                        let modalName = 'show'
                        const social = capitalize(template.social)
                        if (!['Design', 'Tech', 'Google'].includes(social)) modalName += social
                        const category = capitalize(template.category.alias)
                        modalName += category
                        modalFn.template = template
                        // console.log(template)
                        modalFn[modalName](template)

                    } }
                >
                    { template.data?.title || `${template.category?.name} №${template.id}` }
                </button>
            )

        } else {

            result = 'Шаблоны отсутствуют'

        }

        return <>
            { result }
            <div className="ticket-modal-templates-note">Для добавления запроса в шаблоны нажмите <Icon name="star-empty" /> на странице запроса.</div>
        </>

    }, [app.requestTemplates])


    ///////////////////////////////////
    return useCallback(() => setModal({
        title: 'Шаблоны',
        className: 'ticket-modal ticket-modal-templates',
        content: modalContent,
        floating: true,
        isVisible: true,
        buttons: [{
            icon: 'chevron-left2',
            label: 'Назад',
            action: modalFn.showStart
        }]
    }), [modalContent])


}
