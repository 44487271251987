import { forwardRef, memo, useCallback, useEffect, useState } from 'react'
import { Icon } from '../Icon/Icon'
import './TagInput.light.scss'

/**
 * A component that allows users to input and manage tags.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Array} props.initialTags - The initial tags to display.
 * @param {Function} props.onChange - The function to call when tags change.
 * @param {string} props.name - The name of the input field.
 * @param {Object} props.inputRef - The ref for the input field.
 * @param {boolean} props.isInvalid - Indicates if the input is invalid.
 * @param {React.Ref} ref - The ref for the component.
 * @returns {JSX.Element} The rendered TagInput component.
 */

export const TagInput = memo(forwardRef(({ initialTags, onChange, name, inputRef, isInvalid }, ref) => {


    ///////////////////////////////////////////////////
    const [tags, setTags] = useState(initialTags || [])
    const [inputValue, setInputValue] = useState('')
    const [isActive, setIsActive] = useState(false)


    /////////////////
    useEffect(() => {
        onChange(tags, name)
    }, [tags, name]) // onChange in dependency cause maximum update


    /////////////////
    useEffect(() => {

        setTags(initialTags)
        setInputValue('')

    }, [initialTags, setTags])


    ///////////////////////////////////////////
    const handleKeyDown = useCallback((e) => {

        if (e.key === 'Enter') {
            e.preventDefault()

            if (inputValue.trim()) {

                setTags([...tags, inputValue.trim()])
                setInputValue('')

            }

        }

    }, [setTags, setInputValue, tags, inputValue])


    /////////////////////////////////////////
    const handleChange = useCallback((e) => {
        setInputValue(e.target.value)
    }, [setInputValue])


    //////////////////////////////////////////////////
    const removeTag = useCallback((indexToRemove) => {
        setTags(tags.filter((_, index) => index !== indexToRemove))
    }, [setTags, tags])


    ///////////////////////////////////////
    const handleFocus = useCallback(() => {
        setIsActive(true)
    }, [])


    //////////////////////////////////////
    const handleBlur = useCallback(() => {
        setIsActive(false)
    }, [])


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return <div className={ `tag-input ${isActive ? 'tag-input-active' : ''} ${isInvalid ? 'tag-input-invalid' : ''}` }>

        { tags.map((tag, index) => (
            <div key={ index } className="tag">
                { tag }
                <Icon onClick={ () => removeTag(index) } name="close" />
            </div>
        )) }

        <input
            ref={ inputRef }
            type="text"
            placeholder="Введите название тегов через Enter"
            value={ inputValue }
            onChange={ handleChange }
            onKeyDown={ handleKeyDown }
            onFocus={ handleFocus }
            onBlur={ handleBlur }
        />

    </div>


}))
