/**
 * InputPassword Component
 * 
 * This component renders a password input field with a toggle button to show or hide the password.
 * It supports various input attributes and handles value changes.
 * 
 * @component
 * @example
 * ```jsx
 * <InputPassword
 *   name="password"
 *   value={passwordValue}
 *   onChange={handlePasswordChange}
 *   placeholder="Enter your password"
 * />
 * ```
 * 
 * @param {Object} props - Component properties
 * @param {string} props.name - The name attribute for the input element
 * @param {string} [props.value] - The initial value of the input field
 * @param {Function} props.onChange - The function to call when the input value changes
 * @param {string} [props.placeholder] - The placeholder text for the input field
 * @param {string} [props.className] - Additional class names for the input field
 * 
 * @returns {JSX.Element} The rendered password input component
 */


import { memo, useEffect, useMemo, useState } from 'react'
import { Icon } from '../Icon/Icon'
import './InputPassword.scss'


//////////////////////////////////////////////
export const InputPassword = memo((props) => {


    ///////////////////////////////////////////////
    const [value, setValue] = useState(props.value)
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)


    /////////////////
    useEffect(() => {
        setValue(props.value)
    }, [props.value])


    ///////////////////////////
    const attributes = useMemo(
        () => ({
            ...props,
            onChange: (event) => {
                if (props.onChange) {
                    props.onChange(event)
                }
                setValue(event.target.value)
            },
            value,
            type: !isPasswordVisible
                ? 'password'
                : 'text',
        }),
        [props, value, isPasswordVisible]
    )


    ////////////////////////////////
    delete attributes.formClassName
    delete attributes.store


    ////////////////////////////////////////
    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prev) => !prev)
    }


    ///////////////////////////////////////////////
    return <div className="input-password-wrapper">

        <input { ...attributes } />

        <span onClick={ togglePasswordVisibility }>
            <Icon name={ isPasswordVisible ? "eye-slash" : "eye" } />
        </span>

    </div>


})