import isEqual from 'react-fast-compare'
import { apiRequest } from './apiRequest'
import { tip } from './tip'


////////////////////////////////////////////////
export const getContentPath = (dirname, id) => {

    id = id + ''
    return `/data/${dirname}/${id.substring(0, 3)}/${id}`

}


//////////////////////////////////////////////////
export const fieldToClipboard = async (field) => {

    let isFieldHidden = false;

    if (typeof field == 'string') {
        field = document.querySelector(field)
    } else if (field.nodeType === 1) {
        // field is a dom node, do nothing
    } else if (typeof field == 'object') {
        field.stopPropagation()
        field = field.currentTarget
    }

    if (field.tagName === 'INPUT' || field.tagName === 'TEXTAREA') {

        if (field.type === 'hidden') {
            field.type = 'text'
            isFieldHidden = true;
        }

        field.select()
        field.setSelectionRange(0, 99999)
        await navigator.clipboard.writeText(field.value)

        if (isFieldHidden) {
            field.type = 'text'
        }

    } else {

        await navigator.clipboard.writeText(field.innerHTML.replace(/<span class="copy-exclude">.*?<\/span>/g, ''))

    }

    tip({
        target: field,
        content: 'Скопировано!',
        timeout: 3000
    })

}


////////////////////////////////////////////
export const pasteHere = async (target) => {

    // navigator.permissions.query({ name: "clipboard-read" })
    const clipboardContents = await navigator.clipboard.readText()

    if (target.tagName === 'INPUT') {
        target.value = clipboardContents
    } else {
        target.innerText = clipboardContents
    }

}


////////////////////////////////////////////////////
// export const debounce = (func, wait, immediate) => {
//     var timeout
//     return function () {
//         var context = this,
//             args = arguments;
//         var later = function () {
//             timeout = null;
//             if (!immediate) func.apply(context, args);
//         };
//         var callNow = immediate && !timeout;
//         clearTimeout(timeout);
//         timeout = setTimeout(later, wait);
//         if (callNow) func.apply(context, args);
//     }
// }


//////////////////////////////////////////
export const debounce = (func, delay) => {

    let timeout

    return (...args) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => func(...args), delay)
    }

}


//////////////////////////////////////////
export const setPageHeight = selector => {

    const setHeight = debounce(() => {

        const windowHeight = window.innerHeight
        const node = document.querySelector(selector)

        if (!node) return

        const nodeRect = node.getBoundingClientRect()
        const nodeMarginTop = window.getComputedStyle(node).marginTop
        const newNodeHeight = windowHeight - nodeRect.top - parseFloat(nodeMarginTop) - 4
        node.style.height = newNodeHeight + 'px'

        const agencyRequestsHeader = document.querySelector('div.agency-requests-header')
        const agencyRequestsList = document.querySelector('div.agency-requests-list')

        if (agencyRequestsHeader && agencyRequestsList) {
            agencyRequestsList.style.maxHeight = newNodeHeight / 2 - agencyRequestsHeader.offsetHeight + 'px'
        }

    }, 100)

    window.removeEventListener('resize', setHeight)
    window.addEventListener('resize', setHeight)
    setHeight()

}


//////////////////////////////////////////////////
export const matchGroups = (list, userGroups) => {

    if (!userGroups) {
        const authData = JSON.parse(localStorage.getItem('authData'))
        if (!authData?.userGroups) return false
        userGroups = authData.userGroups
    }

    const groupsIntersection = userGroups.filter(group => list.includes(group))
    return groupsIntersection?.length > 0

}


/////////////////////////////////////////////
export const generatePassword = (length) => {

    const getRandomFromRange = (min, max) => {
        min = Math.ceil(min)
        max = Math.floor(max)
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    let result = ''
    const symbols = 'abcdefghigkmnopqrstuvwxyzABCDEFGHGKLMNPQRSTUVWXYZ123456789123456789'.split('')

    for (let i = 0; i < length; i++) {

        let rndSymbolPos = getRandomFromRange(0, symbols.length - 1)
        let rndSymbol = symbols[rndSymbolPos]

        while (result.indexOf(rndSymbol) > -1) {
            rndSymbolPos = getRandomFromRange(0, length - 1)
            rndSymbol = symbols[rndSymbolPos]
        }

        result += rndSymbol

    }

    return result

}


////////////////////////////////////////////////////
export const scrollToTop = (duration, callback) => {
    const startingY = window.pageYOffset;
    const diff = startingY * 0.1;
    let timestamp = null;

    function step(currentTime) {

        if (!timestamp) timestamp = currentTime;
        const progress = Math.min((currentTime - timestamp) / duration, 1);
        window.scrollTo(0, startingY - diff * progress);

        if (progress < 1) {
            window.requestAnimationFrame(step);
        } else if (callback) {
            callback()
        }

    }

    window.requestAnimationFrame(step);
}


////////////////////////////////////////////////////////////////////////////////////
export const handleError = async ({ message, url, line, column, source, auth }) => {

    await apiRequest({
        post: '/api/errors',
        body: JSON.stringify({
            message, url, line, column, source
        }),
        auth
    })

}


////////////////////////////////
export const getHash = name => {

    const hash = window.location.hash.substring(1)
    const pairs = hash.split('&')
    let result = ''

    for (let pair of pairs) {
        const params = pair.split('=')
        if (params[0] === name) {
            result = decodeURIComponent(params[1])
            break
        }
    }

    return result

}


////////////////////////////////////////
export const hashFields = newParams => {

    let hash = window.location.hash.substring(1)
    let curParams = hash && hash.split('&')
    let resultParams = {}
    let sResultParams = ''

    if (curParams) {
        curParams.forEach((item) => {

            let splitItem = item.split('=')
            resultParams[splitItem[0]] = splitItem[1]

        })
    }

    for (let key in newParams) {

        if (newParams[key] === '' && newParams[key] !== false) {
            delete resultParams[key]
        } else {
            resultParams[key] = newParams[key]
        }

    }

    for (let key in resultParams) {

        sResultParams += (sResultParams) ? '&' : '#'
        sResultParams += key + '=' + resultParams[key]

    }

    return sResultParams

}


////////////////////////////////////////////////////////////////////////
EventTarget.prototype.hasEventListener = function (eventType, handler) {

    const eventListeners = this.__events && this.__events[eventType]

    if (eventListeners && eventListeners.includes(handler)) {
        return true
    }

    return false

}


////////////////////////////////////////
export const getBackendUrl = apiUrl => {

    const backendPort = (
        window.location.hostname === 'localhost'
        && process.env.NODE_ENV === 'development'
    )
        ? window.location.port.slice(0, -1) + '1' // 3000 -> 3001, 5000 -> 5001 etc.
        : window.location.port

    let result = 'https://'
    result += window.location.hostname
    result += backendPort ? `:${backendPort}` : ''
    if (apiUrl) result += apiUrl

    return result

}


/////////////////////////////////////////
export const isElementVisible = (el) => {

    if (typeof el === 'string') el = document.querySelector(el)
    const computedStyle = window.getComputedStyle(el)

    if (
        computedStyle.display === 'none'
        || computedStyle.visibility !== 'visible'
        || computedStyle.opacity === '0'
    ) {
        return false
    }

    const rect = el.getBoundingClientRect()

    if (
        rect.bottom < 0 ||
        rect.right < 0 ||
        // rect.left > (window.innerWidth || document.documentElement.clientWidth) ||
        // rect.top > (window.innerHeight || document.documentElement.clientHeight)
        rect.left > document.documentElement.scrollWidth ||
        rect.top > document.documentElement.scrollHeight
    ) {
        return false
    }

    if (el.parentElement && el.parentElement.tagName !== 'BODY') {
        return isElementVisible(el.parentElement)
    }

    return true

}


/////////////////////////////////////////////////////////////
export const updateStateIfChanged = (newState, setState) => {

    if (!newState) return

    setState(prevState =>
        isEqual(prevState, newState) ? prevState : newState
    )

}


//////////////////////////////////////////////
export const getNestedValue = (obj, path) => {

    // used with dot.separates.paths

    return path.split('.').reduce((acc, part) => acc && acc[part], obj)

}


/////////////////////////////////////////////////////
export const getNestedObjectAndKey = (obj, path) => {

    const keys = path.split('.')
    let currentPart = obj

    for (let i = 0; i < keys.length - 1; i++) {
        currentPart = currentPart[keys[i]]
    }

    return { parent: currentPart, key: keys[keys.length - 1] }

}


//////////////////////////////////
export const updateStateArray = ({
    setState,
    updatedArray,
    updateByKey = '_id',
    sort,
    resetList
}) => {

    if (!updatedArray) return

    setState(prevState => {

        const newState = prevState ? [...prevState] : []

        if (resetList) return updatedArray

        updatedArray.forEach(updatedItem => {

            const existingItemIndex = newState.findIndex(item => item[updateByKey] === updatedItem[updateByKey])

            if (existingItemIndex !== -1) {

                let existingItem = { ...newState[existingItemIndex] }

                Object.keys(updatedItem).forEach(key => {
                    const expanded = expandNestedKey(key, updatedItem[key])
                    Object.assign(existingItem, expanded)
                })

                newState[existingItemIndex] = existingItem

            } else {
                newState.push(updatedItem)
            }

        })

        typeof sort === 'function' && newState.sort(sort)

        return newState

    })

}


////////////////////////////////////////////////
export const expandNestedKey = (key, value) => {

    // expands a dot-separated key into a nested object
    // example object: { 'isUnreadMessages.forGiver': true }
    // result: { isUnreadMessages: { forGiver: true } }

    const parts = key.split('.')
    let result = {}
    let current = result

    for (let i = 0; i < parts.length; i++) {

        const part = parts[i]

        if (i === parts.length - 1) {
            current[part] = value
        } else {
            current[part] = {}
            current = current[part]
        }

    }

    return result

}