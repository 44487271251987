import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { AppContext } from '../../context/AppContext'
import { BugReportForm } from '../BugReportForm/BugReportForm.jsx'
import { Dropdown, placeDropdown } from '../Dropdown/Dropdown.jsx'
import { Icon } from '../Icon/Icon'
import { MaintenanceButton } from '../Maintenance/Maintenance.jsx'
import { MatchGroups } from '../MatchGroups'
import { Modal } from '../Modal/Modal'
import { useTicketModal } from '../TicketModal/ticket.modal.hook'
import '../TicketModal/TicketModal.light.scss'
import { UserWidget } from '../UserWidget/UserWidget'
import { TaskAddForm } from './TaskAddForm'
import './TopBar.light.scss'
import { TopCurrentTasks } from './TopCurrentTasks'
import { TopNotifications } from './TopNotifications'
import { TopThemeSwitcher } from './TopThemeSwitcher'

/**
 * Represents the TopBar component.
 * @component
 * @returns {JSX.Element} The rendered TopBar component.
 */

export const TopBar = memo(() => {


    /////////////////////////////////////////////////////////////////////
    const [isAddDropdownVisible, setAddDropdownVisible] = useState(false)
    const [isTaskAddVisible, setTaskAddVisible] = useState(false)
    const [isTemplatesVisible, setTemplatesVisible] = useState(false)
    const [requestTemplate, setRequestTemplate] = useState(null)
    const location = useLocation()
    const addBtn = useRef(null)
    const app = useContext(AppContext)
    const modalFn = useTicketModal()


    /////////////////
    useEffect(() => {
        app.setModalFn(modalFn)
    }, [modalFn])


    /////////////////////////////////
    const toggleAddDropdown = () => {

        placeDropdown('div.topbar-add-dropdown', addBtn.current)
        setAddDropdownVisible(!isAddDropdownVisible)

    }


    ////////////////////////////////
    const handleAddBtnBlur = () => {

        setTimeout(() => {
            setAddDropdownVisible(false)
        }, 250)

    }


    ////////////////////////////////////////////////////////
    const addRequestFromTemplate = useCallback(template => {

        setRequestTemplate(template)
        setTemplatesVisible(false)

        const alias =
            template?.category?.alias.startsWith('design')
                ? 'designUgc'
                : template?.category?.alias

        app.setSelectedSocial(template.social)
        app.setVisibleCat(alias)

    }, [app])


    /////////////////////////////////////////////
    const templatesModalContent = useMemo(() => {

        let result

        if (app.requestTemplates?.length) {

            result = app.requestTemplates?.map((template, templateIndex) =>
                <button
                    className="request-templates-item button button-2-small"
                    key={ templateIndex }
                    onClick={ () => addRequestFromTemplate(template) }
                >
                    { template.data?.title || `${template.category?.name} №${template.id}` }
                </button>
            )

        } else {

            result = <>Шаблоны отсутствуют.</>

        }

        return (
            <>
                { result }
                <div className="request-templates-note">Для добавления запроса в шаблоны нажмите <Icon name="star-empty" /> на странице запроса.</div>
            </>
        )

    }, [app.requestTemplates, addRequestFromTemplate])


    ///////////////////////////////
    const templatesModalButtons = [
        {
            title: 'Закрыть',
            className: 'button-default-xl',
            action: () => setTemplatesVisible(false)
        }
    ]


    ////////////////////////////////
    const tabItems = useMemo(() => {
        return [
            { name: 'Учетные записи', route: '/settings/users', allowedGroups: ['Admin'] },
            { name: 'Команды', route: '/settings/teams', allowedGroups: ['Admin'] },
            { name: 'Категории', route: '/settings/categories', allowedGroups: ['Admin'] },
            { name: 'Глобальные категории', route: '/settings/globalcategories', allowedGroups: ['Admin'] },
            { name: 'Операторы', route: '/settings/operators', allowedGroups: ['Admin', 'Support Proxy'] },
            { name: 'Селлеры', route: '/settings/sellers', allowedGroups: ['Admin', 'Support Proxy'] },
            { name: 'Типы аккаунтов', route: '/settings/account-types', allowedGroups: ['Admin', 'Manager Farm'] },
            { name: 'Темы тех. запросов', route: '/settings/tech-topics', allowedGroups: ['Admin', 'Manager Technical'] },
            { name: 'Гео', route: '/settings/geos', allowedGroups: ['Admin', 'Support Proxy'] },
            { name: 'Агентства', route: '/settings/agencies', allowedGroups: ['Admin'] }
        ].sort((a, b) => a.name.localeCompare(b.name))
    }, [])


    ///////////////////////////////
    return <div className="topbar">

        { location.pathname.includes('/settings') && <div className="settings-tabs">
            { tabItems?.map(item => <NavLink
                key={ item.route }
                className={
                    ({ isActive }) => isActive ? 'settings-tab-active' : 'settings-tab'
                }
                to={ item.route }
            >
                { item.name }
            </NavLink>
            ) }
        </div> }
        <MatchGroups allowed={ ['Buyer', 'Team Lead', 'Manager Farm', 'Admin'] }>

            { app.sellers?.length
                ? <div
                    className={ `topbar-add-btn ${isAddDropdownVisible ? 'button-active' : ''}` }
                    onClick={ modalFn.showStart }
                    onBlur={ handleAddBtnBlur }
                    ref={ addBtn }
                    tabIndex="0"
                >
                    <Icon name={ `plus` } /></div>
                : ''
            }

            <Dropdown
                isVisible={ isAddDropdownVisible }
                className="topbar-add-dropdown"
                content={
                    <>

                        <MatchGroups allowed={ ['Buyer', 'Team Lead', 'Admin'] }>

                            <div className="add-dropdown-item" onClick={ () => app.setVisibleCat('fanpage') }>
                                <Icon name="plus" />
                                <label>Создать новый запрос</label>
                            </div>

                            <div className="add-dropdown-item" onClick={ () => setTemplatesVisible(true) }>
                                <Icon name="plus" />
                                <label>Создать по шаблону</label>
                            </div>

                        </MatchGroups>

                        <div className="add-dropdown-item" onClick={ () => setTaskAddVisible(true) }>
                            <Icon name="plus" />
                            <label>Создать новую задачу</label>
                        </div>

                    </>
                }
            />

        </MatchGroups>

        <MatchGroups allowed={ ['Manager Farm', 'Support Proxy', 'Support Farm'] }>
            <TopCurrentTasks />
        </MatchGroups>

        <TopThemeSwitcher />
        <TopNotifications />
        <MaintenanceButton />
        <BugReportForm />
        <UserWidget />

        <MatchGroups allowed={ ['Buyer', 'Team Lead', 'Admin'] }>

            <Modal
                title="Создание запроса по шаблону"
                className="request-templates-modal"
                isVisible={ isTemplatesVisible }
                setVisible={ setTemplatesVisible }
                content={ templatesModalContent }
                buttons={ templatesModalButtons }
            />

        </MatchGroups>

        <MatchGroups allowed={ ['Buyer', 'Team Lead', 'Manager Farm', 'Admin'] }>
            <TaskAddForm isVisible={ isTaskAddVisible } setVisible={ setTaskAddVisible } />
        </MatchGroups>

    </div>


})