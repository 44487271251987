import { memo, useContext, useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { MaintenanceNote } from '../../components/Maintenance/Maintenance'
import { SideBar } from '../../components/SideBar/SideBar'
import { SideBarToggle } from '../../components/SideBar/SideBarToggle'
import { TopBar } from '../../components/TopBar/TopBar'
import { AppContext } from '../../context/AppContext'
import { AuthContext } from '../../context/AuthContext'
import { globalClickHandler } from '../../js/globalClickHandler'
import { globalPasteHandler } from '../../js/globalPasteHandler'
import './MainPage.light.scss'


const MainPage = () => {


    /////////////////////////////////////////////////////////////
    const [isSidebarVisible, setSidebarVisible] = useState(false)
    const app = useContext(AppContext)
    const page = window.location.pathname.split('/')
    const auth = useContext(AuthContext)


    //////////////////////////////////////////////////
    document.body.dataset.page = page[1] || 'overview'
    document.body.dataset.subpage = page[2] || ''
    document.body.dataset.maintenanceMode = app.maintenanceMode
    document.body.dataset.group = auth.data.userGroups[0]


    /////////////////
    useEffect(() => {

        document.body.addEventListener('paste', globalPasteHandler)

        return () => {
            document.body.removeEventListener('paste', globalPasteHandler)
        }

    }, [])


    /////////////////////////////////////////////////////////////////
    return <div className="main-page" onClick={ globalClickHandler }>

        <SideBarToggle isSidebarVisible={ isSidebarVisible } setSidebarVisible={ setSidebarVisible } />
        <SideBar isSidebarVisible={ isSidebarVisible } setSidebarVisible={ setSidebarVisible } />
        <MaintenanceNote />
        <TopBar />

        <main className="main">
            <Outlet />
        </main>

    </div >


}

export default memo(MainPage)