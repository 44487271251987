import { useContext, useState } from 'react'
import { AppContext } from '../../context/AppContext'
import { useApiRequest } from '../../hooks/api.request.hook'
import { Form, formStateToJson, validateForm } from '../Form/Form'
import { Modal } from '../Modal/Modal'
import { Uploader } from '../Uploader/Uploader'

/**
 * Represents a form for adding a new task.
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.isVisible - Indicates whether the form is visible or not.
 * @param {function} props.setVisible - A function to set the visibility of the form.
 * @returns {JSX.Element} The TaskAddForm component.
 */

export const TaskAddForm = ({ isVisible, setVisible }) => {


    //////////////////////////////////
    const app = useContext(AppContext)
    const [startUpload, setStartUpload] = useState(null)
    const apiRequest = useApiRequest()


    //////////////////////////
    const finishForm = () => {

        app.setInfoPopup({
            isVisible: true,
            text: <>Ваша задача <strong>{ formState[0].value }</strong> успешно создана</>,
            linkUrl: '/requests/',
            linkText: 'Подробнее'
        })

        setVisible(false)

    }


    ////////////////////
    const formFields = [
        {
            title: 'Название',
            type: 'text',
            name: 'title',
            placeholder: 'Коротко опишите задачу',
            required: 'string'
        }, {
            title: 'Ваш комментарий',
            type: 'textarea',
            name: 'comment',
            placeholder: 'Опишите задачу подробнее',
            required: 'string'
        }
    ]


    //////////////////////////////////////////////////////
    const [formState, setFormState] = useState(formFields)


    //////////////////////
    const modalButtons = [
        {
            type: 'custom',
            content: <Uploader url="/api/files" setStartUpload={ setStartUpload } label="Прикрепить файл" multiple />
        },
        {
            title: 'Создать задачу',
            className: 'button-default-xl',
            action: (event) => {
                event.preventDefault()
                submitForm()
            }
        }
    ]


    ////////////////////////////////
    const submitForm = async () => {

        const isValid = validateForm(formState, '.task-add-form', setFormState)

        if (!isValid) {
            return
        }

        await apiRequest({
            post: '/api/requests',
            body: formStateToJson(formState, { category: 'task' }),
            200: async response => {

                await startUpload({
                    type: 'request',
                    itemId: response.newRequest_id
                })

                finishForm()

            }
        })

    }


    ////////
    return (
        <Modal
            isVisible={ isVisible }
            setVisible={ setVisible }
            className="task-add-form"
            buttons={ modalButtons }
            title="Новая задача"
            content={ <Form
                finishFn={ finishForm }
                submitFn={ submitForm }
                isVisible={ isVisible }
                formState={ formState }
                setFormState={ setFormState }
            /> }
        />
    )

}