import { memo, useCallback, useEffect, useState } from 'react'
import { useDebounce } from '../../hooks/debounce.hook'
import { Icon } from '../Icon/Icon'

/**
 * Renders a sidebar toggle button component.
 *
 * @component
 * @param {boolean} isSidebarVisible - Indicates whether the sidebar is visible or not.
 * @param {function} setSidebarVisible - Callback function to toggle the visibility of the sidebar.
 * @returns {JSX.Element} The sidebar toggle button component.
 */

export const SideBarToggle = memo(({ isSidebarVisible, setSidebarVisible }) => {


    ///////////////////////////////////////////////
    const [isMobile, setIsMobile] = useState(false)


    ////////////////////////////////////////
    const handleResize = useCallback(() => {

        setIsMobile(window.innerWidth < 500)

    }, [])


    ////////////////////////////////////////////////////////////
    const debouncedHandleResize = useDebounce(handleResize, 200)


    /////////////////
    useEffect(() => {

        handleResize() // Check initial width
        window.addEventListener('resize', debouncedHandleResize)
        return () => {
            window.removeEventListener('resize', debouncedHandleResize)
        }

    }, [debouncedHandleResize])


    /////////////////////////////////////////
    const toggleSidebar = useCallback(() => {

        setSidebarVisible(prevState => !prevState)

    }, [setSidebarVisible])


    /////////////////////////////////////////////////////////////////////////////////////////////////
    const sidebarIcon = isSidebarVisible ? "chevron-left2" : (isMobile ? "burger" : "chevron-right2")
    const sidebarBackground = isMobile && !isSidebarVisible ? 'transparent' : undefined


    ///////////
    return <div
        className={ `
            sidebar-toggle
            ${isSidebarVisible ? 'sidebar-toggle-open' : ''}
        `}
        style={ { background: sidebarBackground } }
        onClick={ toggleSidebar }
    >
        <Icon name={ sidebarIcon } />
    </div>


})