import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { AppContext } from '../../context/AppContext'
import { useApiRequest } from '../../hooks/api.request.hook'
import { tip } from '../../js/tip'
import { Form, formStateToJson, getFormItemByName, resetForm, updateFormState, validateForm } from '../Form/Form'
import { Uploader } from '../Uploader/Uploader'
import { Icon } from '../Icon/Icon'
import { useModal } from '../Modal2/Modal2'
import './BugReportForm.light.scss'
import Bowser from 'bowser'

export const BugReportForm = memo(() => {


    ///////////////////////////////
    const { setModal } = useModal()
    const auth = useContext(AuthContext)
    const app = useContext(AppContext)
    const [startUpload, setStartUpload] = useState(null)
    const apiRequest = useApiRequest()
    let modalButtons
    const userAgent = Bowser.getParser(window.navigator.userAgent)
    const browserName = userAgent.getBrowserName() || ''
    const browserVersion = userAgent.getBrowserVersion() || ''
    const osName = userAgent.getOSName() || ''
    const osVersion = userAgent.getOSVersion() || ''


    /////////////////
    useEffect(() => {

        updateFormState(setFormState, {
            pageTitle: document.title.replace(' / TDFarmCRM', ''),
            // userName: `${auth.data.firstName}${auth.data.lastName ? ' ' + auth.data.lastName : ''}`
        }, {
            skipUnexisting: true
        })

    }, [document.title, auth.data.firstName, auth.data.lastName])


    ////////////////////
    const formFields = [
        {
            type: 'custom',
            content: <>
                Пользователь: <strong>{ auth.data.firstName }{ auth.data.lastName ? ' ' + auth.data.lastName : '' }</strong><br />
                Браузер: <strong>{ browserName } { browserVersion }</strong><br />
                ОС: <strong>{ osName } { osVersion }</strong>
            </>
        }, {
            title: 'Страница',
            type: 'text',
            name: 'pageTitle',
            required: 'string'
        }, {
            title: 'Ваш Телеграм',
            type: 'text',
            name: 'telegram',
            required: 'string',
            placeholder: 'Введите свой телеграм',
            value: localStorage.getItem('bugReportTelegram')
        }, {
            title: 'Комментарий',
            name: 'comment',
            type: 'textarea',
            required: 'string',
            placeholder: 'Опишите задачу подробнее'
        },
        // {
        //     title: 'Файл, скриншот или видео',
        //     name: 'screenshot',
        //     label: 'Выберите или вставьте из буфера',
        //     type: 'uploader',
        //     url: '/api/bugreport',
        //     setStartUpload,
        //     unnecessary: true
        // }, 
    ]



    //////////////////////////////////////////////////////
    const [formState, setFormState] = useState(formFields)


    ////////////////////////////////////////
    const modalContent = useMemo(() => <Form
        formState={ formState }
        setFormState={ setFormState }
    />, [formState])


    /////////////////
    useEffect(() => {

        setModal({
            className: 'bug-report-modal',
            content: modalContent,
            buttons: modalButtons
        })

    }, [modalContent, setModal, modalButtons])


    ////////////////////////////////////////////
    const submitForm = useCallback(async () => {

        const isValid = validateForm(formState, 'div.bug-report-modal', setFormState)

        if (!isValid) return

        const telegram = getFormItemByName(formState, 'telegram')
        localStorage.setItem('bugReportTelegram', telegram.value)

        await apiRequest({
            post: '/api/bugreport',
            body: formStateToJson(formState, {
                pageUrl: window.location.href,
                host: `https://${window.location.host}`,
                browserName,
                browserVersion,
                osName,
                osVersion,
                userName: `${auth.data.firstName}${auth.data.lastName ? ' ' + auth.data.lastName : ''}`
            }),
            200: async (response) => {

                await startUpload({
                    newCardId: response.newCardId
                })

                tip({
                    target: 'button.bug-report-form-btn',
                    content: 'Отправлено'
                })

                resetForm(setFormState, 'div.bug-report-modal')
                setModal(false)

            }
        })

    }, [apiRequest, formState, setModal, startUpload])

    //     title: 'Файл, скриншот или видео',
    //     name: 'screenshot',
    //     label: 'Выберите или вставьте из буфера',
    //     type: 'uploader',
    //     url: '/api/bugreport',
    //     setStartUpload,
    //     unnecessary: true


    ///////////////////////////////
    modalButtons = useMemo(() => [{
        type: 'custom',
        content: <Uploader name="screenshot" setStartUpload={ setStartUpload } url="/api/bugreport" label="Прикрепить файл" multiple />
    },
    {
        label: 'Отправить',
        className: 'button-default-xl',
        action: submitForm
    }], [submitForm])


    ////////////////////////////////////
    const showForm = useCallback(() => {

        const telegramValue = localStorage.getItem('bugReportTelegram')

        updateFormState(
            setFormState,
            { telegram: telegramValue },
            { skipUnexisting: true }
        )

        setModal({
            title: 'Отчет об ошибке',
            className: 'bug-report-modal',
            content: modalContent,
            buttons: modalButtons,
            isVisible: true
        })

    }, [setModal, modalButtons, modalContent])


    //////////////
    return <button
        className="bug-report-form-btn"
        title="Отчет об ошибке"
        onClick={ showForm }
    >
        <Icon name="bug" />
    </button>


})