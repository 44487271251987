import React, { memo } from 'react'
import './Range.scss'

/**
 * A range component that allows users to input minimum and maximum values.
 *
 * @component
 * @param {number} minValue - The minimum value.
 * @param {number} maxValue - The maximum value.
 * @param {function} onMinChange - The callback function to be called when the minimum value changes.
 * @param {function} onMaxChange - The callback function to be called when the maximum value changes.
 * @param {string} name - The name of the range component.
 * @returns {JSX.Element} The range component.
 */

export const Range = memo(({ minValue = 0, maxValue = 0, onMinChange, onMaxChange, name }) => {

    return <div className="range-container">

        <div className="range-input">

            <label htmlFor="min">От</label>
            <input
                type="number"
                id="min"
                placeholder={ 0 }
                min={ 0 }
                name={ name }
                value={ minValue || '' }
                onChange={ onMinChange }
            />

        </div>

        <div className="range-input">

            <label htmlFor="max">До</label>
            <input
                type="number"
                id="max"
                min={ 0 }
                placeholder={ 0 }
                name={ name }
                value={ maxValue || '' }
                onChange={ onMaxChange }
            />

        </div>

    </div>

})

