import axiosInstance from './axiosHelper'

export const newsApi = {

  getNews({ sortState, page }: { sortState: [string, boolean], page: number }) {
    return axiosInstance.get(`/api/news?sortCol=${sortState[0]}&sortDir=${sortState[1]}&page=${page}`)
  },
  deleteNews(body: { ids: string[] }) {
    return axiosInstance.delete('/api/news', {
      data: body,
    })
  }

}
