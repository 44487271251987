/**
 * InfoPopup Component
 * 
 * This component renders an informational popup with optional link and timeout for auto-hide functionality.
 * 
 * @component
 * @example
 * ```jsx
 * import { AppContext } from '../../context/AppContext'
 * const app = useContext(AppContext)
 * 
 * app.setInfoPopup({
 *   isVisible: true,
 *   text: <>JSX</>,
 *   linkUrl: '/link/url/', // optional
 *   linkText: 'Link text', // optional,
 *   timeout: 10000 // [false | number], optional, default: 5000
 * })
 * 
 * // To hide the popup
 * app.setInfoPopup({
 *   isVisible: false
 * })
 * ```
 * 
 * @param {Object} props - Component properties
 * @param {Object} props.state - The state object containing the popup configuration
 * @param {boolean} props.state.isVisible - Flag indicating if the popup is visible
 * @param {JSX.Element} props.state.text - The text content of the popup
 * @param {string} [props.state.linkUrl] - Optional URL for the link in the popup
 * @param {string} [props.state.linkText] - Optional text for the link in the popup
 * @param {number|boolean} [props.state.timeout=5000] - Optional timeout for auto-hiding the popup, default is 5000ms
 * @param {Function} props.setState - Function to set the state of the popup
 * 
 * @returns {JSX.Element} The rendered info popup component
 */


import { useEffect } from 'react'
import { Icon } from '../Icon/Icon'
import './InfoPopup.scss'


export const InfoPopup = ({ state, setState }) => {


    /////////////////
    useEffect(() => {

        if (typeof state.timeout === 'boolean') {
            return
        }

        setTimeout(hidePopup, state.timeout || 5000)

    }, [])


    /////////////////
    useEffect(() => {

        if (state.isVisible && typeof state.timeout !== 'boolean') {
            setTimeout(hidePopup, state.timeout || 5000)
        }

    }, [state.isVisible])


    /////////////////////////
    const hidePopup = () => {
        setState({ ...state, isVisible: false })
    }


    ///////////
    return <div
        className={ `info-popup ${state.isVisible ? 'info-popup-visible' : ''}` }
    >

        <Icon
            name="chevron-up"
            className="info-popup-close"
            onClick={ () => setState({ ...state, isVisible: false }) }
        />

        <div className="info-popup-text">{ state.text }</div>

        { state.linkUrl && state.linkText ?
            <a
                href={ state.linkUrl }
                className="info-popup-link"
                onClick={ hidePopup }
            >
                { state.linkText }
                <Icon name="arrow-right" />
            </a>
            : ''
        }

    </div>


}