/*

    !!! DEPRECATED !!!

    USAGE
    =====

    await apiRequest({
        [requestMethod]: '/api/url?optional=params',
        body: jsonBody,
        [responseStatus]: optionalCallbackFn,
        otherStatus: optionalCallbackFn,
        useLoader: useContext(AppContext), // optional
        auth: useContext(AuthContext),
        debug: boolean
    })

    requestMethod
    =============
    post|get|patch|delete|put

    responseStatus
    ==============
    200|401|409

*/

import { getBackendUrl } from './lib'

// let isRefreshTokenInAction = false

export const apiRequest = async (props) => {

    const {
        get, post, patch, put,
        body, auth, otherStatus, autoContentType, useLoader,
        ...params
    } = props

    const url = get || post || params.delete || patch || put
    const startTime = params.debug && performance.now()

    let method = ''

    const headers = {
        'x-access-token': auth.data.accessToken
    }

    if (body && !autoContentType) {
        headers['Content-Type'] = 'application/json'
    }

    if (get) {
        method = 'GET'
    } else if (post) {
        method = 'POST'
    } else if (params.delete) {
        method = 'DELETE'
    } else if (patch) {
        method = 'PATCH'
    } else if (put) {
        method = 'PUT'
    }

    useLoader && useLoader.setLoader(true)
    const backendUrl = getBackendUrl(url)

    const response = await fetch(backendUrl, {
        method,
        headers,
        body,
        credentials: 'include'
    })

    let responseJson

    try {
        responseJson = await response.json()
        auth.updateAccessToken(responseJson)
    } catch (e) {
        responseJson = 'Parcing JSON error'
    }

    if (params.debug) {

        const endTime = performance.now()
        const executionTime = endTime - startTime
        console.debug(method, props[method.toLowerCase()], executionTime, 'ms')

    }

    if (response.status === 200 && params['200']) {

        await params['200'](responseJson)

        // if (responseJson.newAccessToken) {
        //     isRefreshTokenInAction = true
        //     console.log(responseJson.newAccessToken)
        // }

        useLoader && useLoader.setLoader(false)

    } else if (response.status === 401) {

        auth.setIsAuth(false)
        useLoader && useLoader.setLoader(false)

    } else if (response.status === 409 && params[409]) {

        params[409](responseJson)
        useLoader && useLoader.setLoader(false)

    } else if (otherStatus) {

        otherStatus(responseJson)
        useLoader && useLoader.setLoader(false)

    } else {

        useLoader && useLoader.setLoader(false)

    }

    return responseJson

}