import { isElementVisible } from './lib'

export const globalPasteHandler = event => {

    const clipboardData = event.clipboardData || window.clipboardData
    const files = clipboardData.files || []

    if (!files.length) return

    const uploaders = document.querySelectorAll('div.modal div.uploader, div.modal2 div.uploader')

    for (const uploader of uploaders) {

        if (!isElementVisible(uploader)) continue

        const input = uploader.querySelector('input[type="file"]')
        const isMultiple = input.hasAttribute('multiple')
        const accept = input.getAttribute('accept')
        const dataTransfer = new DataTransfer()

        for (const file of files) {
            if (accept && accept !== '*' && !file.type.match(accept)) continue
            dataTransfer.items.add(file)
            if (!isMultiple) break
        }

        if (!dataTransfer.files.length) break
        input.files = dataTransfer.files

        setTimeout(() => {
            input.dispatchEvent(new Event('change', { bubbles: true }))
        }, 0)

        uploader.querySelector('label').innerText =
            input.files.length > 1
                ? 'Выбрано файлов: ' + input.files.length
                : input.files[0].name

        break

    }

}