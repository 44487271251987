/*

    USAGE
    =====

    const apiRequest = useApiRequest()

    await apiRequest({
        [requestMethod]: '/api/url?optional=params',
        body: json || object,
        [responseStatus]: optionalCallbackFn,
        otherStatus: optionalCallbackFn,
        useLoader: bool, // optional, default: false
        debug: boolean
    })

    requestMethod
    =============
    post|get|patch|delete|put

*/

import { useCallback, useContext } from 'react'
import { AppContext } from '../context/AppContext'
import { AuthContext } from '../context/AuthContext'
import { getBackendUrl } from '../js/lib'
import { tip } from '../js/tip'

export const useApiRequest = (auth) => {


    /////////////////////////////////////
    const auth2 = useContext(AuthContext)
    const app = useContext(AppContext)
    auth ||= auth2


    /////////////////////////////////////
    return useCallback(async (props) => {

        let {
            setLoader, get, post, patch, put, signal,
            body, otherStatus, autoContentType, useLoader,
            ...params
        } = props

        setLoader ||= app.setLoader
        const apiUrl = get || post || params.delete || patch || put
        const startTime = params.debug && performance.now()

        let method = ''

        const headers = {
            'x-access-token': auth.data.accessToken
        }

        if (body && !autoContentType) {

            headers['Content-Type'] = 'application/json'

            if (typeof body !== 'string') {
                body = JSON.stringify(body)
            }

        }

        if (get) {
            method = 'GET'
        } else if (post) {
            method = 'POST'
        } else if (params.delete) {
            method = 'DELETE'
        } else if (patch) {
            method = 'PATCH'
        } else if (put) {
            method = 'PUT'
        }

        if (
            (
                useLoader
                || (!('useLoader' in props) && method !== 'GET')
            ) && setLoader
        ) setLoader(true)

        const backendUrl = getBackendUrl(apiUrl)

        try {

            const response = await fetch(backendUrl, {
                method,
                headers,
                body,
                credentials: 'include',
                signal
            })

            let responseJson

            try {
                responseJson = await response.json()
                auth.updateAccessToken(responseJson)
            } catch (e) {
                responseJson = 'Parsing JSON error'
            }

            if (params.debug) {

                const endTime = performance.now()
                const executionTime = endTime - startTime
                console.debug(method, props[method.toLowerCase()], executionTime, 'ms')

            }

            if (response.status === 401) {

                auth.setIsAuth && auth.setIsAuth(false)
                hideLoader(props, method, setLoader)

            } else if (params[response.status]) {

                await params[response.status](responseJson)
                hideLoader(props, method, setLoader)

            } else if (otherStatus) {

                await otherStatus(responseJson)
                hideLoader(props, method, setLoader)

            } else if (response.status >= 400) {

                // tooltip for unhandled error

                let tipTarget
                let visibleModal = document.querySelector('div.modal-visible')
                if (!visibleModal) visibleModal = document.querySelector('div.modal2-visible')
                if (visibleModal) tipTarget = visibleModal.querySelector('button.button-1')

                tip({
                    target: tipTarget,
                    content: `Ошибка: ${responseJson?.message}`,
                    timeout: 10000,
                    placement: 'top'
                })

                hideLoader(props, method, setLoader)

            } else {

                hideLoader(props, method, setLoader)

            }

            return {
                status: response.status,
                data: responseJson
            }

        } catch (error) {

            // fetch error

        }

    }, [auth])


}


//////////////////////////////////////////////////
const hideLoader = (props, method, setLoader) => {

    if (
        (
            props.useLoader
            || (!('useLoader' in props) && method !== 'GET')
        ) && setLoader
    ) setLoader(false)

}