import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { SocketContext } from '../../context/SocketContext'
import { useApiRequest } from '../../hooks/api.request.hook'
import { Dropdown, placeDropdown } from '../Dropdown/Dropdown'
import { Icon } from '../Icon/Icon'
import { NotificationCounter } from '../NotificationCounter/NotificationCounter'
import './TopNotifications.light.scss'

/**
 * Represents the TopNotifications component.
 * This component displays a list of notifications in the top bar.
 * It allows the user to toggle the visibility of the notification dropdown,
 * mark all notifications as read, toggle the sound on/off, and click on individual notifications.
 *
 * @component
 * @example
 * return (
 *   <TopNotifications />
 * )
 */

export const TopNotifications = memo(() => {


    ///////////////////////////////////////////////////////////////
    const [isDropdownVisible, setDropdownVisible] = useState(false)
    const toggleBtnRef = useRef(null)
    const [list, setList] = useState([])
    const [unreadCount, setUnreadCount] = useState(0)
    const auth = useContext(AuthContext)

    const [isSoundOn, setSoundOn] = useState(() => {

        const isSoundOnStored = localStorage.getItem('notificationsSound')

        return (
            isSoundOnStored === null
                ? process.env.REACT_APP_NOTIFICATIONS_SOUND_DEFAULT === 'true' ? true : false
                : isSoundOnStored === 'true' ? true : false
        )

    })

    const apiRequest = useApiRequest()
    const socket = useContext(SocketContext)
    const navigate = useNavigate()
    const audioRef = useRef(null)
    const silenceSound = 'data:audio/wav;base64,UklGRigAAABXQVZFZm10IBIAAAABAAEARKwAAIhYAQACABAAAABkYXRhAgAAAAEA'


    //////////////////////////////////////////////////////
    const renderNotifications = useCallback((updates) => {

        auth.updateAccessToken(updates)
        if (!updates?.length) return

        setList(prevState => {

            const newState = [...prevState]

            updates.forEach(update => {

                const existingNotification = newState.find(item => item._id === update._id)

                if (existingNotification) {
                    existingNotification.isUnread = update.isUnread
                } else {
                    newState.unshift(update)
                }

            })

            const newUnreadCount = newState.reduce((total, current) => {
                return current.isUnread ? total + 1 : total
            }, 0)

            setUnreadCount(newUnreadCount)

            const isNewUnread = newState.some(item =>
                item.isUnread
                && !prevState?.some(prevItem => prevItem._id === item._id)
            )

            if (newState.length && isNewUnread && isSoundOn && prevState?.length) {
                const soundName = newState.some(item => item.type === 'closed') ? 'closed' : 'notification'
                audioRef.current.src = `/audio/${soundName}.mp3`
                audioRef.current.play()
            }

            newState.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            return newState

        })

    }, [isSoundOn])


    ///////////////////////////////////////////////
    const updateNotifications = useCallback(() => {
        if (!socket) return
        socket.emit('update notifications')
    }, [socket])


    /////////////////////////////////////////////////////////////
    useEffect(() => updateNotifications(), [updateNotifications])


    /////////////////
    useEffect(() => {

        if (!socket) return

        socket.off('notifications update', renderNotifications)
        socket.on('notifications update', renderNotifications)

        return () => {
            socket.off('notifications update', renderNotifications)
            socket.emit('leave notifications')
        }

    }, [socket, renderNotifications])


    ///////////////////////////////////////
    const playSilence = useCallback(() => {
        audioRef.current?.play()
        document.body.removeEventListener('click', playSilence)
        window.removeEventListener('touchstart', playSilence)
    }, [])


    /////////////////
    useEffect(() => {

        document.body.addEventListener('click', playSilence)
        window.addEventListener('touchstart', playSilence)

        return () => {
            document.body.removeEventListener('click', playSilence)
            window.removeEventListener('touchstart', playSilence)
        }

    }, [playSilence])


    //////////////////////////////////////////
    const toggleDropdown = useCallback(() => {

        placeDropdown('div.topbar-notifications-dropdown', toggleBtnRef.current)
        setDropdownVisible(prevState => !prevState)

    }, [])


    /////////////////////////////////////////
    const handleBtnBlur = useCallback(() => {

        setTimeout(() => {
            setDropdownVisible(false)
        }, 200)

    }, [])


    //////////////////////////////////////////////////////////////////////
    const handleNotificationClick = useCallback((event, notification) => {

        navigate(notification.link)
        setDropdownVisible(false)

        apiRequest({
            patch: `/api/notifications/read?id=${notification._id}&getUpdates=true`,
            useLoader: false,
            // 200: updateNotifications
        })

    }, [apiRequest, navigate])


    ////////////////////////////////////////////
    const setAllRead = useCallback(async () => {

        apiRequest({
            patch: '/api/notifications/read?getUpdates=true',
            useLoader: false,
            // 200: updateNotifications
        })

    }, [apiRequest])


    ///////////////////////////////////////
    const toggleSound = useCallback(() => {

        setSoundOn(prevState => {

            const newValue = !prevState
            localStorage.setItem('notificationsSound', newValue)
            return newValue

        })

    }, [])


    /////////
    return <>

        <div
            className="topbar-item"
            onClick={ toggleDropdown }
            ref={ toggleBtnRef }
            onBlur={ handleBtnBlur }
            tabIndex="1"
        >
            <Icon name="bell" />
            <NotificationCounter value={ unreadCount } color={ unreadCount ? 'red' : 'grey' } />
        </div>

        <Dropdown
            isVisible={ isDropdownVisible && list.length }
            className="topbar-notifications-dropdown"
            content={ <>

                <div className="topbar-notifications-header">

                    {
                        unreadCount
                            ? <button
                                className="button-outline-xs"
                                onClick={ setAllRead }
                            >
                                <Icon
                                    name="mail"
                                />
                                отметить все прочитанными
                            </button>
                            : ''
                    }

                    <button
                        className="button-plain-xl"
                        onClick={ toggleSound }
                        title={ isSoundOn ? 'Выключить звук' : 'Включить звук' }
                    >
                        <Icon name={ isSoundOn ? 'bell-alert' : 'bell-slash' } />
                    </button>

                </div>

                <div className="topbar-notification-list">
                    { list.map((item, index) => {

                        return <React.Fragment key={ index }>

                            <div className="topbar-notification" onClick={ (event) => handleNotificationClick(event, item) }>
                                <div className="topbar-notification-title">
                                    { item.isUnread && <span /> }
                                    { item.title }
                                </div>
                                <div className="topbar-notification-text" dangerouslySetInnerHTML={ { __html: item.text } } />
                                <Icon name="chevron-right" className="topbar-notification-arrow" />
                            </div>

                        </React.Fragment>

                    }) }
                </div>

            </> }
        />

        <audio
            src={ silenceSound }
            ref={ audioRef }
        />

    </>


})