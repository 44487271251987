import { useMemo } from 'react'
import { useTicketModalConstructor } from './ticket.modal.constructor'

export const useShowConsumablesFacebook = (modalFn: any) => {


    ///////////////////////////////////////////
    const ticketModalButtons = useMemo(() => [{
        name: 'fanpage',
        label: 'FanPage',
        action: 'showFacebookFanpage'
    }, {
        name: 'autoregs',
        label: 'Автореги',
        action: 'showFacebookAutoregs'
    }, {
        name: 'bm',
        label: 'БМ',
        action: 'showFacebookBm'
    }, {
        name: 'cards',
        label: 'Карты',
        action: 'showFacebookCards'
    }, {
        name: 'logs',
        label: 'Логи',
        action: 'showFacebookLogs'
    }, {
        name: 'proxy',
        label: 'Прокси',
        action: 'showFacebookProxy'
    }, {
        name: 'samofarms',
        label: 'Самофармы',
        action: 'showFacebookSamofarms'
    }, {
        name: 'consumable',
        label: 'Расходник',
        action: 'showFacebookConsumable'
    }, {
        name: 'other',
        label: 'Другое',
        action: 'showFacebookOther'
    }], [])


    //////////////////////////////////
    return useTicketModalConstructor({
        title: 'Новый тикет',
        name: 'consumables-facebook',
        ticketModalButtons,
        breadcrumbs: [
            ['Расходники', modalFn.showConsumables],
            ['Facebook']
        ],
        modalFn
    })


}
