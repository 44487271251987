/**
 * DropZone Component
 * 
 * This component renders a drop zone for drag-and-drop file uploads. It highlights the drop zone when files are dragged over it
 * and handles the drop event to process the files.
 * 
 * @component
 * @example
 * ```jsx
 * <DropZone />
 * ```
 * 
 * @returns {JSX.Element} The rendered drop zone component
 */


import { memo, useCallback, useEffect, useState, useRef } from 'react'
import './DropZone.scss'
import { Icon } from '../Icon/Icon'
import { isElementVisible } from '../../js/lib'


export const DropZone = memo(() => {


    ///////////////////////////////////////////////
    const [isVisible, setVisible] = useState(false)
    const [activeUploader, setActiveUploader] = useState(null)
    let dragCounter = useRef(0)


    //////////////////////////////////////////////
    const handleDragEnter = useCallback(event => {

        if (!event.dataTransfer?.items?.length) return

        event.preventDefault()
        event.stopPropagation()
        const allUploaders = Array.from(document.querySelectorAll('div.uploader[data-dnd="true"]'))
        const visibleUploaders = allUploaders.filter(uploader => isElementVisible(uploader))
        visibleUploaders.sort((a, b) => getZIndex(b) - getZIndex(a))
        const activeUploader = visibleUploaders.length ? visibleUploaders[0] : null

        if (!activeUploader) return

        dragCounter.current++
        setActiveUploader(activeUploader)
        setVisible(true)

    }, [dragCounter])


    /////////////////////////////////////////
    const handleDrop = useCallback(event => {

        event.preventDefault()
        event.stopPropagation()
        dragCounter.current = 0

        if (!activeUploader) return

        setVisible(false)
        const files = event.dataTransfer.files
        const input = activeUploader.querySelector('input[type="file"]')
        const isMultiple = input.hasAttribute('multiple')
        const accept = input.getAttribute('accept')
        const dataTransfer = new DataTransfer()

        for (const file of files) {
            if (accept && accept !== '*' && !file.type.match(accept)) continue
            dataTransfer.items.add(file)
            if (!isMultiple) break
        }

        if (!dataTransfer.files.length) return
        input.files = dataTransfer.files

        setTimeout(() => {
            input.dispatchEvent(new Event('change', { bubbles: true }))
        }, 0)

        const label = activeUploader.querySelector('label')
        if (!label) return

        label.innerText =
            input.files.length > 1
                ? 'Выбрано файлов: ' + input.files.length
                : input.files[0].name

    }, [activeUploader])


    //////////////////////////////////////////
    const handleDragExit = useCallback(() => {
        setVisible(false)
    }, [])


    ///////////////////////////////////////////
    const handleDragLeave = useCallback(() => {

        dragCounter.current--

        if (dragCounter.current <= 0) {
            setVisible(false)
        }

    }, [dragCounter])


    /////////////////////////////////////////////
    const handleDragOver = useCallback(event => {
        event.preventDefault()
        event.stopPropagation()
    }, [])


    /////////////////
    useEffect(() => {

        document.body.addEventListener('dragenter', handleDragEnter)
        document.body.addEventListener('dragover', handleDragOver)
        document.body.addEventListener('drop', handleDrop)
        document.body.addEventListener('dragexit', handleDragExit)
        // document.body.addEventListener('dragleave', handleDragLeave)

        return () => {
            document.body.removeEventListener('dragenter', handleDragEnter)
            document.body.removeEventListener('dragover', handleDragOver)
            document.body.removeEventListener('drop', handleDrop)
            document.body.removeEventListener('dragexit', handleDragExit)
            // document.body.removeEventListener('dragleave', handleDragLeave)
        }

    }, [handleDragEnter, handleDrop, handleDragExit, handleDragOver, handleDragLeave])


    ///////////
    return <div
        className={ `
            drop-zone
            ${isVisible ? 'drop-zone-visible' : ''}`
        }
    >
        <Icon name="import-small" />
        Бросьте файл сюда
    </div>


})


////////////////////////////////
const getZIndex = (element) => {

    if (!element || element === document.body) {
        return 0
    }

    const style = window.getComputedStyle(element)
    const zIndex = style.zIndex

    if (zIndex && zIndex !== 'auto') {
        return parseInt(zIndex, 10)
    } else {
        return getZIndex(element.parentNode)
    }

}