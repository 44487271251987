import './Icon.scss';
import { AppContext } from '../../context/AppContext';
import { memo, useContext, useState, useEffect, useCallback } from 'react';

const checkIconExists = (url) => {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = url;
    });
};

const IconRaw = ({ name, className, onClick, title, tabIndex, ...attributes }) => {


    ////////////////////////////////////////
    const { theme } = useContext(AppContext)
    const [iconName, setIconName] = useState('')


    ///////////////////////////////////////////
    const fetchIcon = useCallback(async () => {

        const themedName = `${name}2`
        const themedIconUrl = `/icons/${themedName}.svg`
        const defaultIconUrl = `/icons/${name}.svg`

        const exists = await checkIconExists(themedIconUrl)
        setIconName(exists ? themedIconUrl : defaultIconUrl)

    }, [name, theme])


    /////////////////////////////////////////////
    useEffect(() => { fetchIcon() }, [fetchIcon])


    /////////
    return <i
        className={ `icon ${className || ''} icon-${iconName}` }
        style={ { WebkitMaskImage: `url(${iconName})` } }
        onClick={ onClick }
        title={ title }
        tabIndex={ tabIndex }
        { ...attributes }
    ></i>


}

export default memo(IconRaw)
export const Icon = memo(IconRaw)