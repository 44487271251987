type updateParams = {
    [key: string]: any
}


////////////////////////////////////////////////////////////////////////////////
export const updateFormSchema = (setState: any, updateParams: updateParams) => {

    setState((prevState: any[]) => everyFormItem(prevState, item => {

        if (updateParams[item.name]) {
            Object.assign(item, updateParams[item.name])
        }

    }))

}


///////////////////////////////////////////////////////////////////////////////////
export const everyFormItem = (formState: any[], callback: (item: any) => void) => {

    ///
    const proceedCell = (cell: any) => {

        let cellItems: any[] = []

        if (cell.items) {
            cellItems = cell.items
            cellItems.push(cell)
        } else {
            cellItems = [cell]
        }

        cellItems.forEach(callback)

    }

    ///
    const proceedRow = (row: any[] | any) => {

        let cells: any[] = []

        if (Array.isArray(row)) {
            cells = row
        } else {
            cells = [row]
        }

        cells.forEach(proceedCell)
        return row

    }

    ///
    return formState.map(proceedRow)

}