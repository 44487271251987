import { useMemo } from 'react'
import { useTicketModalConstructor } from './ticket.modal.constructor'

export const useShowConsumables = (modalFn: any) => {


    ///////////////////////////////////////////
    const ticketModalButtons = useMemo(() => [{
        name: 'facebook',
        icon: 'facebook',
        label: 'Facebook',
        action: 'showConsumablesFacebook'
    }, {
        name: 'google',
        icon: 'google',
        label: 'Google',
        action: 'showConsumablesGoogle'
    }, {
        name: 'agency',
        icon: 'agency-accounts2',
        label: 'Agency',
        action: 'showConsumablesAgency'
    }, {
        name: 'creative',
        icon: 'design2',
        label: 'Creative',
        action: 'showConsumablesDesign',
        matchTeams: ['Sem', 'Dating devils', 'VenceDigital']
    }], [])


    //////////////////////////////////
    return useTicketModalConstructor({
        title: 'Новый тикет',
        name: 'consumables',
        size: 'medium',
        ticketModalButtons,
        breadcrumbs: [
            ['Расходники']
        ],
        modalFn,
        backAction: 'showNewTicket'
    })


}
