import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { AppContext } from '../../context/AppContext'
import { AuthContext } from '../../context/AuthContext'
import { getBackendUrl } from '../../js/lib'
import { defineCase, shortenText } from '../../js/text'
import { tip } from '../../js/tip'
import { Icon } from '../Icon/Icon'
import { If } from '../If'
import './Uploader.light.scss'

const DEFAULT_LABEL = 'Выберите файл'

/**
 * Uploader component for uploading files.
 *
 * @component
 * @param {string} url - The URL to upload the files to.
 * @param {string} [label=DEFAULT_LABEL] - The label text for the uploader.
 * @param {object} postData - Additional data to be sent along with the files.
 * @param {string} [accept=*] - The file types to accept for upload.
 * @param {boolean} [autoUpload=false] - Whether to automatically upload the files after selection.
 * @param {function} setStartUpload - A function to set the startUpload function.
 * @param {function} onChange - A function to handle the file selection change event.
 * @param {boolean} [multiple=false] - Whether to allow multiple file selection.
 * @param {string} name - The name attribute for the input element.
 * @param {ReactNode} [icon=<Icon name="paperclip" />] - The icon to display in the uploader.
 * @param {array} [selectedFilesDefault] - The default selected files.
 * @param {boolean} [dnd=true] - Whether to enable drag and drop functionality.
 * @param {function} onComplete - A function to be called when the upload is complete.
 * @param {number} [maxFiles] - The maximum number of files allowed to be selected.
 * @param {number} [maxFileSize=process.env.REACT_APP_MAX_FILE_SIZE] - The maximum file size allowed in MB.
 * @param {boolean} isInvalid - Whether the uploader is in an invalid state.
 * @param {boolean} clearButton - Whether to show the clear button.
 * @param {boolean} [showLabel=true] - Whether to show the label.
 * @param {array} resultFiles - The result files to be displayed in the uploader.
 * @returns {JSX.Element} The rendered Uploader component.
 */

export const Uploader = ({
    url,
    label = DEFAULT_LABEL,
    postData,
    accept = '*',
    autoUpload = false,
    setStartUpload,
    onChange,
    multiple = false,
    name,
    icon = <Icon name="paperclip" />,
    selectedFilesDefault,
    dnd = true,
    onComplete,
    maxFiles,
    maxFileSize = process.env.REACT_APP_MAX_FILE_SIZE, // MB
    isInvalid,
    clearButton,
    showLabel = true,
    resultFiles
}) => {


    //////////////////////////////////
    const app = useContext(AppContext)
    const auth = useContext(AuthContext)
    const inputRef = useRef(null)
    const labelRef = useRef(null)
    const [selectedFiles, setSelectedFiles] = useState(selectedFilesDefault || [])


    /////////////////
    useEffect(() => {
        setSelectedFiles(selectedFilesDefault || [])
    }, [selectedFilesDefault])


    /////////////////
    useEffect(() => {
        if (resultFiles) {
            setSelectedFiles(resultFiles)
        }
    }, [resultFiles])


    /////////////////
    useEffect(() => {

        if (setStartUpload instanceof Function) {

            const startUploadFn = () => {

                return async (postData2, selector) => {

                    let files = []

                    if (selector) {
                        const input = document.querySelector(selector)
                        if (input) files = Array.from(input.files)
                    } else if (selectedFiles?.length) {
                        files = selectedFiles
                    } else if (inputRef?.current) {
                        files = Array.from(inputRef.current.files)
                    }

                    if (!files?.length) return

                    await upload(
                        files, { ...postData, ...postData2 }, url, app, auth,
                        onComplete, inputRef, labelRef, setSelectedFiles
                    )

                }
            }

            setStartUpload(startUploadFn)

        }

    }, [app, auth, postData, setStartUpload, url, onComplete, selectedFiles])


    ///////////////////////////////////////
    const handleChange = async (event) => {

        let files = Array.from(event.target.files)

        if (maxFiles && files.length > maxFiles) {

            files = files.slice(0, maxFiles)

            tip({
                content: `Максимальное количество файлов: ${maxFiles}`,
                target: labelRef.current
            })

        }

        if (maxFileSize && files.some(file => file.size > maxFileSize * 1024 * 1024)) {

            files = files.filter(file => file.size <= maxFileSize * 1024 * 1024)

            tip({
                content: `Максимальный размер файла: ${maxFileSize} МБ`,
                target: labelRef.current
            })

        }

        onChange && onChange([...selectedFiles, ...files])

        autoUpload && upload(
            files, postData, url, app, auth, onComplete,
            inputRef, labelRef, setSelectedFiles
        )

        setSelectedFiles((prev) => [...prev, ...files])

    }


    ////////////////////////////////////////
    const inputAttributes = useMemo(() => ({
        type: 'file',
        accept,
        onChange: handleChange,
        ref: inputRef,
        multiple,
        name
    }), [accept, handleChange, multiple, name])


    ////////////////////////////////////
    const clear = useCallback(event => {

        event.stopPropagation()
        setSelectedFiles([])
        inputRef.current.value = ''
        onChange && onChange({
            target: {
                name,
                files: [],
                value: ''
            }
        })

    }, [setSelectedFiles, inputRef])


    /////////////////////////////////////////
    return <div className="uploader-wrapper">

        <div className="uploader" data-dnd={ dnd } data-is-invalid={ isInvalid }>

            <input { ...inputAttributes } />

            { icon }

            <If condition={ clearButton && selectedFiles?.length }>
                <button
                    className="uploader-clear-button button button-6"
                    onClick={ clear }
                >
                    <Icon name="close" />
                </button>
            </If>

        </div>

        <If condition={ showLabel && label }>
            <p><label data-default={ label } data-name={ name } ref={ labelRef }>
                {
                    selectedFiles.length
                        ? selectedFiles.length > 1
                            ? <>Выбрано { selectedFiles.length } { defineCase(selectedFiles.length, 'файл', 'файла', 'файлов') }</>
                            : shortenText(selectedFiles[0].name, 70)
                        : shortenText(label, 70)
                }
            </label></p>
        </If>

    </div>


}


//////////////////////
const upload = async (
    files, postData, url, app, auth, onComplete, inputRef, labelRef, setSelectedFiles
) => {

    app.setLoader(true)
    app.setUploadingActive(true)
    setSelectedFiles([])
    const backendUrl = getBackendUrl(url)
    const loaderTextContainer = document.querySelector('div.loader-text')

    let totalFiles = files.length
    let filesUploaded = 0
    let totalProgress = 0

    if (!loaderTextContainer) {
        console.error('Loader text container not found')
        return
    }

    for (let i = 0; i < files.length; i++) {
        const payload = new FormData()
        payload.append('file', files[i])

        if (postData) {
            for (let key in postData) {
                payload.append(key, postData[key])
            }
        }

        await new Promise((resolve, reject) => {

            const xhr = new XMLHttpRequest()
            xhr.open('POST', backendUrl, true)
            xhr.setRequestHeader('x-access-token', auth.data.accessToken)
            xhr.withCredentials = true

            xhr.upload.onprogress = (event) => {
                if (event.lengthComputable) {
                    const percentComplete = (event.loaded / event.total) * 100
                    totalProgress = (filesUploaded + (percentComplete / 100)) * 100 / totalFiles
                    if (loaderTextContainer) {
                        loaderTextContainer.textContent = `${Math.round(totalProgress)}%`
                    }
                }
            }

            xhr.onload = () => {

                if (xhr.status >= 200 && xhr.status < 300) {
                    filesUploaded++

                    const responseJson = JSON.parse(xhr.responseText)

                    onComplete && onComplete({
                        data: responseJson,
                        status: xhr.status
                    })

                    resolve()

                } else {

                    reject(new Error('Upload failed'))

                }

            }

            xhr.send(payload)

        })

    }

    resetUploader(inputRef, labelRef, setSelectedFiles)
    app.setLoader(false)
    loaderTextContainer.textContent = ''
    app.setUploadingActive(false)

}




/////////////////////////////////////////////////////////////////
const resetUploader = (inputRef, labelRef, setSelectedFiles) => {

    if (inputRef?.current) inputRef.current.value = ''
    if (inputRef?.current) inputRef.current.files = null
    if (labelRef?.current) labelRef.current.innerText = DEFAULT_LABEL
    setSelectedFiles([])

}