import { useCallback, useEffect, useRef, useState } from 'react'
import { useApiRequest } from '../../hooks/api.request.hook'
import { useModal } from '../Modal2/Modal2'
import { useShowNewTicket } from '../TicketModal/show.new.ticket.hook'
import { useShowStart } from '../TicketModal/show.start.hook'
import { useShowAgencyNew } from './show.agency.new.hook'
import { useShowAgencyReplace } from './show.agency.replace.hook'
import { useShowAgencyReport } from './show.agency.report.hook'
import { useShowConsumablesAgency } from './show.consumables.agency.hook'
import { useShowConsumablesDesign } from './show.consumables.design.hook'
import { useShowConsumablesFacebook } from './show.consumables.facebook.hook'
import { useShowConsumablesGoogle } from './show.consumables.google.hook'
import { useShowConsumables } from './show.consumables.hook'
import { useShowDesignStatic } from './show.design.static.hook'
import { useShowDesignUgc } from './show.design.ugc.hook'
import { useShowDesignVideo } from './show.design.video.hook'
import { useShowFacebookAutoregs } from './show.facebook.autoregs.hook'
import { useShowFacebookBm } from './show.facebook.bm.hook'
import { useShowFacebookCards } from './show.facebook.cards.hook'
import { useShowFacebookConsumable } from './show.facebook.consumable.hook'
import { useShowFacebookFanpage } from './show.facebook.fanpage.hook'
import { useShowFacebookLogs } from './show.facebook.logs.hook'
import { useShowFacebookOther } from './show.facebook.other.hook'
import { useShowFacebookProxy } from './show.facebook.proxy.hook'
import { useShowFacebookSamofarms } from './show.facebook.samofarms.hook'
import { useShowGoogleOther } from './show.google.other.hook'
import { useShowGooglePurchasedFarm } from './show.google.purchased.farm.hook'
import { useShowGoogleTdFarm } from './show.google.td.farm.hook'
import { useShowGoogleProxy } from './show.google.proxy.hook'
import { useShowGoogleConsumable } from './show.google.consumable.hook'
import { useShowTech } from './show.tech.hook'
import { useShowTemplates } from './show.templates.hook'

const modalFn: any = {}

export const useTicketModal = () => {


    /////////////////////////////////
    const formRef: any = useRef(null)
    const apiRequest = useApiRequest()
    const [startUpload, setStartUpload] = useState<
        ((startUploadFn: any) => void) | null
    >(null)
    const startUploadRef = useRef(startUpload)
    const { setModal } = useModal()


    ////////////////////////////////////////////////////////////////////////
    useEffect(() => { startUploadRef.current = startUpload }, [startUpload])


    //////////////////////////////////////
    const submitForm = useCallback(() => {
        formRef.current && formRef.current.submit()
    }, [])


    ///////////////////////////////////////////////////////
    const handleSubmit = useCallback(async (data: any) => {

        console.log('handleSubmit', data)
        // return

        let apiUrl
        let uploadType = 'request'

        let isAgencyRequest = false
        let isDesignRequest = false

        if (data?.category) {
            isAgencyRequest = data.category.startsWith('agency')
            isDesignRequest = data.category.startsWith('design')
        } else if (data['type-0']) {
            isAgencyRequest = data['type-0'].startsWith('agency')
            isDesignRequest = data['type-0'].startsWith('design')
        }

        if (isAgencyRequest) {
            apiUrl = '/api/agencies/requests'
            uploadType = 'agencyRequest'
        } else if (isDesignRequest) {
            apiUrl = '/api/designs/requests'
            uploadType = 'designRequest'
        } else {
            apiUrl = '/api/requests'
        }

        await apiRequest({
            post: apiUrl,
            body: data,
            200: async (response: any) => {

                startUploadRef.current &&
                    (await startUploadRef.current({
                        type: uploadType,
                        itemId: response.newRequest_id,
                    }))

                setModal(false)

            },
        })

    }, [apiRequest])


    /////////////////////////////////////////
    modalFn.showStart = useShowStart(modalFn)
    modalFn.showNewTicket = useShowNewTicket(modalFn)

    modalFn.showTech = useShowTech(
        modalFn,
        formRef,
        submitForm,
        handleSubmit,
        setStartUpload
    )

    modalFn.showTemplates = useShowTemplates(modalFn)
    modalFn.showConsumables = useShowConsumables(modalFn)

    modalFn.showConsumablesFacebook = useShowConsumablesFacebook(modalFn)

    modalFn.showFacebookFanpage = useShowFacebookFanpage(
        modalFn,
        formRef,
        submitForm,
        handleSubmit,
    )

    modalFn.showFacebookAutoregs = useShowFacebookAutoregs(
        modalFn,
        formRef,
        submitForm,
        handleSubmit,
    )

    modalFn.showFacebookBm = useShowFacebookBm(
        modalFn,
        formRef,
        submitForm,
        handleSubmit,
    )

    modalFn.showFacebookCards = useShowFacebookCards(
        modalFn,
        formRef,
        submitForm,
        handleSubmit,
    )

    modalFn.showFacebookLogs = useShowFacebookLogs(
        modalFn,
        formRef,
        submitForm,
        handleSubmit,
    )

    modalFn.showFacebookProxy = useShowFacebookProxy(
        modalFn,
        formRef,
        submitForm,
        handleSubmit,
    )

    modalFn.showFacebookSamofarms = useShowFacebookSamofarms(
        modalFn,
        formRef,
        submitForm,
        handleSubmit,
    )

    modalFn.showFacebookConsumable = useShowFacebookConsumable(
        modalFn,
        formRef,
        submitForm,
        handleSubmit
    )

    modalFn.showFacebookOther = useShowFacebookOther(
        modalFn,
        formRef,
        submitForm,
        handleSubmit,
        setStartUpload
    )

    // GOOGLE

    modalFn.showConsumablesGoogle = useShowConsumablesGoogle(modalFn)

    modalFn.showGooglePurchasedFarm = useShowGooglePurchasedFarm(
        modalFn,
        formRef,
        submitForm,
        handleSubmit
    )

    modalFn.showGoogleTdFarm = useShowGoogleTdFarm(
        modalFn,
        formRef,
        submitForm,
        handleSubmit
    )

    modalFn.showGoogleOther = useShowGoogleOther(
        modalFn,
        formRef,
        submitForm,
        handleSubmit,
        setStartUpload
    )

    modalFn.showGoogleProxy = useShowGoogleProxy(
        modalFn,
        formRef,
        submitForm,
        handleSubmit
    )

    modalFn.showGoogleConsumable = useShowGoogleConsumable(
        modalFn,
        formRef,
        submitForm,
        handleSubmit
    )

    // AGENCY

    modalFn.showConsumablesAgency = useShowConsumablesAgency(modalFn)

    modalFn.showAgencyReplace = useShowAgencyReplace(
        modalFn,
        formRef,
        submitForm,
        handleSubmit,
        setStartUpload
    )

    modalFn.showAgencyReport = useShowAgencyReport(
        modalFn,
        formRef,
        submitForm,
        handleSubmit,
        setStartUpload
    )

    modalFn.showAgencyNew = useShowAgencyNew(
        modalFn,
        formRef,
        submitForm,
        handleSubmit,
        setStartUpload
    )

    modalFn.showConsumablesDesign = useShowConsumablesDesign(modalFn)

    // DESIGN

    modalFn.showDesignUgc = useShowDesignUgc(
        modalFn,
        formRef,
        submitForm,
        handleSubmit,
        setStartUpload
    )

    modalFn.showDesignStatic = useShowDesignStatic(
        modalFn,
        formRef,
        submitForm,
        handleSubmit,
        setStartUpload
    )

    modalFn.showDesignVideo = useShowDesignVideo(
        modalFn,
        formRef,
        submitForm,
        handleSubmit,
        setStartUpload
    )

    //////////////
    return modalFn

}
