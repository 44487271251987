import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { AppContext } from '../../context/AppContext'
import FormConstructor from '../FormConstructor/FormConstructor'
import { useModal } from '../Modal2/Modal2'
import { useDefaultValues } from './default.values.hook'

export const useShowFacebookLogs = (
    modalFn: any,
    formRef: any,
    submitForm: Function,
    handleSubmit: SubmitHandler<any>,
) => {


    ///////////////////////////////
    const { setModal } = useModal()
    const app = useContext(AppContext)
    const { defaultValues, setDefaultValues } = useDefaultValues(modalFn, 'logs')

    ////////////////////////////////////////////////////////////////////
    const mapTopics = (items: Array<any>, filterActive: boolean = false) => {
        return items
            ?.filter((item: any) => !filterActive || item.isActive)
            ?.map((item: any) => ({
                label: item.name,
                value: item._id,
            }))
    }

    const geosTopics = useMemo(
        () => mapTopics(app?.geos || [], true),
        [app?.geos]
    )

    ///////////////////////////////////
    const formSchema = useMemo(
        () => [
            [
                {
                    label: 'ПЗРД',
                    type: 'radio-button',
                    validation: { required: true },
                    options: [
                        { label: 'С ПЗРД', value: 'with' },
                        { label: 'Без ПЗРД', value: 'without' }
                    ],
                    name: 'pzrd',
                },
                {
                    label: 'Лимит',
                    type: 'radio-button',
                    validation: { required: true },
                    options: [
                        { label: '50', value: '50' },
                        { label: '250', value: '250' },
                        { label: 'Вериф', value: 'verif' }
                    ],
                    name: 'limit',
                },
                {
                    label: 'Необходимое количество',
                    placeholder: 'Введите количество',
                    type: 'quantity',
                    name: 'qty',
                    value: 1,
                    min: 1,
                    max: 1000,
                    required: 'number'
                }
            ],
            {
                label: 'Гео',
                placeholder: 'Выберите гео',
                type: 'select',
                autocomplete: true,
                name: 'geo',
                options: geosTopics,
                validation: { required: true },
            },
            {
                label: 'Ссылка на покупку (необязательно)',
                placeholder: 'Вставте ссылку на покупку',
                type: 'text',
                name: 'buyLink',
                pastable: true
            },
            {
                label: 'Ваш комментарий (необязательно)',
                placeholder: 'Ваш комментарий',
                type: 'textarea',
                name: 'comment',
            },
        ],
        [geosTopics]
    )

    ////////////////////////////////////
    const modalContent = useMemo(() => {
        return (
            <>
                <FormConstructor
                    ref={ formRef }
                    formSchema={ formSchema }
                    defaultValues={ defaultValues }
                    sectionTitle="Логи"
                    sectionCount={ 5 }
                    onSubmit={ (data) =>
                        handleSubmit({
                            ...data,
                            social: 'facebook',
                            category: 'logs',
                        })
                    }
                />
            </>
        )
    }, [formSchema, handleSubmit, formRef.current])


    /////////////////////////////////////////////
    const showModal = useCallback(() => setModal({
        title: 'Новый тикет',
        className: 'ticket-modal ticket-modal-logs',
        content: modalContent,
        floating: true,
        isVisible: true,
        buttons: [
            {
                icon: 'plus',
                label: 'Еще один тикет',
                action: () => formRef.current?.append(),
                className: 'button-plain-xl',
            },
            {
                label: 'Создать тикет',
                action: submitForm,
            },
        ],
        breadcrumbs: [
            ['Расходники', modalFn.showConsumables],
            ['Facebook', modalFn.showConsumablesFacebook],
            ['Логи']
        ]
    }), [modalContent])

    /////////////////
    useEffect(() => {

        if (defaultValues) {
            showModal()
            setDefaultValues(null)
        }

    }, [modalContent, defaultValues, showModal, setDefaultValues])

    ////////////////
    return showModal
}