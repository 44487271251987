import React, { memo, useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { matchGroups } from '../../js/lib'
import { TicketModalButton } from './TicketModalButton'

interface TicketModalButtonsProps {
    list: Array<{
        label: string,
        icon?: string,
        action: any,
        name: string,
        matchTeams?: string[]
    }>
    onChange?: Function
}

export const TicketModalButtons = memo(({ list, onChange }: TicketModalButtonsProps) => {


    //////////////////////////////////////////////////////
    const [selectedLabel, setSelectedLabel] = useState('')
    const auth = useContext(AuthContext)


    /////////////////////////////////////////////
    return <div className="ticket-modal-buttons">

        { list.map((button, index) =>
            <TicketModalButton
                key={ index }
                label={ button.label }
                icon={ button.icon }
                selected={ selectedLabel === button.label }
                name={ button.name }
                disabled={
                    !matchGroups(['Admin'])
                    && button.matchTeams
                    && !button.matchTeams.includes(auth.data.team.name)
                }
                onClick={ () => {
                    setSelectedLabel(button.label)
                    onChange && onChange(button.name)
                } }
            />
        ) }

    </div>


})

