import React, {
    useCallback, useEffect, useMemo, useState
} from 'react'
import { SubmitHandler } from 'react-hook-form'
import FormConstructor from '../FormConstructor/FormConstructor'
import { useModal } from '../Modal2/Modal2'
import { Uploader } from '../Uploader/Uploader'
import { useDefaultValues } from './default.values.hook'
import Icon from '../Icon/Icon'
import { formatFileSize } from '../../js/file'

export const useShowGoogleOther = (
    modalFn: any,
    formRef: any,
    submitForm: Function,
    handleSubmit: SubmitHandler<any>,
    setStartUpload: any
) => {


    ///////////////////////////////////////////
    const { setModal, modalState } = useModal()
    const [resultFiles, setResultFiles] = useState<any>([])
    const [shouldUpdateModal, setShouldUpdateModal] = useState(false)
    const { defaultValues, setDefaultValues } = useDefaultValues(modalFn, 'googleOther')


    ///////////////////////////////////
    const formSchema = useMemo(() => [{
        label: 'Описание задачи',
        placeholder: 'Опишите задачу',
        type: 'textarea',
        name: 'comment',
        validation: { required: true }
    }], [])


    ////////////////////////////////////
    const modalContent = useMemo(() => {
        return (
            <>
                <FormConstructor
                    ref={ formRef }
                    formSchema={ formSchema }
                    defaultValues={ defaultValues }
                    sectionTitle="Другое"
                    sectionCount={ 5 }
                    onSubmit={ (data) =>
                        handleSubmit({
                            ...data,
                            social: 'google',
                            category: 'googleOther',
                        })
                    }
                />

                <div className="ticket-modal-heading">Прикрепить файл</div>

                <div className="ticket-modal-files">
                    { resultFiles?.map((result: any) =>
                        <div className="ticket-details-file" key={ result?.name }>
                            <span>
                                <div className="ticket-details-file-name">{ result?.name }</div>
                                <div className="ticket-details-file-size">{ formatFileSize(result?.size) }</div>
                            </span>
                            <Icon onClick={ () => {
                                setResultFiles((prev: any) => prev.filter((item: any) => item?.name !== result?.name))
                                setShouldUpdateModal(true)
                            } } name="close" />
                        </div>
                    ) }
                </div>

                <Uploader
                    url="/api/files"
                    postData={ { type: 'ticket' } }
                    setStartUpload={ setStartUpload }
                    resultFiles={ resultFiles }
                    onChange={ files => {
                        setResultFiles(files)
                        setShouldUpdateModal(true)
                    } }
                    multiple
                    name="file-0"
                />

            </>
        )
    }, [formSchema, handleSubmit, defaultValues, resultFiles, setResultFiles, setShouldUpdateModal, setStartUpload])


    /////////////////////////////////////
    const showModal = useCallback(() => {

        return setModal({
            title: 'Новый тикет',
            className: 'ticket-modal ticket-modal-google-other',
            content: modalContent,
            isVisible: true,
            floating: true,
            buttons: [{
                icon: 'chevron-left',
                label: 'Назад',
                action: modalFn.showConsumablesGoogle,
                className: 'button-plain-xl',
            }, {
                label: 'Создать тикет',
                action: submitForm,
            }],
            breadcrumbs: [
                ['Расходники', modalFn.showConsumables],
                ['Google', modalFn.showConsumablesGoogle]
            ]
        })

    }, [modalContent, submitForm])


    /////////////////
    useEffect(() => {

        if (
            shouldUpdateModal
            && modalState.isVisible
            && modalState.className === 'ticket-modal ticket-modal-google-other'
        ) {
            showModal()
            setShouldUpdateModal(false)
        }

    }, [shouldUpdateModal, showModal, modalState])


    /////////////////
    useEffect(() => {
        if (defaultValues) {
            showModal()
            setDefaultValues(null)
        }
    }, [modalContent, defaultValues, showModal, setDefaultValues])


    ////////////////
    return showModal


}
