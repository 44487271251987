import React, { forwardRef, memo, useCallback, useEffect, useMemo, useRef } from 'react'
import { Icon } from '../Icon/Icon'
import './Quantity.light.scss'

/**
 * Quantity component.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.name - The name of the input field.
 * @param {number} props.min - The minimum value allowed for the input field.
 * @param {number} props.max - The maximum value allowed for the input field.
 * @param {function} props.onChange - The callback function to be called when the value of the input field changes.
 * @param {boolean} props.required - Indicates whether the input field is required.
 * @param {boolean} props.isInvalid - Indicates whether the input field is invalid.
 * @param {React.Ref} props.reference - The reference to the input field.
 * @returns {JSX.Element} The Quantity component.
 */

export const Quantity = memo(forwardRef((props, ref) => {

    const {
        name,
        min,
        max,
        onChange,
        required,
        isInvalid,
        reference
    } = props


    //////////////////////////
    const input = useRef(null)
    const [value, setValue] = React.useState(props.value || 1)


    /////////////////
    useEffect(() => {
        if (value in props) setValue(props.value || 1)
    }, [props.value])


    /////////////////
    useEffect(() => {
        if (reference) reference.current = input.current
    }, [input])


    //////////////////////////////////
    const stepUp = useCallback(() => {

        let newValue = +input.current.value + 1

        if (min && newValue < min) {
            newValue = min
        }

        if (max && newValue > max) {
            newValue = max
        }

        input.current.value = newValue
        setValue(newValue)

        onChange && onChange({
            target: {
                name: name,
                value: input.current.value
            }
        })

    }, [input, max, name, onChange])


    ////////////////////////////////////
    const stepDown = useCallback(() => {

        let newValue = +input.current.value - 1

        if (min && newValue < min) {
            newValue = min
        }

        if (max && newValue > max) {
            newValue = max
        }

        input.current.value = newValue
        setValue(newValue)

        onChange && onChange({
            target: {
                name: name,
                value: input.current.value
            }
        })

    }, [input, min, name, onChange])


    ///////////////////////////////////
    const attributes = useMemo(() => ({
        type: 'text',
        name: name,
        value: value,
        ref: reference
    }), [name, reference, value])


    /////////////////////////////
    if (min) attributes.min = min
    if (max) attributes.max = max
    if (required) attributes['data-required'] = 'number'


    ///////////////
    // if (onChange) {

    attributes.onChange = event => {

        event.target.value = event.target.value.replace(/\D+/gi, '')
        if (!+event.target.value) event.target.value = min || 1
        setValue(event.target.value)
        onChange && onChange(event)

    }

    // }


    ///////////////////////////////////////////////////////////////
    return <div className="quantity" data-is-invalid={ isInvalid }>

        <div className="quantity-button quantity-button-minus" onClick={ stepDown }>
            <Icon name="minus" />
        </div>

        <input
            className="quantity-input"
            { ...attributes }
            ref={ input }
        />

        <div className="quantity-button quantity-button-plus" onClick={ stepUp }>
            <Icon name="plus-thin" />
        </div>

    </div>


}))