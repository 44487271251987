import { memo, useCallback, useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { If } from '../If'
import './UserAvatar.light.scss'

/**
 * UserAvatar component displays the avatar of a user.
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.firstName - The first name of the user.
 * @param {string} props.lastName - The last name of the user.
 * @param {string} [props.size='s'] - The size of the avatar. Can be 's' (small) or 'm' (medium).
 * @param {string} [props.avatarDir] - The directory path of the avatar image.
 * @returns {JSX.Element} The UserAvatar component.
 */

export const UserAvatar = memo(({
    firstName, lastName, size = 's', ...props
}) => {


    ////////////////////////////////////
    const auth = useContext(AuthContext)
    const [imagePath, setImagePath] = useState(null)
    const avatarDir = 'avatarDir' in props
        ? props.avatarDir
        : auth?.data?.avatarDir


    /////////////////
    useEffect(() => {

        if (!avatarDir) return

        if (avatarDir?.startsWith('blob:')) {
            setImagePath(avatarDir)
        } else {
            setImagePath(`${avatarDir}/${size}.webp?ver=${Date.now()}`)
        }

    }, [auth, props?.avatarDir, size])


    //////////////////////////////////////////
    const renderInitials = useCallback(() => {

        const userFirstName = firstName || auth?.data?.firstName
        const userLastName = lastName || auth?.data?.lastName
        return `${userFirstName?.[0]?.toUpperCase() ?? ''}${userLastName?.[0]?.toUpperCase() ?? ''}`

    }, [firstName, lastName, auth])


    ///////////
    return <div
        className={ size === 'm' ? 'user-avatar-medium' : 'user-avatar-small' }
        style={ { backgroundImage: `url(${imagePath})` } }
    >
        <If condition={ !avatarDir }>{ renderInitials() }</If>
    </div>


})