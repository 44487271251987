/**
 * FormConstructor Component
 * 
 * This component dynamically renders a form based on the provided schema. It supports various input types,
 * including text, textarea, select, checkbox, radio button, quantity, and custom components.
 * 
 * @component
 * @example
 * ```jsx
 * <FormConstructor
 *   formSchema={formSchema}
 *   onSubmit={handleSubmit}
 *   defaultValues={defaultValues}
 *   sectionTitle="Section Title"
 *   sectionCount={3}
 * />
 * ```
 * 
 * @param {Object} props - Component properties
 * @param {Array} props.formSchema - The schema defining the form fields
 * @param {Function} props.onSubmit - The function to call when the form is submitted
 * @param {Object} [props.defaultValues={}] - The default values for the form fields
 * @param {string} [props.sectionTitle] - The title for each section (if sectionCount is provided)
 * @param {number} [props.sectionCount=null] - The number of sections to render
 * 
 * @returns {JSX.Element} The rendered form constructor component
 */


import React, { useCallback, forwardRef, useImperativeHandle, useEffect, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { Input } from '../Input/Input'
import { Textarea } from '../Textarea/Textarea'
import { Select } from '../Select/Select'
import { TagInput } from '../TagInput/TagInput'
import { Radio } from '../Radio/Radio'
import { Quantity } from '../Quantity/Quantity'
import TicketModalCount from '../TicketModal/TicketModalCount'
import './FormConstructor.scss'
import { everyFormItem } from './updateFormSchema'


const FormConstructor = forwardRef(({
    formSchema,
    onSubmit,
    defaultValues = {},
    sectionTitle,
    sectionCount = null
}, ref) => {


    ///////
    const {
        register, handleSubmit, setValue, getValues, unregister, control, reset,
        formState: { errors }, watch
    } = useForm({ defaultValues: sectionCount && { sections: [{ ...defaultValues }] } })


    /////////////////////////////////////////////////
    const { fields, append, remove } = useFieldArray({
        control,
        rules: { maxLength: sectionCount },
        name: 'sections',
    })


    ////////////////////////////////////////////////////////
    const [visibleSections, setVisibleSections] = useState()


    /////////////////
    useEffect(() => {

        if (
            !sectionCount
            && defaultValues
            && Object.keys(defaultValues).length
        ) {
            reset(defaultValues)
        }

    }, [sectionCount, defaultValues])


    /////////////////
    useEffect(() => {

        if (fields?.length === 1) {
            setVisibleSections(fields?.map(item => item?.id))
        }

    }, [fields])


    /////////////////
    useEffect(() => {

        everyFormItem(formSchema, item => {
            if (item.hidden) unregister(item.name)
        })

        fields?.forEach((section, sectionIndex) => {

            everyFormItem(formSchema, item => {
                if (item.hidden) unregister(`sections.${sectionIndex}.${item.name}-${sectionIndex}`)
            })

        })

    }, [formSchema, unregister, fields])


    /////////////////////////////////
    useImperativeHandle(ref, () => ({

        submit: handleSubmit((data) => {

            if (!sectionCount) {
                onSubmit(data)
                return
            }

            const preparedData = data?.sections?.flat()?.reduce((acc, curr) => {
                return { ...acc, ...curr }
            }, {})

            onSubmit({ ...preparedData, formSections: fields?.length })
            reset({})

        }),
        append: () => (fields?.length < sectionCount) && append({}),

    }))


    //////////////////////////////////////////////////////
    const toggleSectionVisibility = useCallback((id) => {

        setVisibleSections((prev) =>
            prev.includes(id)
                ? prev.filter(item => item !== id)
                : [...prev, id]
        )

    }, [setVisibleSections])


    ////////////////////////////////////////////////////////////////////
    const renderFormField = useCallback((field, sectionIndex = null) => {

        const fieldName = sectionIndex !== null
            ? `sections.${sectionIndex}.${field.name + `-${sectionIndex}`}`
            : field.name

        const error = sectionIndex !== null
            ? errors?.sections?.[sectionIndex]?.[`${field.name}-${sectionIndex}`]
            : errors?.[field.name]

        const fieldProps = {
            ...register(fieldName, {
                ...field.validation,
                onChange: (e) => {
                    setValue(fieldName, e.target.value)
                    field.onChange?.(e)
                },
            }),
            placeholder: field.placeholder || '',
            disabled: field.disabled,
            isInvalid: !!error,
            'data-is-invalid': !!error,
            value: getValues(fieldName)
            // onChange: (e) => setValue(fieldName, e.target.value)
        }

        switch (field.type) {

            case 'select':

                const selectedValue = getValues(fieldName)

                fieldProps.selectedIndex =
                    field.selectedIndex
                    || field.options.findIndex(option =>
                        option[field.valueParamName || 'value'] === selectedValue
                    )

                return <Select
                    { ...fieldProps }
                    source={ field.options }
                    labelParamName={ field.labelParamName }
                    valueParamName={ field.valueParamName }
                    autocomplete={ field.autocomplete }
                    multiple={ field.multiple }
                    // onChange={ () => (event) => {
                    //     field.onItemClick && field.onItemClick(event)
                    // } }
                    onItemClick={ () => (event) => {
                        field.onItemClick && field.onItemClick(event)
                    } }
                />

            case 'textarea':
                return <Textarea { ...fieldProps } />

            case 'checkbox':

                if ('isInvalid' in fieldProps) {
                    fieldProps['data-is-invalid'] = fieldProps.isInvalid
                    delete fieldProps.isInvalid
                }

                return (
                    <div className="form-item-checkbox">
                        <input type="checkbox" id={ `checkbox-${fieldName}` } { ...fieldProps } />
                        <label htmlFor={ `checkbox-${fieldName}` }>{ field.label }</label>
                    </div>
                )

            case 'quantity':

                if (field.value && !getValues(fieldName)) {
                    setValue(fieldName, field.value)
                }

                return <Quantity
                    { ...fieldProps }
                    min={ field.min }
                    max={ field.max }
                />

            case 'radio-button':
                return (
                    <div className="form-radio-list">
                        { field.options.map((option, idx) => {

                            // if (option.checked) setValue(fieldName, option.value)

                            return <Radio
                                key={ idx }
                                name={ fieldName }
                                type="radio-button"
                                value={ option.value }
                                label={ option.label }
                                disabled={ field.disabled }
                                isInvalid={ fieldProps.isInvalid }
                                checked={ watch(fieldName) === option.value }
                                onChange={ (e) => {
                                    setValue(fieldName, option.value)
                                    field.onChange?.(e)
                                } }
                            />

                        }) }
                    </div>
                )

            case 'tags':
                return (
                    <TagInput
                        initialTags={ getValues(fieldName) || [] }
                        onChange={ (tags) => setValue(fieldName, tags) }
                    />
                )

            case 'custom':
                return <div className="form-custom">
                    { field.content && field.content }
                </div>

            default:
                if (field.value) setValue(fieldName, field.value)
                return <Input type={ field.type || 'text' } { ...fieldProps } autoComplete="off" />

        }
    }, [register, setValue, getValues, errors, watch])


    //////////////////////////////////////////////////////////////////////////////////
    const renderFormItem = useCallback((field, sectionIndex = null, itemIndex = 0) => {

        if (field.hidden) return null

        const fieldName = sectionIndex !== null
            ? `sections.${sectionIndex}.${field.name + `-${sectionIndex}`}`
            : field.name

        return <div key={ itemIndex } className="form-item">
            { field.type !== 'checkbox' && <label>{ field.label }</label> }
            { renderFormField(field, sectionIndex) }
            { errors?.[fieldName] && <span>{ errors[fieldName]?.message }</span> }
        </div>

    }, [renderFormField, errors])


    //////////////////////////////////////////////////////////////////
    return <form className="form" onSubmit={ handleSubmit(onSubmit) }>

        { sectionCount ? (

            fields.map((section, sectionIndex) => {

                return <div key={ section.id || sectionIndex }>

                    <TicketModalCount
                        index={ sectionIndex + 1 }
                        title={ sectionTitle }
                        onClick={ () => toggleSectionVisibility(section?.id) }
                        showIndex={ fields.length > 1 }
                        onDelete={ () => {

                            remove(sectionIndex)

                            formSchema.forEach((field) => {
                                const fieldName = `sections.${sectionIndex}.${field.name}-${sectionIndex}`
                                unregister(fieldName)
                            })
                        } }
                    />

                    <div
                        className="form-block"
                        style={ {
                            display: visibleSections?.includes(section?.id) ? 'flex' : 'none',
                        } }
                    >
                        { formSchema.map((field, index) => {

                            return Array.isArray(field) ? (
                                <div className="form-group" key={ `group-${index}` } >
                                    { field?.map((item, index) => renderFormItem(item, sectionIndex, index)) }
                                </div>
                            ) : (
                                renderFormItem(field, sectionIndex, index)
                            )

                        }) }
                    </div>

                </div>

            }
            )

        ) : (

            formSchema.map((field, index) => {

                return Array.isArray(field) ? (
                    <div className="form-group" key={ `group-${index}` } >
                        { field?.map((item, index) => renderFormItem(item, null, index)) }
                    </div>
                ) : (
                    renderFormItem(field, null, index)
                )

            })

        ) }

    </form>

})

export default FormConstructor
