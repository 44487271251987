/**
 * Checkbox Component
 * 
 * This component renders a checkbox or button that can be toggled. It supports various props for customization.
 * 
 * @component
 * @example
 * ```jsx
 * <Checkbox
 *   label="checkbox text"
 *   className="optional-class-name"
 *   name="input-name"
 *   value="input-value"
 *   checked={checkedState}
 *   onChange={changeHandler}
 *   view="checkbox|button" // optional, default: checkbox
 *   isInvalid={boolean} // optional, default: false
 * />
 * ```
 * 
 * @param {Object} props - Component properties
 * @param {boolean} [props.checked=false] - The checked state of the checkbox
 * @param {Function} props.onChange - The function to call when the checkbox state changes
 * @param {string} props.name - The name attribute for the input element
 * @param {string} [props.value='1'] - The value attribute for the input element
 * @param {string} props.label - The label text for the checkbox
 * @param {string} [props.unique] - A unique identifier for the checkbox
 * @param {string} [props.className] - Additional class names for the checkbox
 * @param {string} [props.view='checkbox'] - The view type of the component, either 'checkbox' or 'button'
 * @param {boolean} [props.disabled] - Whether the checkbox is disabled
 * @param {boolean} [props.isInvalid=false] - Whether the checkbox is in an invalid state
 * @param {boolean} [props.withExclude=false] - Whether to show the exclude option
 * @param {boolean} [props.useExclude=false] - Whether the exclude option is used
 * @param {boolean} [props.excluded=false] - The excluded state of the checkbox
 * 
 * @returns {JSX.Element} The rendered checkbox component
 */


import { memo, useCallback, useContext } from 'react'
import { AppContext } from '../../context/AppContext'
import { Icon } from '../Icon/Icon'
import './Checkbox.light.scss'

export const Checkbox = memo(({
    checked = false,
    onChange,
    name,
    value = '1',
    label,
    unique,
    className,
    view = 'checkbox',
    disabled,
    isInvalid,
    withExclude = false,
    useExclude = false,
    excluded = false
}) => {


    //////////////////////////////////
    const app = useContext(AppContext)


    //////////////////////////////////////////
    const handleClick = useCallback(event => {

        const calcChecked = () => {

            if (excluded) return false
            if (!useExclude) return !checked
            if (checked === true) return '-'
            if (checked === '-') return false
            return true

        }

        const newChecked = calcChecked()

        onChange && onChange({
            target: {
                name,
                value,
                type: 'checkbox',
                checked: newChecked
            }
        })

    }, [name, value, onChange, checked, useExclude])


    ///////////
    return <div
        className={ `
            checkbox
            checkbox-view-${view}
            ${disabled ? 'checkbox-disabled' : ''}
            ${className || ''}
            ${checked ? 'checkbox-checked' : ''}
            ${excluded || checked === '-' ? 'checkbox-excluded' : ''}
        ` }
        onClick={ handleClick }
        data-is-invalid={ isInvalid }
    >

        <div className="checkbox-icon">

            <CheckboxIcon checked={ checked } theme={ app.theme } excluded={ excluded } />

        </div>

        { label ? <div className="checkbox-label">{ label }</div> : '' }
        { withExclude && <div onClick={ (e) => {
            e.stopPropagation()
            onChange && onChange({
                target: {
                    name,
                    value,
                    type: 'checkbox',
                    checked: '-',
                    excluded: !excluded
                }
            })
        } }
            className="checkbox-exclude">
            Кроме
        </div>
        }

    </div>

})


////////////////////////////////////////////////////////
const CheckboxIcon = ({ checked, theme, excluded }) => {

    const getCheckedIcon = () => {

        const themeIcons = {
            light: <img className="checkbox" src="/icons/checkbox-checked2.svg" alt="checked icon" />,
            dark: <img className="checkbox" src="/icons/checkbox-checked2dark.svg" alt="checked icon" />
        }

        if (checked === '-' || excluded) {
            return <img
                className="checkbox"
                src={ `/icons/${theme === 'light' ? 'checkbox-excluded2' : 'checkbox-excluded2dark'}.svg` }
                alt="excluded icon"
            />
        }

        if (checked === true) {
            return themeIcons[theme] || <Icon className="checkbox" name="checkbox-unchecked2" />
        }

        return <Icon className="checkbox" name="checkbox-unchecked2" />

    }

    return getCheckedIcon()

}
