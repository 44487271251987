import { tip } from './tip'

export const globalClickHandler = async (event) => {

    let target = event.target
    const isCopyable = target.dataset.copyable
    let isFieldHidden = false

    if (!isCopyable) {
        const copyableParent = target.closest('[data-copyable]')
        if (!copyableParent) return
        target = copyableParent
    }

    if (target.tagName === 'INPUT' || target.tagName === 'TEXTAREA') {

        if (target.type === 'hidden') {
            target.type = 'text'
            isFieldHidden = true
        }

        target.select()
        target.setSelectionRange(0, 99999)
        await navigator.clipboard.writeText(target.value)

        if (isFieldHidden) {
            target.type = 'text'
        }

    } else {

        const copyContent = target.innerText
        const copyContentNoExclusions = copyContent.replace(/<span class="copy-exclude">.*?<\/span>/g, '')
        await navigator.clipboard.writeText(copyContentNoExclusions)

    }

    tip({
        target,
        content: 'Скопировано!',
        timeout: 3000
    })

}