import { everyFormItem } from './Form'

export const formStateToJson = (state, extraData) => {

    let result = {}

    everyFormItem(state, item => {

        let name = item.name
        let value

        if (item.resolveNameConflict) {
            const replaceValue = (typeof item.resolveNameConflict === 'string') ? item.resolveNameConflict : item.value
            name = name.replace(replaceValue, '')
        }

        if (item.type === 'checkbox' && item.options) {

            value = item.options
                .filter(option => option.checked)
                .map(option => {

                    const newOption = { ...option }

                    if (
                        newOption.checked === '-'
                        && !newOption.value.startsWith('-')
                    ) {

                        newOption.value = '-' + newOption.value

                    } else if (newOption.value.startsWith('-')) {

                        newOption.value = newOption.value.slice(1)

                    }

                    return newOption.value

                })

        } else if (item.type === 'checkbox-list' && item.options) {

            value = item?.options
                ?.filter(option => (option.checked || option.excluded))
                ?.map(option => (option.checked === '-' || option.excluded) ? '-' + option[item.valueParamName] : option[item.valueParamName])

        } else {

            value = item.value

        }

        if (typeof value === 'string') value = value.trim()

        if (item.type === 'radio' || item.type === 'radio-button') {
            const checkedOption = item.options.find(option => option.checked)
            if (checkedOption) value = checkedOption.value
        }

        if (!result[name]) {
            result[name] = value
        } else if (Array.isArray(result[name])) {

            if (Array.isArray(value)) {
                result[name].push(...value)
            } else {
                result[name].push(value)
            }

        } else {
            result[name] = [result[name]]
            result[name].push(value)
        }

    })

    result = { ...result, ...extraData }
    return JSON.stringify(result)

}
