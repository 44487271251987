import { memo, useCallback, useEffect, useState } from 'react'
import { msToTime } from '../../js/date'
import './Timer.scss'

/**
 * Timer component displays a countdown timer based on the provided start and end times.
 * @param {Object} props - The component props.
 * @param {Date} props.start - The start time of the timer.
 * @param {Date} props.end - The end time of the timer.
 * @param {number} props.pausedSeconds - The number of seconds the timer has been paused.
 * @param {boolean} props.isPaused - Indicates whether the timer is currently paused.
 * @param {Object} props.options - Additional options for formatting the timer display.
 * @returns {JSX.Element} The Timer component.
 */

export const Timer = memo(({ start, end, pausedSeconds, isPaused, options }) => {


    ////////////////////////////////////
    const [time, setTime] = useState('')


    ////////////////////////////////////
    const calcTime = useCallback(() => {

        let endDate

        if (end) {
            endDate = new Date(end)
        } else {
            endDate = new Date()
        }

        endDate.setSeconds(endDate.getSeconds() - pausedSeconds)

        const startDate = new Date(start)
        const diffMs = endDate - startDate

        return msToTime(diffMs, options)

    }, [start, pausedSeconds, options])


    /////////////////
    useEffect(() => {
        setTime(calcTime())
    }, [calcTime])


    /////////////////
    useEffect(() => {

        const timer = setInterval(() => {
            if (isPaused) return
            setTime(calcTime())
        }, 1000)

        return () => clearInterval(timer)

    }, [isPaused, calcTime])


    /////////////////////////////////////////////////////////////////////
    return <span className={ `timer ${isPaused ? 'timer-paused' : ''}` }>
        { time }
    </span>


})