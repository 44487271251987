import { ArcElement, Chart as ChartJS, Tooltip } from 'chart.js'
import { useContext } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { AppContext } from '../../context/AppContext'
import './OverviewChartsPorts.scss'
ChartJS.register(ArcElement, Tooltip)


const colors = [
    '#EC0011',
    'rgba(255, 0, 18, 0.6)',
    'rgba(255, 0, 18, 0.4)',
    'rgba(255, 3, 21, 0.25)',
    'rgba(255, 2, 20, 0.15)'
]


const proxyCategories = {
    static: 'Статика',
    mobile: 'Мобильные',
    resident: 'Редизентские'
}


export const OverviewChartsPorts = ({ data }) => {


    //////////////////////////////////
    const app = useContext(AppContext)
    let byCategory = []


    ///////////////////////////////////////
    for (let category in data.byCategory) {

        byCategory.push({
            category: proxyCategories[category],
            value: data.byCategory[category]
        })

    }

    byCategory.sort((a, b) => a.value < b.value)


    /////////////////////////////////////////////////////////
    const chartLabels = byCategory.map(item => item.category)
    const chartDataset = byCategory.map(item => item.value)


    ///////////////////
    const chartData = {
        labels: chartLabels,
        datasets: [
            {
                label: '# of Votes',
                data: chartDataset,
                backgroundColor: colors,
                borderWidth: 5,
                borderRadius: 10,
                borderColor: app.theme.includes('dark') ? '#262626' : '#fff',
                hoverBorderColor: app.theme.includes('dark') ? '#262626' : '#fff',
                hoverBorderWidth: 3,
                borderAlign: 'outer',
                cutout: 70
            }
        ]
    }


    //////////////////////
    const chartOptions = {
        plugins: {
            legend: {
                display: false
            }
        },
        responsive: true
    }


    ///////////////////////////////////////////////
    return (<div className="overview-charts-ports">

        <div className="overview-charts-ports-donut">
            <div className="overview-charts-ports-donut-container">

                { chartLabels.length
                    ? <Doughnut
                        options={ chartOptions }
                        data={ chartData }
                    />
                    : ''
                }

                <div className="overview-charts-ports-donut-total">{ data.active }</div>

            </div>
        </div>

        <div className="overview-charts-ports-categories">{ byCategory.map((item, itemIndex) =>

            <div key={ itemIndex } className="overview-charts-ports-category">
                <div className="overview-charts-ports-category-dot" style={ { backgroundColor: colors[itemIndex] } }></div>
                <div className="overview-charts-ports-category-label">{ item.category }</div>
                <div className="overview-charts-ports-category-value">{ item.value }</div>
            </div>

        ) }</div>

        <div className="overview-charts-ports-summary">
            <div className="overview-charts-ports-value">{ data.total }</div>
            Всего портов
        </div>

        <div className="overview-charts-ports-summary">
            <div className="overview-charts-ports-value">{ data.active }</div>
            Активные порты
        </div>

        <div className="overview-charts-ports-summary">
            <div className="overview-charts-ports-value">{ data.total - data.active }</div>
            Неактивные
        </div>

    </div>)


}