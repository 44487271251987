import React, { memo, useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon } from '../../components/Icon/Icon'
import { AuthContext } from '../../context/AuthContext'
import { AppContext } from '../../context/AppContext'
import { useApiRequest } from '../../hooks/api.request.hook'
import { matchGroups } from '../../js/lib'


/////////////////////////////////
interface RequestControlsProps {
    item: any
    updateList: () => void
    showCostAddForm: (request: any) => void
}


////////////////////////////////////////////////////////////
const RequestControls: React.FC<RequestControlsProps> = ({
    item, updateList, showCostAddForm
}) => {


    ////////////////////////////////////
    const auth = useContext(AuthContext)
    const app = useContext(AppContext)
    const apiRequest = useApiRequest()
    const navigate = useNavigate()
    const isTechLead = matchGroups(['Manager Technical'])
    const isTechRequest = item.category?.alias?.startsWith('tech')


    //////////////////////////////////////////////////////////////////////////
    const takeRequest = useCallback(async (event: any, requestId: string) => {

        event.stopPropagation()

        await apiRequest({
            patch: `/api/requests/take?id=${requestId}`,
            200: () => navigate(`/requests/${requestId}`)
        })

    }, [apiRequest, navigate])


    ///////////////////////////////////////////////////////////////////////////
    const pauseRequest = useCallback(async (event: any, requestId: string) => {

        event.stopPropagation()

        await apiRequest({
            patch: `/api/requests/pause?id=${requestId}`,
            200: updateList
        })

    }, [apiRequest, updateList])


    ////////////////////////////////////////////////////////////////////////////
    const resumeRequest = useCallback(async (event: any, requestId: string) => {

        event.stopPropagation()

        await apiRequest({
            patch: `/api/requests/resume?id=${requestId}`,
            200: updateList
        })

    }, [apiRequest, updateList])


    //////////////////
    if (!matchGroups([
        'Manager Farm',
        'Support Farm',
        'Support Proxy',
        'Support Design',
        'Manager Design',
        'Support Technical',
        'Manager Technical'
    ])) return null


    ////////////////////////////////////////////////////////////////////
    if (item.status === 'new' && item.giver?._id !== auth.data.userId) {

        return <button
            className="requests-page-item-button"
            onClick={ event => takeRequest(event, item._id) }
            data-status={ item.status }
        >
            <Icon name="play" />
            Начать
        </button>

    }


    ////
    if (
        item.status === 'paused'
        && (
            item.taker?._id === auth.data.userId
            || item.taker?.group?.name === auth.data.userGroups[0]
            || matchGroups(['Manager Design'])
            || (isTechRequest && isTechLead)
        )
    ) {
        return <button
            className="requests-page-item-button"
            onClick={ event => resumeRequest(event, item._id) }
            data-status={ item.status }
        >
            <Icon name="play" />
            Продолжить
        </button>
    }


    ////
    if (
        item.status === 'inWork'
        && (
            item.taker?._id === auth.data.userId
            || item.taker?.group?.name === auth.data.userGroups[0]
            || matchGroups(['Manager Design'])
            || (isTechRequest && isTechLead)
        )
    ) {
        return (
            <button
                className="requests-page-item-button"
                onClick={ event => pauseRequest(event, item._id) }
                data-status={ item.status }
            >
                <Icon name="pause" />
                Пауза
            </button>
        )
    }


    ////
    if (
        item.status === 'closed'
        && (
            item.taker?._id === auth.data.userId
            || item.taker?.group?.name === auth.data.userGroups[0]
        )
        && !matchGroups(['Support Farm', 'Support Design'])
    ) {
        return (
            <button
                className="requests-page-item-button"
                onClick={ () => showCostAddForm && showCostAddForm(item) }
                data-status={ item.status }
            >
                <Icon name="wallet" />
                Добавить расход
            </button>
        )
    }


    //////////////////////////////////
    if (item.status === 'approving') {
        return (
            <div className="requests-page-item-status" data-status={ item.status }>{ app.requestStatuses[item.status] }</div>
        )
    }


    ///////////
    return null


}


/////////////////////////////////////
export default memo(RequestControls)