import parseMilliseconds from 'parse-ms'

////////////////////////////////////////////
export const getFirstDayOfWeek = (date) => {
    return (new Date(date.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1))))
}


///////////////////////////////////////////
export const getLastDayOfWeek = (date) => {
    return (new Date(date.setDate(date.getDate() - date.getDay() + 7)))
}


/////////////////////////////////////////////
export const getFirstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1)
}


////////////////////////////////////////////
export const getLastDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0)
}


///////////////////////////////////////////////
export const dateToHr = (date, isShowTime) => {

    if (!date) {
        return ''
    }

    const monthsShort = {
        0: 'янв',
        1: 'фев',
        2: 'мар',
        3: 'апр',
        4: 'мая',
        5: 'июня',
        6: 'июля',
        7: 'авг',
        8: 'сен',
        9: 'окт',
        10: 'ноя',
        11: 'дек',
    }

    if (typeof date == 'string') {
        date = new Date(date)
    }

    const oneYearAgo = new Date()
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
    let result = ''

    if (date > oneYearAgo) {
        result = `${date.getDate()} ${monthsShort[date.getMonth()]}.`
    } else {
        result = formatDate(date, { time: isShowTime })
    }

    return result

}


/////////////////////////////////////////////
export const formatDate = (date, params) => {

    // params.time: bool (default: false)
    // params.year: bool (default: true)

    let result = ''

    if (!date) return result

    if (typeof date == 'string') {
        date = new Date(date)
    }

    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()
    let hours = date.getHours()
    let minutes = date.getMinutes()

    day = (day < 10) ? '0' + day : day
    month = (month < 10) ? '0' + month : month
    minutes = (minutes < 10) ? '0' + minutes : minutes

    result = `${day}.${month}`

    if (params?.year !== false) result += `.${year}`

    if (params?.time) {
        result += ` ${hours}:${minutes}`
    }

    return result

}


/////////////////////////////////////////
export const msToTime = (s, options) => {

    let ms = s % 1000
    s = (s - ms) / 1000
    let secs = s % 60
    s = (s - secs) / 60
    let mins = s % 60
    let hrs = (s - mins) / 60

    let hoursResult = (options?.hours !== false ? hrs : '') + (options?.hours !== false ? ':' : '')
    let minutesResult = (options?.minutes !== false ? (mins < 10 ? '0' : '') + mins : '') + (options?.seconds !== false ? ':' : '')
    let secondsResult = (options?.seconds !== false ? (secs < 10 ? '0' : '') + secs : '')

    return hoursResult + minutesResult + secondsResult

}


/////////////////////////////////////
export const dateToTime = (date) => {

    if (typeof date === 'string') {
        date = new Date(date)
    }

    const hours = date.getHours()
    const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()

    return `${hours}:${minutes}`

}


///////////////////////////////////////
export const msToHr = (ms, params) => {

    const parsedMs = parseMilliseconds(ms)

    return `
        
        ${parsedMs.days ? parsedMs.days + 'д' : ''}
        ${parsedMs.hours ? parsedMs.hours + 'ч' : ''}
        ${parsedMs.minutes ? parsedMs.minutes + 'м' : ''}

        ${(
            parsedMs.seconds
            && (params?.seconds || params?.seconds === undefined)
        )
            ? parsedMs.seconds + 'с'
            : ''
        }
        
    `

}