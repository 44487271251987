import { tip } from '../../js/tip'
import { isEmail, isNumber, isUrl, flattenFormState } from './Form'


////////////////////////////////////////////////////////////
export const validateForm = (state, formNode, setState) => {

    const flat = flattenFormState(state)
    const required = flat.filter(item => item.required && item.stateRef.type !== 'custom')
    const form = (typeof formNode == 'string') ? document.querySelector(formNode) : formNode

    let isErrorFocused = true // !!!!!! disabled focus, enable: false
    let isValid = true

    required.forEach(item => {

        if (item.hidden) {
            item.isInvalid = false
            return
        }

        const input = form.querySelector(`[name="${item.name}"]`)
        let tipTarget

        if (input) {

            if (item.tipTarget) {

                const closestParent = input.closest(item.tipTarget)
                tipTarget = closestParent || input.parentNode.querySelector(item.tipTarget)

            } else {
                tipTarget = input
            }

        }

        if (item.required === 'email' && !isEmail(item.value)) {

            isValid = false
            item.stateRef.isInvalid = true

            const formSectionToggle = state.find(stateItem =>
                stateItem.type === 'formSectionToggle'
                && stateItem.sectionIndex === item.stateRef.sectionIndex
            )

            if (formSectionToggle) formSectionToggle.isInvalid = true

            if (!isErrorFocused) {
                isErrorFocused = true
                input.focus()
            }

            // tip({
            //     target: tipTarget,
            //     content: 'Введите корректный email'
            // })

        } else if (
            item.required === 'string'
            && (
                (Array.isArray(item.value) && !item.value.length)
                || !item.value
            )
        ) {

            isValid = false
            item.stateRef.isInvalid = true

            const formSectionToggle = state.find(stateItem =>
                stateItem.type === 'formSectionToggle'
                && stateItem.sectionIndex === item.stateRef.sectionIndex
            )

            if (formSectionToggle) formSectionToggle.isInvalid = true

            if (!isErrorFocused) {
                isErrorFocused = true
                input.focus()
            }

            // tip({
            //     target: tipTarget,
            //     content: 'Заполните поле'
            // })

        } else if (item.required === 'password' && !item.value) {

            isValid = false
            item.stateRef.isInvalid = true

            const formSectionToggle = state.find(stateItem =>
                stateItem.type === 'formSectionToggle'
                && stateItem.sectionIndex === item.stateRef.sectionIndex
            )

            if (formSectionToggle) formSectionToggle.isInvalid = true

            if (!isErrorFocused) {
                isErrorFocused = true
                input.focus()
            }

            // tip({
            //     target: tipTarget,
            //     content: 'Введите пароль'
            // })

        } else if (item.required === 'same') {

            if (!item.requiredSame) {
                return
            }

            const sameInput = form.querySelector(`[name="${item.requiredSame}"]`)

            if (!sameInput) {
                return
            }

            if (sameInput.value !== input.value) {

                isValid = false
                item.stateRef.isInvalid = true

                const formSectionToggle = state.find(stateItem =>
                    stateItem.type === 'formSectionToggle'
                    && stateItem.sectionIndex === item.stateRef.sectionIndex
                )

                if (formSectionToggle) formSectionToggle.isInvalid = true

                if (!isErrorFocused) {
                    isErrorFocused = true
                    input.focus()
                }

                // tip({
                //     target: tipTarget,
                //     content: 'Пароли должны совпадать'
                // })

            }

        } else if (item.required === 'radio') {

            const allNameOpts = form.querySelectorAll(`input[name=${item.name}]`)
            const isAnyChecked = Array.from(allNameOpts).some(item => item.checked)
            tipTarget = (item.tipTarget) ? document.querySelector(item.tipTarget) : input?.nextElementSibling

            if (!isAnyChecked) {

                isValid = false
                item.stateRef.isInvalid = true

                const formSectionToggle = state.find(stateItem =>
                    stateItem.type === 'formSectionToggle'
                    && stateItem.sectionIndex === item.stateRef.sectionIndex
                )

                if (formSectionToggle) formSectionToggle.isInvalid = true

                // tip({
                //     target: tipTarget,
                //     content: 'Выберите один из вариантов'
                // })

            }

        } else if (item.required === 'number') {

            if (!isNumber(item.value)) {

                isValid = false
                item.stateRef.isInvalid = true

                const formSectionToggle = state.find(stateItem =>
                    stateItem.type === 'formSectionToggle'
                    && stateItem.sectionIndex === item.stateRef.sectionIndex
                )

                if (formSectionToggle) formSectionToggle.isInvalid = true

                tip({
                    target: tipTarget,
                    content: 'Значение должно быть числовым'
                })

            } else if (item.stateRef.min && item.stateRef.value < item.stateRef.min) {

                isValid = false
                item.stateRef.isInvalid = true

                tip({
                    target: tipTarget,
                    content: `Значение должно быть не менее ${item.stateRef.min}`
                })

            } else if (item.stateRef.max && item.stateRef.value > item.stateRef.max) {

                isValid = false
                item.stateRef.isInvalid = true

                tip({
                    target: tipTarget,
                    content: `Значение должно быть не более ${item.stateRef.max}`
                })

            }

        } else if (
            item.required === 'option' && (
                (Array.isArray(item.value) && !item.value.length)
                || !item.value
            )
        ) {

            isValid = false
            item.stateRef.isInvalid = true

            const formSectionToggle = state.find(stateItem =>
                stateItem.type === 'formSectionToggle'
                && stateItem.sectionIndex === item.stateRef.sectionIndex
            )

            if (formSectionToggle) formSectionToggle.isInvalid = true

            // tip({
            //     target: tipTarget,
            //     content: 'Выберите вариант'
            // })

        } else if (item.required === 'url' && !isUrl(item.value)) {

            isValid = false
            item.stateRef.isInvalid = true

            const formSectionToggle = state.find(stateItem =>
                stateItem.type === 'formSectionToggle'
                && stateItem.sectionIndex === item.stateRef.sectionIndex
            )

            if (formSectionToggle) formSectionToggle.isInvalid = true

            // tip({
            //     target: tipTarget,
            //     content: 'Введите корректный URL'
            // })

        } else if (
            item.required === 'select'
            && !item.value?.length
            && (
                !('selectedIndex' in item) || item.selectedIndex < 0
            )
        ) {

            isValid = false
            item.stateRef.isInvalid = true

            const formSectionToggle = state.find(stateItem =>
                stateItem.type === 'formSectionToggle'
                && stateItem.sectionIndex === item.stateRef.sectionIndex
            )

            if (formSectionToggle) formSectionToggle.isInvalid = true

            // tip({
            //     target: form.querySelector(`[data-name="${item.name}"] div.select`),
            //     content: 'Выберите вариант'
            // })

        } else if (item.required === 'selectOrEnter' && !item.value?.length) {

            isValid = false
            item.stateRef.isInvalid = true

            const formSectionToggle = state.find(stateItem =>
                stateItem.type === 'formSectionToggle'
                && stateItem.sectionIndex === item.stateRef.sectionIndex
            )

            if (formSectionToggle) formSectionToggle.isInvalid = true

        } else if (item.required === 'files' && !item.value?.length) {

            isValid = false
            item.stateRef.isInvalid = true

        }

    })

    const validationError = state.find(item => item.type === 'validationError')
    if (validationError) validationError.hidden = isValid

    setState && setState([...state])
    return isValid

}
