import { useCallback, useEffect, useRef, useState } from 'react'
import './ContextMenu.scss'

export const ContextMenu = ({ state, setState }) => {


    ////////////////////////////////////////
    const [style, setStyle] = useState(null)
    const [items, setItems] = useState(null)
    const contextMenuRef = useRef(null)


    ////////////////////////////////////
    const hideMenu = useCallback(() => {

        setState(null)
        window.removeEventListener('click', hideMenu)

    }, [setState])


    /////////////////
    useEffect(() => {

        if (state?.items?.length) {

            window.addEventListener('click', hideMenu)

            let newTop = ('' + state.top).replace(/px$/, '')
            let newLeft = ('' + state.left).replace(/px$/, '')

            if (+ newLeft + contextMenuRef.current?.offsetWidth > window.innerWidth) {
                newLeft = + newLeft - contextMenuRef.current?.offsetWidth
            }

            if (+ newTop + contextMenuRef.current?.offsetHeight > window.innerHeight) {
                newTop = + newTop - contextMenuRef.current?.offsetHeight
            }

            setStyle({
                top: newTop + 'px',
                left: newLeft + 'px'
            })

            setItems(() => {

                const itemsNotHidden = state.items.filter(item => !item.hidden)
                if (!itemsNotHidden) return []

                return itemsNotHidden.map((item, itemIndex) => {

                    const itemClickAction = event => {
                        item.action && item.action(event)
                    }

                    return <li
                        key={ itemIndex }
                        className="context-menu-item"
                        onClick={ itemClickAction }
                    >{ item.label }</li>

                })

            })

        }

    }, [state, hideMenu])


    //////////////////////////
    if (!items?.length) return


    //////////
    return <ul
        className={ `
            context-menu
            ${state?.items?.length ? 'context-menu-visible' : ''}
        ` }
        style={ style }
        ref={ contextMenuRef }
    >
        { items }
    </ul>

}


