import React, { useCallback, useEffect, useMemo } from 'react'
import { SubmitHandler } from 'react-hook-form'
import FormConstructor from '../FormConstructor/FormConstructor'
import Icon from '../Icon/Icon'
import { useModal } from '../Modal2/Modal2'
import { useDefaultValues } from './default.values.hook'

export const useShowGooglePurchasedFarm = (
	modalFn: any,
	formRef: any,
	submitForm: Function,
	handleSubmit: SubmitHandler<any>,
) => {


	///////////////////////////////
	const { setModal } = useModal()
	const { defaultValues, setDefaultValues } = useDefaultValues(modalFn, 'googlePurchasedFarm')


	///////////////////////////////////
	const formSchema = useMemo(
		() => [
			[
				{
					label: 'Ссылка на покупку (необязательно)',
					placeholder: 'Вставте ссылку на покупку',
					type: 'text',
					name: 'buyLink-0',
					pastable: true
				},
				{
					label: 'Необходимое количество',
					placeholder: 'Введите количество',
					type: 'quantity',
					name: 'qty-0',
					value: 1,
					min: 1,
					max: 1000,
					required: 'number'
				},
			],
			{
				label: 'Ваш комментарий (необязательно)',
				placeholder: 'Ваш комментарий',
				type: 'textarea',
				name: 'comment-0',
			},
		],
		[]
	)


	////////////////////////////////////
	const modalContent = useMemo(() => {
		return (
			<>
				<FormConstructor
					ref={ formRef }
					defaultValues={ defaultValues }
					formSchema={ formSchema }
					onSubmit={ (data) =>
						handleSubmit({
							...data,
							formSections: 1,
							social: 'google',
							category: 'googlePurchasedFarm',
						})
					}
				/>
			</>
		)
	}, [formSchema, handleSubmit, defaultValues])


	//////////////////////////////////////////////
	const showModal = useCallback(() => setModal({
		title: 'Новый тикет',
		className: 'ticket-modal ticket-modal-google-purchased-farms',
		content: modalContent,
		floating: true,
		isVisible: true,
		buttons: [
			{
				label: (
					<>
						<Icon name="chevron-left2" />
						Назад
					</>
				),
				action: modalFn.showNewTicket,
				className: 'button-plain-xl',
			},
			{
				label: 'Создать тикет',
				action: submitForm,
			},
		],
		breadcrumbs: [
			['Расходники', modalFn.showConsumables],
			['Google', modalFn.showConsumablesGoogle],
			['Покупные фармы']
		]
	}), [modalContent])


	/////////////////
	useEffect(() => {

		if (defaultValues) {
			showModal()
			setDefaultValues(null)
		}

	}, [modalContent, defaultValues, showModal, setDefaultValues])


	////////////////
	return showModal


}