import { memo } from 'react'
import { matchGroups } from '../js/lib'

export const MatchGroups = memo(({ allowed, denied, children }) => {

    if (denied && matchGroups(denied)) {
        return
    }

    if (!allowed?.length && !denied?.length) {
        return children
    }

    if (allowed && !matchGroups(allowed)) {
        return
    }

    return children

})