import { Suspense, lazy } from 'react'
import { matchGroups } from '../js/lib'
import { AuthPage } from '../pages/AuthPage/AuthPage'
import MainPage from '../pages/MainPage/MainPage'
import { OverviewPage } from '../pages/OverviewPage/OverviewPage'

const RequestsPage = lazy(() => import('../pages/RequestsPage/RequestsPage'))
const ProxyPricePage = lazy(() => import('../pages/ProxyPricePage/ProxyPricePage'))
const ProxyPage = lazy(() => import('../pages/ProxyPage/ProxyPage'))
const CostsPage = lazy(() => import('../pages/CostsPage/CostsPage'))
const NewsPage = lazy(() => import('../pages/NewsPage/NewsPage'))
const UserPage = lazy(() => import('../pages/UserPage/UserPage'))
const ShopPage = lazy(() => import('../pages/ShopPage/ShopPage'))
const AccountsLogPage = lazy(() => import('../pages/AccountsLogPage/AccountsLogPage'))
const AccountingPage = lazy(() => import('../pages/AccountingPage/AccountingPage'))
const NewsCategoriesPage = lazy(() => import('../pages/NewsPage/NewsCategoriesPage'))
const NewsSubcategoriesPage = lazy(() => import('../pages/NewsPage/NewsSubcategoriesPage'))
const UsersPage = lazy(() => import('../pages/UsersPage/UsersPage'))
const TeamsPage = lazy(() => import('../pages/TeamsPage/TeamsPage'))
const CategoriesPage = lazy(() => import('../pages/CategoriesPage/CategoriesPage'))
const GlobalCategoriesPage = lazy(() => import('../pages/GlobalCategoriesPage/GlobalCategoriesPage'))
const OperatorsPage = lazy(() => import('../pages/OperatorsPage/OperatorsPage'))
const AgenciesPage = lazy(() => import('../pages/AgenciesPage/AgenciesPage'))
const SellersPage = lazy(() => import('../pages/SellersPage/SellersPage'))
const GeosPage = lazy(() => import('../pages/GeosPage/GeosPage'))
const AccountTypesPage = lazy(() => import('../pages/AccountTypesPage/AccountTypesPage'))
const TechTopicsPage = lazy(() => import('../pages/TechTopicsPage/TechTopicsPage'))
const AgencyAccountsPage = lazy(() => import('../pages/AgencyAccountsPage/AgencyAccountsPage'))
const AgencyAccountsHistoryPage = lazy(() => import('../pages/AgencyAccountsHistoryPage/AgencyAccountsHistoryPage'))
const AgencyAccountsReviewsPage = lazy(() => import('../pages/AgencyAccountsReviewsPage/AgencyAccountsReviewsPage'))
const DesignsPage = lazy(() => import('../pages/DesignsPage/DesignsPage'))


////////////////////////////
const FallbackPage = () => (
    <div className="fallback-div" >
        <p>Something went wrong.</p>
        <button
            onClick={ () => window.location.reload() }
            className="button-default-xl"
        >Reload page</button>
    </div>
)


/////////////////////////////////////////////////////////////////////////
const renderWithSuspense = (Component, isAllowed = true, props = {}) => {

    if (!isAllowed) {
        return null
    }

    /////////////////
    return <Suspense>
        <Component { ...props } />
    </Suspense>

}


///////////////////////////
export const authRoutes = [
    {
        path: '/*',
        element: <MainPage />,
        errorElement: <FallbackPage />,
        children: [
            ////////////////////////USER PAGE/////////////////////////////////////////////////////////////////////////////////
            { path: 'user', element: renderWithSuspense(UserPage) },
            ///////////////////////OVERVIEW PAGE//////////////////////////////////////////////////////////////////////////////
            { index: true, element: renderWithSuspense(OverviewPage) },
            //////////////////////REQUESTS PAGE///////////////////////////////////////////////////////////////////////////////
            {
                path: 'requests',
                element: renderWithSuspense(RequestsPage),
                children: [
                    { path: ':urlRequestId', element: renderWithSuspense(RequestsPage) }
                ]
            },
            {
                path: 'archive',
                element: renderWithSuspense(RequestsPage, true, { isArchive: true }),
                children: [
                    { path: ':urlRequestId', element: renderWithSuspense(RequestsPage, true, { isArchive: true }) }
                ]
            },
            /////////////////////////////////PROXY PAGE////////////////////////////////////////////////////////////////////////
            {
                path: 'proxy',
                element: renderWithSuspense(
                    ProxyPage,
                    matchGroups(['Support Proxy', 'Buyer', 'Support Farm', 'Admin', 'Team Lead'])
                )
            },
            {
                path: 'proxy/price',
                element: renderWithSuspense(
                    ProxyPricePage,
                    matchGroups(['Support Proxy', 'Admin'])
                ),
            },
            /////////////////////////////////COSTS PAGE/////////////////////////////////////////////////////////////////////////
            {
                path: 'costs',
                element: renderWithSuspense(CostsPage, !matchGroups(['Support Farm', 'Support Technical', 'Manager Technical']))
            },
            ///////////////////////////////NEWS PAGE////////////////////////////////////////////////////////////////////////////
            {
                path: 'news',
                element: renderWithSuspense(NewsPage),
                children: [
                    { path: ':newsId', element: renderWithSuspense(NewsPage) },
                ]
            },
            { path: 'news/categories', element: renderWithSuspense(NewsCategoriesPage, matchGroups(['Admin'])) },
            { path: 'news/subcategories', element: renderWithSuspense(NewsSubcategoriesPage, matchGroups(['Admin'])) },
            ///////////////////////////////SHOP PAGE////////////////////////////////////////////////////////////////////////////
            {
                path: 'shop',
                element: renderWithSuspense(ShopPage, matchGroups(['Admin', 'Manager Farm', 'Buyer'])),
            },
            { path: 'shop/purchases', element: renderWithSuspense(AccountsLogPage, matchGroups(['Buyer', 'Admin'])) },
            { path: 'shop/accounts-log', element: renderWithSuspense(AccountsLogPage, matchGroups(['Admin'])) },
            { path: 'shop/accounting', element: renderWithSuspense(AccountingPage, matchGroups(['Admin'])) },
            ///////////////////////////////SETTINGS PAGE////////////////////////////////////////////////////////////////////////
            {
                path: 'settings',
                children: [
                    { path: 'users', index: true, element: renderWithSuspense(UsersPage, matchGroups(['Admin'])) },
                    { path: 'teams', element: renderWithSuspense(TeamsPage, matchGroups(['Admin'])) },
                    { path: 'categories', element: renderWithSuspense(CategoriesPage, matchGroups(['Admin'])) },
                    { path: 'globalcategories', element: renderWithSuspense(GlobalCategoriesPage, matchGroups(['Admin'])) },
                    { path: 'agencies', element: renderWithSuspense(AgenciesPage, matchGroups(['Admin'])) },
                    { path: 'operators', element: renderWithSuspense(OperatorsPage, matchGroups(['Admin', 'Support Proxy'])) },
                    { path: 'sellers', element: renderWithSuspense(SellersPage, matchGroups(['Admin', 'Support Proxy'])) },
                    { path: 'geos', element: renderWithSuspense(GeosPage, matchGroups(['Admin', 'Support Proxy'])) },
                    { path: 'account-types', element: renderWithSuspense(AccountTypesPage, matchGroups(['Admin', 'Manager Farm'])) },
                    { path: 'tech-topics', element: renderWithSuspense(TechTopicsPage, matchGroups(['Admin', 'Manager Technical'])) }
                ]
            },
            ///////////////////////////////AGENCY ACCOUNTS PAGE//////////////////////////////////////////////////////////////////
            {
                path: 'agency-accounts',
                element: renderWithSuspense(AgencyAccountsPage, !matchGroups(['Support Proxy'])),
                children: [
                    {
                        path: ':urlAgencyAccountId',
                        element: renderWithSuspense(AgencyAccountsPage, !matchGroups(['Support Proxy'])),
                        children: [
                            { path: ':urlRequestId', element: renderWithSuspense(AgencyAccountsPage, !matchGroups(['Support Proxy'])) }
                        ]
                    },
                ]
            },
            { path: 'agency-accounts/history', element: renderWithSuspense(AgencyAccountsHistoryPage, !matchGroups(['Support Proxy'])) },
            { path: 'agency-accounts/reviews', element: renderWithSuspense(AgencyAccountsReviewsPage, !matchGroups(['Support Proxy'])) },
            ///////////////////////////////DESIGNS PAGE//////////////////////////////////////////////////////////////////////////
            { path: 'designs', element: renderWithSuspense(DesignsPage) },
        ]
    }
]


//////////////////////////////
export const nonAuthRoutes = [
    { path: '/*', element: <AuthPage /> }
]
