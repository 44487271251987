import { Fragment, memo, useCallback, useEffect, useState } from 'react'
import { hideTips } from '../../js/tip'
import { Icon } from '../Icon/Icon'
import './Modal.light.scss'

/**
 * Modal component that displays a modal dialog with customizable content and buttons.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.content - The content to be displayed in the modal.
 * @param {string} props.contentHTML - The HTML content to be displayed in the modal.
 * @param {boolean} props.isVisible - Determines whether the modal is visible or hidden.
 * @param {function} props.setVisible - Callback function to set the visibility of the modal.
 * @param {string} props.className - Additional CSS class name for the modal container.
 * @param {Array} props.buttons - An array of button objects to be displayed in the modal footer.
 * @param {string} props.title - The title of the modal.
 * @param {React.ReactNode} props.headerContent - Additional content to be displayed in the modal header.
 * @param {boolean} props.closeButton - Determines whether the close button is displayed in the modal header.
 * @param {function} props.onVisibilityChange - Callback function triggered when the visibility of the modal changes.
 * @param {function} props.onClick - Callback function triggered when the modal is clicked.
 * @returns {JSX.Element} The rendered Modal component.
 */

export const Modal = memo(({
    content,
    contentHTML,
    isVisible,
    setVisible,
    className,
    buttons,
    title,
    headerContent,
    closeButton = true,
    onVisibilityChange,
    onClick = event => event.stopPropagation()
}) => {


    ///////////////////////////////////////////////////////////
    const [wasModalOpened, setWasModalOpened] = useState(false)


    /////////////////
    useEffect(() => {

        if (isVisible) {
            setWasModalOpened(isVisible)
        } else if (className) {
            hideTips(`.${className}`)
        }

    }, [isVisible, className])


    /////////////////
    useEffect(() => {
        onVisibilityChange && onVisibilityChange(isVisible)
    }, [isVisible])


    //////////////
    if (buttons) {

        buttons = buttons.filter(button => !button.hidden)

        buttons = buttons.map((item, index) => {

            if (item.type === 'custom') {
                return <Fragment key={ index }>{ item.content }</Fragment>
            }

            const btnClass = item.className || 'button-default-xl'

            return <button
                disabled={ item?.disabled }
                className={ btnClass }
                onClick={ (event) => {
                    event.preventDefault()
                    item.action && item.action(event)
                } }
                key={ index }
            ><label>{ item.title }</label></button>

        })

    }


    /////////////////
    useEffect(() => {

        if (isVisible) {
            document.body.classList.add('is-modal-visible')
        } else {
            window.scrollTo(0, 0)
            document.body.classList.remove('is-modal-visible')
        }

    }, [isVisible])


    //////////////////////////////////////////
    const hideModal = useCallback((event) => {

        event?.preventDefault()
        event?.stopPropagation()
        setVisible(false)

    }, [setVisible])


    /////////////////
    useEffect(() => {

        if (document.body.dataset.isAppAddEscSet) {
            return
        }

        document.body.addEventListener('keyup', (event) => {

            if (event.key === 'Escape') {
                hideModal()
            }

        })

        document.body.dataset.isAppAddEscSet = true

    }, [hideModal])


    //////////////////////////////////////////////////////////////////////////////////
    return <div className={ (className || '') + (isVisible ? ' modal-visible' : '') }>

        <div className="modal" onClick={ onClick }>

            {
                title || headerContent || closeButton
                    ? <div className="modal-header">

                        { title ? <div className="modal-title">{ title }</div> : '' }
                        { headerContent ? <div className="modal-header-content">{ headerContent }</div> : '' }
                        { closeButton ? <div className="modal-close" onClick={ hideModal }><Icon name="close" /></div> : '' }

                    </div>
                    : ''
            }

            { content ? <div className="modal-content">{ content }</div> : '' }
            { contentHTML ? <div className="modal-content" dangerouslySetInnerHTML={ { __html: contentHTML } }></div> : '' }
            { buttons ? <div className="modal-footer">{ buttons }</div> : '' }

        </div>

        <div className="shade" onClick={ hideModal }></div>

    </div>


})