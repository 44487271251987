import { useCallback, useEffect, useMemo } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useModal } from '../Modal2/Modal2'
import React from 'react'
import FormConstructor from '../FormConstructor/FormConstructor'
import { useDefaultValues } from './default.values.hook'

export const useShowFacebookAutoregs = (
    modalFn: any,
    formRef: any,
    submitForm: Function,
    handleSubmit: SubmitHandler<any>,
) => {


    ///////////////////////////////
    const { setModal } = useModal()
    const { defaultValues, setDefaultValues } = useDefaultValues(modalFn, 'autoregs')


    ///////////////////////////
    const formSchema = useMemo(
        () => [
            [
                {
                    label: 'Ссылка на покупку (необязательно)',
                    placeholder: 'Вставте ссылку на покупку',
                    type: 'text',
                    name: 'buyLink',
                    pastable: true
                },
                {
                    label: 'Необходимое количество',
                    placeholder: 'Введите количество',
                    type: 'quantity',
                    name: 'qty',
                    value: 1,
                    min: 1,
                    max: 1000,
                    required: 'number'
                }
            ],
            {
                label: 'Ваш комментарий (необязательно)',
                placeholder: 'Ваш комментарий',
                type: 'textarea',
                name: 'comment',
            },
        ],
        []
    )


    ////////////////////////////////////
    const modalContent = useMemo(() => {
        return (
            <>
                <FormConstructor
                    ref={ formRef }
                    formSchema={ formSchema }
                    defaultValues={ defaultValues }
                    sectionTitle="Автореги"
                    sectionCount={ 5 }
                    onSubmit={ (data) =>
                        handleSubmit({
                            ...data,
                            social: 'facebook',
                            category: 'autoregs',
                        })
                    }
                />
            </>
        )
    }, [formSchema, handleSubmit, formRef.current, defaultValues])

    /////////////////////////////////////////////
    const showModal = useCallback(() => setModal({
        title: 'Новый тикет',
        className: 'ticket-modal ticket-modal-autoregs',
        content: modalContent,
        floating: true,
        isVisible: true,
        buttons: [
            {
                icon: 'plus',
                label: 'Еще один тикет',
                action: () => formRef.current?.append(),
                className: 'button-plain-xl',
            },
            {
                label: 'Создать тикет',
                action: submitForm,
            },
        ],
        breadcrumbs: [
            ['Расходники', modalFn.showConsumables],
            ['Facebook', modalFn.showConsumablesFacebook],
            ['Автореги']
        ]
    }), [modalContent])

    /////////////////
    useEffect(() => {

        if (defaultValues) {
            showModal()
            setDefaultValues(null)
        }

    }, [modalContent, defaultValues, showModal, setDefaultValues])


    ////////////////
    return showModal

}