import { useMemo } from 'react'
import { useTicketModalConstructor } from './ticket.modal.constructor'

export const useShowConsumablesAgency = (modalFn: any) => {


    ///////////////////////////////////////////
    const ticketModalButtons = useMemo(() => [{
        name: 'replace-0',
        label: 'Замена',
        action: 'showAgencyReplace'
    }, {
        name: 'report-0',
        label: 'Репорт',
        action: 'showAgencyReport'
    }, {
        name: 'new-0',
        label: 'Новый',
        action: 'showAgencyNew'
    }], [])


    //////////////////////////////////
    return useTicketModalConstructor({
        title: 'Новый тикет',
        name: 'consumables-agency',
        ticketModalButtons,
        breadcrumbs: [
            ['Расходники', modalFn.showConsumables],
            ['Agency']
        ],
        modalFn,
        backAction: 'showConsumables'
    })


}
