import { useMemo } from 'react'
import { useTicketModalConstructor } from './ticket.modal.constructor'

export const useShowNewTicket = (modalFn: any) => {


    ///////////////////////////////////////////
    const ticketModalButtons = useMemo(() => [{
        name: 'consumables',
        icon: 'consumables',
        label: 'Расходники',
        action: 'showConsumables'
    }, {
        name: 'tech',
        icon: 'techSupport',
        label: 'Техническая помощь',
        action: 'showTech',
        //        matchTeams: ['Sem', 'Midas Team', 'Performance']
    }], [])


    //////////////////////////////////
    return useTicketModalConstructor({
        title: 'Новый тикет',
        name: 'new-ticket',
        size: 'large',
        ticketModalButtons,
        modalFn,
        showSearch: true
    })


}
