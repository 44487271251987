import axios from 'axios'
import { getBackendUrl } from '../js/lib'

axios.defaults.baseURL = getBackendUrl()

const axiosInstance = axios.create()

axiosInstance.interceptors.request.use(
  config => {
    const storageData = JSON.parse(localStorage.getItem('authData') || '{}')


    if (storageData?.accessToken) {
      config.headers['x-access-token'] = storageData.accessToken;
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error)
  }
)

export default axiosInstance
