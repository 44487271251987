import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { AppContext } from '../../context/AppContext'
import { useContext } from 'react'

ChartJS.register(ArcElement, Tooltip)

export const OverviewChartsToday = ({ data }) => {


    //////////////////////////////////
    const app = useContext(AppContext)
    const targetHoursPerDay = 8
    const targetHoursPerWeek = 40
    const todayHoursSpent = data.todayMsSpent / 1000 / 60 / 60
    const todayEfficiency = Math.round(todayHoursSpent * 100 / targetHoursPerDay)
    const chartEfficiency = todayEfficiency < 100 ? todayEfficiency : 100
    const chartInefficiency = 100 - chartEfficiency
    const todayHoursOnly = Math.floor(todayHoursSpent)
    const todayMinutesOnly = Math.floor((todayHoursSpent - todayHoursOnly) * 60 / 1)
    const todayTimeSpent = `${todayHoursOnly}ч ${todayMinutesOnly}м`

    const thisWeekHoursSpent = data.thisWeekMsSpent / 1000 / 60 / 60
    const thisWeekEfficiency = Math.round(thisWeekHoursSpent * 100 / targetHoursPerWeek)
    const prevWeekHoursSpent = data.prevWeekMsSpent / 1000 / 60 / 60
    const prevWeekEfficiency = Math.round(prevWeekHoursSpent * 100 / targetHoursPerWeek)
    const weekEfficiencyDiff = thisWeekEfficiency - prevWeekEfficiency


    ////////////////
    const colors = [
        '#EC0011',
        '#f1f3f4'
    ]


    ///////////////////
    const chartData = {
        labels: ['', ''],
        datasets: [
            {
                label: '# of Votes',
                data: [chartEfficiency, chartInefficiency],
                backgroundColor: colors,
                borderWidth: 0,
                borderRadius: 10,
                borderColor: app.theme.includes('dark') ? '#262626' : '#fff',
                hoverBorderColor: app.theme.includes('dark') ? '#262626' : '#fff',
                borderAlign: 'outer',
                cutout: 110
            }
        ]
    }


    //////////////////////
    const chartOptions = {
        plugins: {
            legend: {
                display: false
            }
        }
    }


    //////////////////////////////
    const RenderWeekDiff = () => {

        if (weekEfficiencyDiff > 0) {

            return (<>
                <strong>На { weekEfficiencyDiff }% выше</strong>, чем на прошлой неделе
            </>)

        } else if (weekEfficiencyDiff === 0) {

            return (<>
                <strong>Так же</strong>, как на прошлой неделе
            </>)

        } else {

            return (<>
                <strong>На { weekEfficiencyDiff }% ниже</strong>, чем на прошлой неделе
            </>)

        }

    }


    /////////////////////////////////////////////////
    return (<div className="overview-charts-typical">

        <div className="overview-charts-typical-donut">

            <div className="overview-charts-typical-donut-container">

                <Doughnut
                    options={ chartOptions }
                    data={ chartData }
                />

                { todayTimeSpent ? <div className="overview-charts-typical-total">{ todayTimeSpent }</div> : '' }

            </div>

            <div className="overview-charts-typical-target">Цель: { targetHoursPerDay } часов</div>

        </div>
        <div className="overview-charts-typical-details">
            <div className="overview-charts-typical-text">Ваша эффективность:</div>
            <div className="overview-charts-typical-value">{ thisWeekEfficiency }%</div>
            <div className="overview-charts-typical-text"><RenderWeekDiff /></div>
        </div>

    </div>)

}