import { getBackendUrl, getContentPath } from './lib'

/////////////////////////////////////////////////
export const formatFileSize = (bytes, sizes) => {

    if (!Array.isArray(sizes) || !sizes.length) {
        sizes = [
            'Б',
            'КБ',
            'МБ',
            'ГБ',
            'ТБ'
        ]
    }

    if (bytes === 0) return '0 ' + sizes[0]

    const decimals = 1
    const k = 1024
    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i]

}


/////////////////////////////////////////
export const getFileExt = (filename) => {

    if (!filename) return ''
    const dotPos = filename.lastIndexOf('.')
    return filename.substring(dotPos + 1)

}


////////////////////////////////////////////
export const getFileBaseName = filepath => {

    if (!filepath) return ''
    return filepath.split('/').pop()

}


///////////////////////////////////
export const getDirName = path => {

    if (!path) return ''
    return path.split('/').slice(0, -1).join('/')

}


/////////////////////////////////////////////////////////////////////////////////////////
export const downloadFile = async (dir, id, storedName, origName, accessToken, size) => {
    const BIG_FILE_SIZE_MB = 500

    const contentPath = getContentPath(dir, id)
    let url = '/api/files/download'
    url += `?contentPath=${contentPath}`
    url += `&accessToken=${accessToken}`
    url += `&storedName=${encodeURIComponent(storedName)}`
    url += `&origName=${encodeURIComponent(origName)}`

    if (size > BIG_FILE_SIZE_MB) {
        window.open(getBackendUrl(url))
    } else {
        const response = await fetch(getBackendUrl(url))
        const blob = await response.blob()
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = origName
        document.body.appendChild(link)
        link.click()
        link.remove()
    }
}


////////////////////////////////////////////////////////////////////////////////////////////
export const downloadArchiveFiles = (dirName, subDirName, files, requestId, accessToken) => {

    const queryParams = new URLSearchParams()
    queryParams.append('requestId', requestId)
    queryParams.append('accessToken', accessToken)

    files.forEach(file => {
        queryParams.append('file', `${dirName},${subDirName},${file.name}`)
    })

    const url = `/api/${dirName}/download?${queryParams.toString()}`
    window.open(getBackendUrl(url))

}



/////////////////////////////////////////////////////
export const downloadTextFile = (text, filename) => {

    const blob = new Blob([text], { type: 'text/plain' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = filename
    link.href = url
    link.click()

}


//////////////////////////////////////
export const justExt = (filename) => {
    if (!filename) return ''
    return filename.split('.').pop()
}


//////////////////////////////////////
export const isImage = (filename) => {

    const ext = getFileExt(filename).toLowerCase()

    return [
        'jpg',
        'jpeg',
        'png',
        'gif',
        'bmp',
        'svg',
        'webp',
        'ico',
        'tif',
        'tiff',
        'psd'
    ].includes(ext)

}


//////////////////////////////////////
export const isVideo = (filename) => {

    const ext = getFileExt(filename).toLowerCase()

    return [
        'mp4',
        'avi',
        'mov',
        'webm',
        'mkv',
        'flv',
        'vob',
        'ogv',
        'ogg',
        'drc',
        'gifv',
        'mng',
        'mts',
        'm2ts',
        'ts',
        'qt',
        'wmv',
        'yuv',
        'rm',
        'rmvb',
        'asf',
        'amv',
        'mpg',
        'mp2',
        'mpeg',
        'mpe',
        'mpv',
        'm2v',
        'svi',
        '3gp',
        '3g2',
        'mxf',
        'roq',
        'nsv'
    ].includes(ext)

}


///////////////////////////////////////
export const justName = (filename) => {
    if (!filename) return ''
    return filename.split('.').shift()
}


///////////////////////////////////////
export const getFileName = (input) => {

    if (!input) return ''
    const basename = getFileBaseName(input)
    return basename.split('.').shift()

}


//////////////////////////////////////////////////////
export const shortenFileName = (fileName, length) => {

    const threeDots = '...'
    const justFileName = justName(fileName)
    if (justFileName.length <= length) return fileName

    const startEndLength = Math.floor((length - threeDots.length) / 2)
    const start = justFileName.substring(0, startEndLength)
    const end = justFileName.substring(justFileName.length - startEndLength)

    return `${start}…${end}.${justExt(fileName)}`

}