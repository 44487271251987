import React, {
    useCallback, useEffect, useMemo
} from 'react'
import { SubmitHandler } from 'react-hook-form'
import FormConstructor from '../FormConstructor/FormConstructor'
import { useModal } from '../Modal2/Modal2'
import { Uploader } from '../Uploader/Uploader'
import { useDefaultValues } from './default.values.hook'

export const useShowFacebookOther = (
    modalFn: any,
    formRef: any,
    submitForm: Function,
    handleSubmit: SubmitHandler<any>,
    setStartUpload: any
) => {


    ///////////////////////////////
    const { setModal } = useModal()
    const { defaultValues, setDefaultValues } = useDefaultValues(modalFn, 'facebookOther')


    ///////////////////////////////////
    const formSchema = useMemo(() => [{
        label: 'Описание задачи',
        placeholder: 'Опишите задачу',
        type: 'textarea',
        name: 'comment',
        validation: { required: true }
    }], [])


    ////////////////////////////////////
    const modalContent = useMemo(() => {
        return (
            <>
                <FormConstructor
                    ref={ formRef }
                    formSchema={ formSchema }
                    defaultValues={ defaultValues }
                    sectionTitle="Другое"
                    sectionCount={ 5 }
                    onSubmit={ (data) =>
                        handleSubmit({
                            ...data,
                            social: 'facebook',
                            category: 'facebookOther',
                        })
                    }
                />

                <div className="ticket-modal-heading">Прикрепить файл</div>

                <Uploader
                    url="/api/files"
                    postData={ { type: 'ticket' } }
                    setStartUpload={ setStartUpload }
                    name="file-0"
                />

            </>
        )
    }, [formSchema, handleSubmit, defaultValues])


    /////////////////////////////////////
    const showModal = useCallback(() => {

        return setModal({
            title: 'Новый тикет',
            className: 'ticket-modal ticket-modal-facebook-other',
            content: modalContent,
            floating: true,
            isVisible: true,
            buttons: [{
                icon: 'chevron-left',
                label: 'Назад',
                action: modalFn.showConsumablesFacebook,
                className: 'button-plain-xl',
            }, {
                label: 'Создать тикет',
                action: submitForm,
            }],
            breadcrumbs: [
                ['Расходники', modalFn.showConsumables],
                ['Facebook', modalFn.showConsumablesFacebook]
            ]
        })

    }, [modalContent, submitForm])


    /////////////////
    useEffect(() => {
        if (defaultValues) {
            showModal()
            setDefaultValues(null)
        }
    }, [modalContent, defaultValues, showModal, setDefaultValues])


    ////////////////
    return showModal


}
