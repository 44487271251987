import { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { SocketContext } from '../../context/SocketContext'
import { matchGroups, updateStateArray } from '../../js/lib'
import { RequestsPageList } from '../RequestsPage/RequestPageList/RequestsPageList'
import './OverviewRequests.light.scss'
export const OverviewRequests = () => {


    ////////////////////////////////////////////
    const [requests, setRequests] = useState([])
    const [isTasksFiltered, setTasksFiltered] = useState(false)
    const [isRequestsFiltered, setRequestsFiltered] = useState(false)
    const auth = useContext(AuthContext)
    const navigate = useNavigate()
    const requestsTitle = auth.data.userGroups.includes('Buyer') ? 'Активные' : 'Доступные'
    const socket = useContext(SocketContext)
    const [currentSocketRequestId, setCurrentSocketRequestId] = useState(null)


    //////////////////////////////////////////////////
    const renderRequests = useCallback(async data => {

        if (data.socketRequestId !== currentSocketRequestId) return

        if (data?.requests?.length || data?.resetList) {

            updateStateArray({
                setState: setRequests,
                updatedArray: data.requests,
                resetList: data.resetList
            })

        }

    }, [currentSocketRequestId])


    /////////////////
    useEffect(() => {

        if (!socket || !socket.on || !socket.off) return

        socket.off('requests update', renderRequests)
        socket.on('requests update', renderRequests)

    }, [socket, renderRequests])


    //////////////////////////////////////////
    const updateRequests = useCallback(() => {

        if (!socket || !socket.emit) return

        const socketRequestId = Date.now()
        setCurrentSocketRequestId(socketRequestId)

        socket.emit('update requests', {
            socketRequestId
        })

    }, [socket])


    ///////////////////////////////////////////////////////
    useEffect(() => { updateRequests() }, [updateRequests])


    /////////////////
    useEffect(() => {

        if (!socket) return

        return () => {
            socket.off('requests update')
            socket.emit('leave requests')
        }

    }, [socket])


    //////////////////////////////////////////
    const toggleFilter = useCallback(type => {

        if (type === 'tasks') {
            setTasksFiltered(!isTasksFiltered)
        } else if (type === 'requests') {
            setRequestsFiltered(!isRequestsFiltered)
        }

    }, [isTasksFiltered, isRequestsFiltered])


    //////////////////////////////////////////
    return <div className="overview-requests">

        <div className="overview-header">

            <div className="overview-title" onClick={ () => navigate('/requests/') }>
                { requestsTitle }
            </div>

            {
                !matchGroups(['Support Farm', 'Support Proxy'])
                    ? <div className="overview-header-filters">
                        <div
                            className={ `button-outline-xs ${isTasksFiltered ? 'button-outline-xs-active' : ''}` }
                            onClick={ () => toggleFilter('tasks') }
                        >
                            Задачи
                        </div>
                        <div
                            className={ `button-outline-xs ${isRequestsFiltered ? 'button-outline-xs-active' : ''}` }
                            onClick={ () => toggleFilter('requests') }
                        >
                            Запросы
                        </div>
                    </div>
                    : ''
            }

        </div>

        <RequestsPageList list={ requests } />

    </div>


}