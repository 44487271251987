import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { Uploader } from '../Uploader/Uploader'
import { AppContext } from '../../context/AppContext'
import { useApiRequest } from '../../hooks/api.request.hook'
import { matchGroups } from '../../js/lib'
import FormConstructor from '../FormConstructor/FormConstructor'
import Icon from '../Icon/Icon'
import { formatFileSize } from '../../js/file'
import { updateFormSchema } from '../FormConstructor/updateFormSchema'
import { useModal } from '../Modal2/Modal2'

export const useShowAgencyReplace = (
    modalFn: any,
    formRef: any,
    submitForm: Function,
    handleSubmit: SubmitHandler<any>,
    setStartUpload: any
) => {


    ///////////////////////////////////////////
    const { setModal, modalState } = useModal()
    const apiRequest = useApiRequest()
    const app = useContext(AppContext)
    const [resultFiles, setResultFiles] = useState<any>([])
    const [shouldUpdateModal, setShouldUpdateModal] = useState(false)
    const [defaultValues, setDefaultValues] = useState({})


    /////////////////
    useEffect(() => {

        if (!modalFn.agencyReplace) return
        setDefaultValues(modalFn.agencyReplace)
        setShouldUpdateModal(true)
        delete modalFn.agencyReplace

    }, [modalFn.agencyReplace])


    /////////////////////////////////////////////////////////////
    const updateSocAccounts = useCallback(async (event: any) => {

        const agencyId = event.target.value?.toString()
        if (!agencyId) return

        await apiRequest({
            get: `/api/agencies/accounts/soc?agencyId=${agencyId}`,
            200: (response: any) => {

                if (!response.socAccounts.length) {

                    updateFormSchema(setFormSchema, {
                        'socAccount': { options: [] }
                    })

                    setShouldUpdateModal(true)
                    return

                }

                const newAccounts = response.socAccounts.map((account: any) => {
                    return {
                        label: account.id,
                        value: account.id
                    }
                })

                updateFormSchema(setFormSchema, {
                    'socAccount': {
                        options: newAccounts,
                        placeholder: 'Начните вводить соц. аккаунт'
                    }
                })

                setShouldUpdateModal(true)

            }
        })

    }, [setShouldUpdateModal])


    /////////////////////////////////////////////////////////////
    const updateAdsAccounts = useCallback(async (event: any) => {

        const socAccountId = event.target.value?.toString()
        if (!socAccountId) return

        await apiRequest({
            get: `/api/agencies/accounts/ads?socAccountId=${socAccountId}`,
            200: (response: any) => {

                if (!response.adsAccounts.length) {

                    updateFormSchema(setFormSchema, {
                        'adsAccount': { options: [] }
                    })

                    setShouldUpdateModal(true)
                    return

                }

                const newAccounts = response.adsAccounts.map((account: any) => {
                    return {
                        label: account.id,
                        value: account.id
                    }
                })

                updateFormSchema(setFormSchema, {
                    'adsAccount': {
                        options: newAccounts,
                        placeholder: 'Начните вводить РК'
                    }
                })

                setShouldUpdateModal(true)

            }
        })

    }, [setShouldUpdateModal])


    /////////////////
    useEffect(() => {

        if (!app.agencies) return

        const newAgencies = app.agencies?.filter((agency: any) =>
            agency.isHaveMyAgencyAccount || matchGroups(['Admin']))
            || []

        updateFormSchema(setFormSchema, {
            'agency': { options: newAgencies }
        })

    }, [app.agencies])


    ///////////////////////////////////////////////////////////////////////
    const [formSchema, setFormSchema] = useState<Array<Array<any> | any>>([
        [{
            label: 'Агентство',
            placeholder: 'Начните вводить агентство',
            type: 'select',
            name: 'agency',
            autocomplete: true,
            options: [],
            validation: { required: true },
            labelParamName: 'name',
            valueParamName: '_id',
            onChange: updateSocAccounts
        }, {
            label: 'Соц. аккаунт',
            placeholder: 'Сначала выберите агентство',
            type: 'select',
            autocomplete: true,
            name: 'socAccount',
            options: [],
            validation: { required: true },
            onChange: updateAdsAccounts
        }], [{
            label: 'Рекламный кабинет',
            type: 'select',
            name: 'adsAccount',
            options: [],
            placeholder: 'Сначала выберите соц. аккаунт',
            multiple: true
        }, {
            label: 'Причина замены',
            type: 'select',
            name: 'reason',
            placeholder: 'Выберите причину замены',
            options: [
                { label: 'Бан РК', value: 'banAdsAcc' },
                { label: 'Лимит 25', value: 'limit25' },
                { label: 'Рикс Пеймент', value: 'rixPayment' },
                { label: 'Полис', value: 'polis' },
                { label: 'ЗРД', value: 'zrd' },
                { label: 'Disable', value: 'disable' },
                { label: 'Свой вариант (в комментарии)', value: 'custom' }
            ],
            validation: { required: true }
        }], {
            type: 'checkbox',
            name: 'deleteAccount',
            label: 'Я не хочу получать замену, удалите кабинет.'
        }, {
            label: 'Ваш комментарий',
            placeholder: 'Напишите Ваш комментарий',
            type: 'textarea',
            name: 'comment'
        }
    ])

    // useEffect(() => {
    //     console.log('formSchema', formSchema[0][1])
    // }, [formSchema])

    ////////////////////////////////////
    const modalContent = useMemo(() => {

        return <>

            <FormConstructor
                ref={ formRef }
                formSchema={ formSchema }
                defaultValues={ defaultValues }
                onSubmit={ data => handleSubmit({
                    ...data,
                    formSections: 1,
                    social: 'agency',
                    category: 'agencyReplace'
                }) }
            />

            <div className="ticket-modal-heading">Прикрепить файл</div>

            <div className="ticket-modal-files">
                { resultFiles?.map((result: any) =>
                    <div className="ticket-details-file" key={ result?.name }>
                        <span>
                            <div className="ticket-details-file-name">{ result?.name }</div>
                            <div className="ticket-details-file-size">{ formatFileSize(result?.size) }</div>
                        </span>
                        <Icon onClick={ () => {
                            setResultFiles((prev: any) => prev.filter((item: any) => item?.name !== result?.name))
                            setShouldUpdateModal(true)
                        } } name="close" />
                    </div>
                ) }
            </div>

            <Uploader
                url="/api/files"
                setStartUpload={ setStartUpload }
                resultFiles={ resultFiles }
                onChange={ files => {
                    setResultFiles(files)
                    setShouldUpdateModal(true)
                } }
                multiple
                name="file-0"
            />

        </>

    }, [formSchema, handleSubmit, defaultValues, setResultFiles, setShouldUpdateModal, resultFiles, setStartUpload])


    //////////////////////////////////////////////
    const showModal = useCallback(() => setModal({
        title: 'Новый тикет',
        className: 'ticket-modal ticket-modal-agency-replace',
        content: modalContent,
        floating: true,
        isVisible: true,
        buttons: [{
            icon: 'chevron-left2',
            label: 'Назад',
            className: 'button-plain-xl',
            action: modalFn.showConsumablesAgency
        }, {
            label: 'Создать тикет',
            action: submitForm,
        }],
        breadcrumbs: [
            ['Расходники', modalFn.showConsumables],
            ['Agency', modalFn.showConsumablesAgency],
            ['Замена']
        ]
    }), [modalContent])


    /////////////////
    useEffect(() => {

        if (shouldUpdateModal) {
            showModal()
            setShouldUpdateModal(false)
        }

    }, [modalContent, shouldUpdateModal, showModal])


    /////////////////
    useEffect(() => {

        if (
            modalState.className === 'ticket-modal ticket-modal-agency-replace'
            && modalState.isVisible === false
        ) {
            setDefaultValues({})
        }

    }, [modalState.isVisible])


    /////////////////
    useEffect(() => {

        if (Object.keys(defaultValues).length > 0) {
            showModal()
            setDefaultValues({})
        }

    }, [modalContent, defaultValues, showModal, setDefaultValues])


    ////////////////
    return showModal


}