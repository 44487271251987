import { memo } from 'react'
import './NotificationCounter.light.scss'

/**
 * Renders a notification counter component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.value - The value to be displayed in the counter.
 * @param {string} props.color - The color of the counter. Possible values: 'red', 'grey', 'gray', 'violet', 'orange', 'green'.
 * @returns {JSX.Element} The rendered notification counter component.
 */

export const NotificationCounter = memo(({ value, color }) => {

    const colorClasses = {
        red: 'notification-counter-red',
        grey: 'notification-counter-grey',
        gray: 'notification-counter-grey',
        violet: 'notification-counter-violet',
        orange: 'notification-counter-orange',
        green: 'notification-counter-green'
    }

    const colorClass = color ? colorClasses[color] || '' : ''

    if (!value && value !== 0) {
        return ''
    }

    return (
        <div className={ `notification-counter ${colorClass}` }>{ value }</div>
    )

})