import React, { useCallback, useMemo } from 'react'
import Icon from '../Icon/Icon'
import { If } from '../If'
import { useModal } from '../Modal2/Modal2'
import { TicketModalButtons } from './TicketModalButtons'
import { TicketModalSearch } from './TicketModalSearch'

interface ParamsButton {
    name: string
    icon?: string
    label: string
    action: any
}

export interface TicketModalConstructorProps {
    title: string
    name: string
    size?: 'medium' | 'large'
    ticketModalButtons?: ParamsButton[]
    breadcrumbs?: any[]
    modalFn?: any
    backAction?: string
    content?: any,
    showSearch?: boolean
}

export const useTicketModalConstructor = (
    {
        title,
        name,
        size,
        ticketModalButtons,
        breadcrumbs,
        modalFn,
        backAction,
        content,
        showSearch = false
    }: TicketModalConstructorProps
) => {


    ///////////////////////////////
    const { setModal } = useModal()


    //////////////////////////////////
    const goBack = useCallback(() => {

        if (backAction && modalFn[backAction]) {
            modalFn[backAction]()
        } else if (name === 'start') {
            setModal(false)
        } else if (!breadcrumbs?.length) {
            modalFn.showStart()
        } else if (breadcrumbs?.length && breadcrumbs.length > 1) {
            const [_, action] = breadcrumbs[breadcrumbs.length - 2]
            action && action()
        }

    }, [])


    //////////////////////////////////
    const goNext = useCallback(() => {

        const selectedButtonNode: any = document.querySelector('.ticket-modal-button-selected')
        const selectedButtonName = selectedButtonNode?.dataset?.name
        const selectedButton = ticketModalButtons?.find(button => button.name === selectedButtonName)
        const showModalFn = modalFn[selectedButton?.action]

        showModalFn && showModalFn()

    }, [])


    ////////////////////////////////////////////////////////////////////////////
    const modalContent = useMemo(() => <div className={ `ticket-form-${name}` }>

        <If condition={ showSearch }>
            <TicketModalSearch modalFn={ modalFn } />
        </If>

        <div className={ `
            ticket-modal-buttons
            ${size ? `ticket-modal-buttons-${size}` : ''}
        `}>

            { ticketModalButtons && <TicketModalButtons list={ ticketModalButtons } /> }
            { content && content }

        </div>

    </div>, [ticketModalButtons])


    /////////////////////////////////////
    const showModal = useCallback(() => {

        setModal({
            title,
            className: `ticket-modal ticket-modal-${name}`,
            content: modalContent,
            isVisible: true,
            buttons: [{
                label: name === 'start' ? 'Отменить' : <><Icon name="chevron-left2" />Назад</>,
                action: goBack,
                className: 'button-plain-xl'
            }, {
                label: <>Далее <Icon name="chevron-right" /></>,
                action: goNext
            }],
            breadcrumbs
        })

    }, [modalContent])


    ////////////////
    return showModal


}