import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { AppContext } from '../../context/AppContext'

import {
    BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
    Tooltip
} from 'chart.js'
import { MatchGroups } from '../../components/MatchGroups'
import { matchGroups } from '../../js/lib'

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)

export const OverviewChartsPeriodicStat = ({ chartPeriod, list }) => {


    ////////////////////////////////////
    const [data, setData] = useState({
        labels: [],
        datasets: []
    })


    const app = useContext(AppContext)
    const unit = matchGroups(['Support Proxy']) ? 'шт' : 'ч'


    /////////////////
    useEffect(() => {

        if (list.length) {

            let newDataset = []
            let newLabels = []

            if (chartPeriod === 'day') {

                newDataset = list.map(item => item.sum?.toFixed(2))
                newLabels = list.map(item => moment(item.createdAt).format('hh:mm'))

            } else if (chartPeriod === 'week') {

                const weekDayNames = {
                    1: 'Вс',
                    2: 'Пн',
                    3: 'Вт',
                    4: 'Ср',
                    5: 'Чт',
                    6: 'Пт',
                    7: 'Сб'
                }

                for (let i = 1; i < 8; i++) {

                    const weekDate = moment().subtract(1, 'week').add(i, 'days')
                    const dayOfWeek = weekDate.day() + 1

                    let month = weekDate.month() + 1
                    month = (month < 10 ? '0' : '') + month

                    const isDayExists = list.find(item => item.dayOfWeek === dayOfWeek)
                    newDataset.push(isDayExists ? isDayExists.value : 0)
                    newLabels.push(`${weekDayNames[dayOfWeek]}, ${weekDate.date()}.${month}`)

                }

            } else if (chartPeriod === 'month') {

                for (let i = 1; i < 31; i++) {

                    const dayDate = moment().subtract(1, 'month').add(i + 1, 'days')
                    const isDayExists = list.find(item => item.day === dayDate.date())
                    let month = dayDate.month() + 1
                    month = (month < 10 ? '0' : '') + month

                    newDataset.push(isDayExists ? isDayExists.value : 0)
                    newLabels.push(`${dayDate.date()}.${month}`)

                }

            }

            setData({
                labels: newLabels,
                datasets: [
                    {
                        data: newDataset,
                        backgroundColor: '#EC0011',
                        borderRadius: 3,
                        maxBarThickness: 18,
                        borderSkipped: false,
                        datalabels: {
                            display: function (context) {
                                return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
                            },
                            color: app.theme.includes('dark') ? '#767676' : '#3C4245',
                            font: {
                                size: '16px'
                            },
                            padding: {
                                bottom: 30
                            },
                            align: 'top',
                            formatter: value => value + '$'
                        }
                    }
                ]
            })

        }


    }, [list, chartPeriod])


    /////////////////
    const options = {
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: '#757B7E',
                    font: {
                        size: 14
                    }
                },
                border: {
                    display: false
                }
            },
            y: {
                display: false,
                grid: {
                    display: false
                }
            }
        },
        layout: {
            padding: {
                top: 40
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: true,
                backgroundColor: '#F1F3F4',
                titleColor: '#262626',
                bodyColor: '#262626',
                displayColors: false,
                xAlign: 'left',
                // borderColor: '#262626',
                // borderWidth: 1,
                callbacks: {
                    title: () => '',
                    body: () => 'body',
                    label: (tooltip) => `${tooltip.formattedValue} ${unit}`
                }
            }
        },
        responsive: true,
        maintainAspectRatio: true
    }


    //////////////////////////////////////////////////
    return (<div className="overview-charts-periodic">

        {

            list.length

                ? <div className="overview-charts-periodic-container">
                    <Bar options={ options } data={ data } />
                </div>

                : <MatchGroups denied={ ['Support Farm'] }>
                    <div className="page-note">За выбранный период данных нет</div>
                </MatchGroups>

        }

    </div>)

}