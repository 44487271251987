import React, { memo, useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import { Icon } from '../Icon/Icon'
import './SideBar.light.scss'

/**
 * Represents a menu item in the sidebar.
 * @param {Object} props - The component props.
 * @param {Object} props.item - The menu item object.
 * @param {Object} props.attributes - The attributes for the NavLink component.
 * @param {Object} props.location - The location object from React Router.
 * @param {Function} props.hideSidebar - The function to hide the sidebar.
 * @param {boolean} props.isSidebarVisible - Indicates whether the sidebar is visible or not.
 * @returns {JSX.Element} The rendered MenuItem component.
 */

export const MenuItem = memo(({ item, attributes, location, hideSidebar, isSidebarVisible }) => {


    ///////////////////////////////////////////////////////////////
    const [isMainSubmenuOpen, setMainSubmenuOpen] = useState(false)
    const [isTooltipSubmenuOpen, setTooltipSubmenuOpen] = useState(false)


    //////////////////
    useEffect(() => {

        // setMainSubmenuOpen(false)
        setTooltipSubmenuOpen(false)

    }, [location.pathname])


    /////////////////
    useEffect(() => {

        const handleClickOutside = (event) => {
            if (!event.target.closest('.sidebar-nav-wrapper')) {
                setTooltipSubmenuOpen(false)
            }
        }

        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }

    }, [])


    ///////////////////////////
    if (item.submenu?.length) {

        item.submenu.sort((a, b) => a.name.localeCompare(b.name))

    }


    ////////////////////////////////////////////
    const handleMainClick = useCallback((e) => {

        if (item.submenu?.length && !isSidebarVisible) {
            e.preventDefault()
            e.stopPropagation()
            setTooltipSubmenuOpen(!isTooltipSubmenuOpen)
        } else {
            e.stopPropagation()
            // hideSidebar()
        }

    }, [setTooltipSubmenuOpen, hideSidebar, isTooltipSubmenuOpen, isSidebarVisible, item.submenu])


    ///////////////////////////////////////////////////
    const handleSubmenuItemClick = useCallback((e) => {

        e.stopPropagation()
        setTooltipSubmenuOpen(false)
        // hideSidebar()

    }, [hideSidebar, setTooltipSubmenuOpen])


    ///////////////////////////////////////////////
    const handleChevronClick = useCallback((e) => {

        e.preventDefault()
        e.stopPropagation()
        setMainSubmenuOpen(!isMainSubmenuOpen)

    }, [setMainSubmenuOpen, isMainSubmenuOpen])


    /////////
    return <>
        <div className="sidebar-nav-wrapper">
            <NavLink
                { ...attributes }
                className={ ({ isActive }) =>
                    `${attributes.className(isActive)} ${isMainSubmenuOpen && isSidebarVisible ? "sidebar-nav-item-open" : ""}`
                }
                onClick={ handleMainClick }
                data-tooltip-id={ !isTooltipSubmenuOpen && !isSidebarVisible ? `nav-tooltip-${item.icon}` : undefined }
                data-tooltip-content={ item?.name }
            >
                <Icon name={ item.icon } />
                <label>{ item.name }</label>
                { item.submenu?.length > 0 && isSidebarVisible && (
                    <Icon
                        onClick={ handleChevronClick }
                        className="sidebar-nav-item-chevron"
                        name={ isMainSubmenuOpen ? 'chevron-up2' : 'chevron-down2' }
                    />
                ) }
            </NavLink>

            { item.submenu?.length > 0 && isTooltipSubmenuOpen && (
                <div className="sidebar-submenu-small">
                    <NavLink
                        { ...attributes }
                        onClick={ handleSubmenuItemClick }
                    >
                        <Icon name={ item.icon } />
                        <label>{ item.name }</label>
                    </NavLink>
                    { item.submenu.map((subItem, index) => (
                        <NavLink
                            className={ ({ isActive }) =>
                                (isActive ? 'sidebar-nav-subitem sidebar-nav-subitem-active' : 'sidebar-nav-subitem')
                            }
                            to={ subItem.route }
                            key={ index }
                            onClick={ handleSubmenuItemClick }
                        >
                            <label>{ subItem.name }</label>
                        </NavLink>
                    )) }
                </div>
            ) }
        </div>

        { item.submenu?.length > 0 && isMainSubmenuOpen && (
            <div className="sidebar-submenu">
                { item.submenu.map((subItem, index) => (
                    <NavLink
                        className={ ({ isActive }) =>
                            (isActive ? 'sidebar-nav-subitem sidebar-nav-subitem-active' : 'sidebar-nav-subitem')
                        }
                        to={ subItem.route }
                        key={ index }
                    // onClick={ hideSidebar }
                    >
                        <label>{ subItem.name }</label>
                    </NavLink>
                )) }
            </div>
        ) }

        { !isTooltipSubmenuOpen
            && !isSidebarVisible
            && <Tooltip
                id={ `nav-tooltip-${item.icon}` }
                place={ item?.route === '/' ? 'bottom' : 'right' }
                className="sidebar-tooltip"
            />
        }
    </>


})
