import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { AppContext } from '../../context/AppContext'
import FormConstructor from '../FormConstructor/FormConstructor'
import Icon from '../Icon/Icon'
import { useModal } from '../Modal2/Modal2'
import { useDefaultValues } from './default.values.hook'

export const useShowGoogleTdFarm = (
    modalFn: any,
    formRef: any,
    submitForm: Function,
    handleSubmit: SubmitHandler<any>,
) => {


    ///////////////////////////////
    const { setModal } = useModal()
    const app = useContext(AppContext)
    const { defaultValues, setDefaultValues } = useDefaultValues(modalFn, 'googleTdFarm')


    /////////////////////////////////////////////////////////////////////////
    const mapTopics = (items: Array<any>, filterActive: boolean = false) => {
        return items
            ?.filter((item: any) => !filterActive || item.isActive)
            ?.map((item: any) => ({
                label: item.name,
                value: item.name,
            }))
    }


    //////////////////////////////
    const accountTopics = useMemo(
        () => mapTopics(app?.accountTypes || [], true),
        [app?.accountTypes]
    )

    ///////////////////////////////////
    const formSchema = useMemo(
        () => [
            [
                {
                    label: 'Тип аккаунта',
                    placeholder: 'Выберите тип аккаунта',
                    type: 'select',
                    name: 'accountType-0',
                    options: accountTopics,
                    validation: { required: true },
                },
                {
                    label: 'Необходимое количество',
                    placeholder: 'Введите количество',
                    type: 'quantity',
                    name: 'qty-0',
                    value: 1,
                    min: 1,
                    max: 1000,
                    required: 'number'
                },
            ],
            {
                label: 'Ваш комментарий',
                placeholder: 'Ваш комментарий (необязательно)',
                type: 'textarea',
                name: 'comment-0'
            },
        ],
        [accountTopics]
    )

    ////////////////////////////////////
    const modalContent = useMemo(() => {
        return (
            <>
                <FormConstructor
                    ref={ formRef }
                    defaultValues={ defaultValues }
                    formSchema={ formSchema }
                    onSubmit={ (data) =>
                        handleSubmit({
                            ...data,
                            formSections: 1,
                            social: 'google',
                            category: 'googleTdFarm',
                        })
                    }
                />
            </>
        )
    }, [formSchema, handleSubmit, defaultValues])


    //////////////////////////////////////////////
    const showModal = useCallback(() => setModal({
        title: 'Новый тикет',
        className: 'ticket-modal ticket-modal-google-td-farm',
        content: modalContent,
        floating: true,
        isVisible: true,
        buttons: [
            {
                label: (
                    <>
                        <Icon name="chevron-left2" />
                        Назад
                    </>
                ),
                action: modalFn.showNewTicket,
                className: 'button-plain-xl',
            },
            {
                label: 'Создать тикет',
                action: submitForm,
            },
        ],
        breadcrumbs: [
            ['Расходники', modalFn.showConsumables],
            ['Google', modalFn.showConsumablesGoogle],
            ['ТД фарм']
        ]
    }), [modalContent])


    /////////////////
    useEffect(() => {

        if (defaultValues) {
            showModal()
            setDefaultValues(null)
        }

    }, [modalContent, defaultValues, showModal, setDefaultValues])


    ////////////////
    return showModal


}