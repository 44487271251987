/////////////////////////////////////////////////////////////////
export const defineCase = (number, name1, name234, nameMany) => {

    number = number + ''
    const lastDig = number.charAt(number.length - 1)

    if (
        (number == 2 || number == 3 || number == 4 || lastDig == 2 || lastDig == 3 || lastDig == 4) &&
        number != 12 && number != 13 && number != 14
    ) {

        return name234

    } else {

        if (number == 1 || (lastDig == 1 && number != 11)) {
            return name1
        } else {
            return nameMany
        }

    }
}


/////////////////////////////////////////////////
export const shortenText = (text, maxLength) => {

    if (!text) {
        return
    }

    if (text?.length <= maxLength) {
        return text
    }

    const shortenedText = text?.slice(0, maxLength)
    const lastSpaceIndex = shortenedText?.lastIndexOf(' ')

    if (lastSpaceIndex !== -1) {
        return shortenedText?.slice(0, lastSpaceIndex) + '...'
    }

    return shortenedText + '...'

}


///////////////////////////////////
export const cleanHTML = (str) => {

    if (!str) return str

    return str
        .replace(/<style[^>]*>.*?<\/style>/gi, '')
        .replace(/<script[^>]*>.*?<\/script>/gi, '')
        .replace(/<[^>]*>/gi, '')

}


///////////////////////////////////////
export const dbToInput = (content) => {

    if (!content) return ''
    const contentBreakes = content.replaceAll('<br />', '\n')
    const contentNoHTML = cleanHTML(contentBreakes)
    return contentNoHTML

}


///////////////////////////////////////
export const inputToDb = (content) => {

    if (!content) return ''

    const contentNoHTML = cleanHTML(content)
    const contentNormalizedBreakes = contentNoHTML.replace(/(\r\n|\r|\n)/g, '\n')
    const contentTrimmed = contentNormalizedBreakes.trim()
    const contentHtmlBreakes = contentTrimmed.replace(/\n/g, '<br />')
    const contentNoBreakDuplicates = contentHtmlBreakes.replace(/(<br \/>\s*)+/g, '<br />')
    const contentWrappedLinks = wrapLinks(contentNoBreakDuplicates)

    return contentWrappedLinks

}


//////////////////////////////////////
export const wrapLinks = (string) => {

    if (!string) return ''

    const urlRegex = /(https?:\/\/\S+)/g
    const parts = string.split(urlRegex)

    let result = ''

    for (let i = 0; i < parts.length; i++) {

        if (i % 2 === 1) {
            result += `<a href="${parts[i]}" target="_blank">${parts[i]}</a>`
        } else {
            result += parts[i]
        }

    }

    return result

}


/////////////////////////////////////////
export const parseCSV = async (file) => {

    if (!file) return

    const { read: readCsv, utils } = await import('xlsx')
    const fileText = (file instanceof File) ? await file.text() : file
    const workbook = readCsv(fileText, { type: 'string' })
    const sheetNameList = workbook.SheetNames
    let jsonData = utils.sheet_to_json(workbook.Sheets[sheetNameList[0]], { header: 1 })
    jsonData = jsonData.map(row => row.map(cell => typeof cell === 'string' ? cell.trim() : cell))
    return jsonData

}


/////////////////////////////////////
export const capitalize = string => {

    if (!string) return ''
    return string.charAt(0).toUpperCase() + string.slice(1)

}